import Api from "../index";

const forgotSenha = async (dados) => {
  const {data} = await Api.post('/forgot-password/web',dados)
  return data
}

const resetartSenha = async (dados) => {
  const {data} = await Api.post('/reset-password/web',dados)
  return data
}





export const resetSenhaService = {
  forgotSenha,
  resetartSenha,
  // changePhoto,
  // updateColaborador,
  
};
