import React, { useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCamera,
  faChevronDown,
  faChevronUp,
  faSignOutAlt,
  faThList,
  faUsers,
  faBars,
  faUser,
  faMoneyBill,
  faImage,
  faGifts,
  faCalendarAlt,
  faImagePortrait,
  faRankingStar,
  faTicketAlt,
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import "../../ADMIN_Estabelecimento/components/Sidebar.css";
import { useAuth } from "../../../contexts/AuthContext";

import { CONSTANTES } from "../../../global/CONSTANTES";
import { COLORS } from "../../../global/STYLE";
import { admService } from "../../../services/adm";

const Sidebar = () => {
  const { token, logout, user, changeData } = useAuth();

  let navigate = useNavigate();

  // Inicializando um objeto de estado para controlar os dropdowns
  const [dropdownsOpen, setDropdownsOpen] = useState({});
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [carregando, setCarregando] = useState(false);
  const [carregandoBG, setCarregandoBG] = useState(false);

  const toggleDropdown = useCallback((dropdownName) => {
    setDropdownsOpen((prevDropdowns) => ({
      ...prevDropdowns,
      [dropdownName]: !prevDropdowns[dropdownName],
    }));
    // console.log(dropdownName)
  }, []);

  const handleNavigation = useCallback((path) => {
    // console.log(path)
    navigate(path);
    setSidebarOpen(false); // Feche o sidebar quando um item é clicado
  }, []);

  const handleImageUpload = useCallback(
    async (event) => {
      setCarregando(true);
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];

        const maxSize = 2 * 1024 * 1024; // 2MB em bytes

        if (file && file.size > maxSize) {
          toast.error(
            "O tamanho do arquivo excede o limite de 2MB. Por favor, selecione um arquivo menor."
          );
          // alert('O tamanho do arquivo excede o limite de 2MB. Por favor, selecione um arquivo menor.');
          event.target.value = ""; // Limpa o valor do input file
          setCarregando(false);
          return false;
        }

        let estabelecimento_id = user.id;
        try {
          const data = await admService.changePhoto(
            file,
            token,
            estabelecimento_id
          );
          if (data.error) {
            toast.error(data.message);
          } else {
            changeData("foto", data.data.foto);
            toast.success(data.message);
            // console.log(data.data.foto);
          }
        } catch (e) {
          toast.error("Erro ao enviar imagem", e.message)
          // console.error("Erro ao enviar imagem", e);
        }
      }
      setCarregando(false);
    },
    [user]
  );


  return (
    <>
      <div
        className="hamburger-toggle"
        onClick={() => setSidebarOpen(!isSidebarOpen)}
      >
        <FontAwesomeIcon icon={faBars} />
      </div>
      <div
        className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}
        style={{ backgroundColor: COLORS.cardBackground }}
      >
        <div className="avatar-section">
          {user.foto ? (
            <img
              src={`${CONSTANTES.uri}${CONSTANTES.caminhoImageAdm}${user.id}/${user.foto}`}
              alt="Avatar"
              className="avatar"
            />
          ) : (
            <img
              src="https://via.placeholder.com/100"
              alt="Avatar"
              className="avatar"
            />
          )}

          <div className="campoFotoPerfil">
            {carregando ? (
              <label className="edit-avatar">
                <span className="loader"></span>
              </label>
            ) : (
              <label htmlFor="image-upload" className="edit-avatar">
                <FontAwesomeIcon icon={faCamera} />
                <input
                  type="file"
                  id="image-upload"
                  accept="image/*"
                  onChange={handleImageUpload}
                  placeholder="BG"
                  alt="Imagem do BG do estabelecimento"
                  title="Imagem do BG do estabelecimento"
                  hidden
                />
              </label>
            )}

    
          </div>
          <div className="company-name">{user.nome}</div>
        </div>

        <ul className="menu-items">
          {/* # INÍCIO */}
          <li
            className="menu-item"
            onClick={() => handleNavigation("/adm_facilite/")}
          >
            <FontAwesomeIcon icon={faHome} className="menu-icon" />
            Início
          </li>

          {/* # PERFIL */}
          <li
            className="menu-item"
            onClick={() => handleNavigation("/adm_facilite/perfil-adm")}
          >
            <FontAwesomeIcon icon={faUser} className="menu-icon" />
            Perfil
          </li>
          
          {/* # PLANOS */}
          <li className="menu-item">
            <div
              className="dropdown-toggle"
              onClick={() => toggleDropdown("planos")}
            >
              <FontAwesomeIcon icon={faRankingStar} className="menu-icon" />
              Planos
              <FontAwesomeIcon
                icon={dropdownsOpen["planos"] ? faChevronUp : faChevronDown}
                className="dropdown-icon"
              />
            </div>
            {dropdownsOpen["planos"] && (
              <ul
                className="dropdown-menu show"
                style={{ backgroundColor: COLORS.bgButtonCard }}
              >
                <li
                  className="dropdown-item"
                  onClick={() => handleNavigation("/adm_facilite/lista-plano")}
                >
                  Listagem de Planos
                </li>
                {/* <li
                  className="dropdown-item"
                  onClick={() =>
                    handleNavigation("/adm_facilite/listaColaboradorEstabelecimento")
                  }
                >
                  Listar colaboradores
                </li> */}
              </ul>
            )}
          </li>
          
          {/* # CUPONS */}
          <li className="menu-item">
            <div
              className="dropdown-toggle"
              onClick={() => toggleDropdown("cupons")}
            >
              <FontAwesomeIcon icon={faTicketAlt} className="menu-icon" />
              Cupons
              <FontAwesomeIcon
                icon={dropdownsOpen["cupons"] ? faChevronUp : faChevronDown}
                className="dropdown-icon"
              />
            </div>
            {dropdownsOpen["cupons"] && (
              <ul
                className="dropdown-menu show"
                style={{ backgroundColor: COLORS.bgButtonCard }}
              >
                <li
                  className="dropdown-item"
                  onClick={() => handleNavigation("/adm_facilite/lista-cupom")}
                >
                  Listagem de cupons
                </li>
                {/* <li
                  className="dropdown-item"
                  onClick={() =>
                    handleNavigation("/adm_facilite/listaColaboradorEstabelecimento")
                  }
                >
                  Listar colaboradores
                </li> */}
              </ul>
            )}
          </li>

          {/* # PAGAMENTO */}
          <li className="menu-item">
            <div
              className="dropdown-toggle"
              onClick={() => toggleDropdown("pagamento")}
            >
              <FontAwesomeIcon icon={faMoneyBill} className="menu-icon" />
              Pagamento
              <FontAwesomeIcon
                icon={dropdownsOpen["pagamento"] ? faChevronUp : faChevronDown}
                className="dropdown-icon"
              />
            </div>
            {dropdownsOpen["pagamento"] && (
              <ul
                className="dropdown-menu show"
                style={{ backgroundColor: COLORS.bgButtonCard }}
              >
                <li
                  className="dropdown-item"
                  onClick={() => handleNavigation("/adm_facilite/lista-pagamento")}
                >
                  Listagem de Pagamentos
                </li>
                {/* <li
                  className="dropdown-item"
                  onClick={() =>
                    handleNavigation("/adm_facilite/listaColaboradorEstabelecimento")
                  }
                >
                  Listar colaboradores
                </li> */}
              </ul>
            )}
          </li>
        </ul>

        {/* # LOGOUT */}
        <div className="logout-section">
          <button
            className="logout-button"
            onClick={() => {
              logout();
              navigate("/");
            }}
          >
            <FontAwesomeIcon icon={faSignOutAlt} className="logout-icon" />
            Sair
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
