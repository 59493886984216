export const COLORS = {
    background: '#171717',
    // background: "#342f39",
    primary: '#fff',
    secondary: '#4E4A50',
    bgButtonCard: '#404040',
    placeHolder:'#c3c3c3',
    cardBackground: '#2B2B2B',
    starColor: '#FEC107',
    button: '#ff9101',
    primaryText: '#fff',
    secondaryText: '#4E4A50',
    inputBackground: '#22222c',
    favorite_full: 'red',
    // favorite_full: '#3FC8B9',
    favorite_empty: '#c3c3c3',
    star:'#FF9200',
    concluido:'#80FF80',
    cancelado: '#FF4C40',
    pendente: '#FAEE40',
    separator: '#E0E0E0',
    toastSuccess: '#80FF80',
    toastInfo:'#63B5F6',
    toastError:'#E06D6D',
    toastCustom:'#B967C7',
    toastWarning:'#ffd351'
  };

  export const ESTILO = {
    navBar_height:70
  }


  export const applyCssVariables = (colors) => {
    const root = document.documentElement;
  
    Object.keys(colors).forEach(key => {
      root.style.setProperty(`--${key}`, colors[key]);
    });
  };
  
  // Chame a função para aplicar as variáveis CSS
  applyCssVariables(COLORS);