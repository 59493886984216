import React, { useState, useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import "./styles.css";
import estadosData from "../../../data/Estados.json";
import cidadesData from "../../../data/Cidades.json";
import MaskedInput from "react-text-mask";
import { toast } from "react-toastify";
// import { estabelecimentoService } from "../../../services/estabelecimento";
import { colaboradorService } from "../../../services/colaborador";
import { COLORS } from "../../../global/STYLE";
import { useNavigate } from "react-router-dom";

function PerfilColaborador() {
  const { token, changeData, logout } = useAuth();
  const navigate = useNavigate()

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [telefone, setTelefone] = useState("");
  const [tipoDocumento, setTipoDocumento] = useState("");
  const [documento, setDocumento] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [logradouro, setLogradouro] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [cep, setCep] = useState("");
  const [carregando, setCarregando] = useState(false);

  const handleSubmit = async (e) => {
    setCarregando(true);
    e.preventDefault();
    try {
      let uf = "";
      if (estado) {
        // console.log("tem estado")
        const estadoFiltrado = estados.filter((e) => e.ID === estado);
        uf = estadoFiltrado[0].Nome;
      }

      const dadosCreate = {
        nome,
        data_nascimento:dataNascimento,
        telefone,
        documento_tipo:tipoDocumento,
        documento_numero:documento,
        uf,
        cidade,
        logradouro,
        numero,
        bairro,
        cep,
      };

      const data = await colaboradorService.updateColaborador(
        token,
        dadosCreate
      );
      if (data.error) {
        if(data.reload){
          navigate("/login/colaborador")
          logout()  
        }
        toast.error(data.message);
      } else {
        changeData("nome", nome);
        toast.success(data.message);
      }
      // console.log(dadosCreate);
      // Fazer algo com os dadosCreate, como enviar para a API
    } catch (e) {
      // toast.error(e);
    }
    setCarregando(false);
  };

  const pegaDadosColaborador = async () => {
    try {
      const data = await colaboradorService.dataColaborador(token);
      if (data.error) {
        if(data.reload){
          navigate("/login/colaborador")
          logout()  
        }
        toast.error(data.message);
      } else {
        setNome(data.data.nome);
        setEmail(data.data.email);
        setDataNascimento(data.data.data_nascimento);
        setTelefone(data.data.telefone);
        setTipoDocumento(data.data.documento_tipo);
        setDocumento(data.data.documento_numero);
        setLogradouro(data.data.logradouro);
        setNumero(data.data.numero);
        setBairro(data.data.bairro);
        setCep(data.data.cep);

        //estado
        // console.log("estado banco = ", data.data.uf);
        if (data.data.uf) {
          // console.log("tem estado")
          const estadoFiltrado = estados.filter((e) => e.Nome === data.data.uf);

          // console.log("estado encontrado: ", estadoFiltrado);
          handleEstadoChange(estadoFiltrado[0].ID);
          setEstado(estadoFiltrado[0].ID);

          if (data.data.cidade) {
            const cidadeFiltrada = cidadesData.filter(
              (c) => c.Nome === data.data.cidade
            );
            setCidade(cidadeFiltrada[0].Nome);
            // console.log("Cidade = ",cidadeFiltrada);
          }
        }
      }
    } catch (e) {
      // toast.error(e);
    }
  };

  useEffect(() => {
    setEstados(estadosData);
    pegaDadosColaborador();
  }, [estados]);

  const handleEstadoChange = (estadoSelecionado) => {
    setEstado(estadoSelecionado);

    // Filtra as cidades baseado no estado selecionado
    const cidadesFiltradas = cidadesData.filter(
      (cidade) => cidade.Estado === estadoSelecionado
    );

    // console.log(estadoSelecionado)

    setCidades(cidadesFiltradas);
  };

  return (
    <div className="" style={{paddingBottom:20}}>
      <h3 style={{ color: COLORS.primaryText }}>Perfil do colaborador</h3>
      <span
        className="descricao"
        style={{ backgroundColor: COLORS.bgButtonCard, marginTop: 10 }}
      >
        <p style={{ color: COLORS.primaryText }}>
          Alguns campos são estritamente obrigatórios, pois são campos
          importantes. Cuidado ao preenchê-los.
        </p>

        <a href={`https://facilite.app/delete-account`} style={{padding:10, textDecoration:'none', background:COLORS.cardBackground, width:'10%', display:'flex', textAlign:'center', justifyContent:'center', alignItems:'center', borderRadius:5, color:COLORS.cancelado, marginTop:10, cursor:'pointer'}} title="Excluir conta" alt="excluir conta">
          Excluir conta
        </a>
      </span>

      <form
        onSubmit={handleSubmit}
        className="formulario-perfil"
        style={{ backgroundColor: COLORS.background }}
      >
        <label style={{ color: COLORS.placeHolder }}>Nome ou apelido</label>
        <small style={{ color: COLORS.placeHolder, marginTop:-10 }}>Mínimo 4 caracteres</small>
        <input
          type="text"
          value={nome || ""}
          onChange={(e) => setNome(e.target.value)}
          required
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        <label style={{ color: COLORS.placeHolder }}>E-mail</label>
        <input
          type="email"
          value={email}
          // onChange={(e) => setEmail(e.target.value)}
          disabled
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        <label style={{ color: COLORS.placeHolder }}>Data de Nascimento</label>
        <MaskedInput
          mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
          value={dataNascimento}
          onChange={(e) => setDataNascimento(e.target.value)}
          placeholder="DD/MM/AAAA"
          className="masked-input"
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        <label style={{ color: COLORS.placeHolder }}>Telefone</label>
        <MaskedInput
          mask={[
            "(",
            /[1-9]/,
            /\d/,
            ")",
            " ",
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          value={telefone || ""}
          onChange={(e) => setTelefone(e.target.value)}
          placeholder="(31) 9 4567-8901"
          className="masked-input"
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        <label style={{ color: COLORS.placeHolder }}>Tipo de Documento:</label>
        <select
          value={tipoDocumento || ""}
          onChange={(e) => setTipoDocumento(e.target.value)}
          className="formulario-servico-select"
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        >
          <option value="">Selecione...</option>
          <option value="cpf">CPF</option>
          <option value="cnpj">CNPJ</option>
        </select>

        <label>
          <b style={{color:COLORS.primaryText}}>{tipoDocumento === "cpf" ? "CPF" : "CNPJ"}:</b>
          <MaskedInput
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
            mask={
              tipoDocumento === "cpf"
                ? [
                    /\d/,
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                  ]
                : [
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                  ]
            }
            value={documento || ""}
            onChange={(e) => setDocumento(e.target.value)}
            placeholder={
              tipoDocumento === "cpf" ? "123.456.789-01" : "12.345.678/0001-90"
            }
            className="masked-input"
          />
        </label>

        <label style={{ color: COLORS.placeHolder }}>Estado</label>
        <select
          value={estado}
          onChange={(e) => handleEstadoChange(e.target.value)}
          className="formulario-servico-select"
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        >
          <option value="">Selecione...</option>
          {estados.map((estado) => (
            <option key={estado.ID} value={estado.ID}>
              {estado.Nome}
            </option>
          ))}
        </select>

        <label style={{ color: COLORS.placeHolder }}>Cidade</label>
        <select
          value={cidade}
          onChange={(e) => setCidade(e.target.value)}
          className="formulario-servico-select"
          disabled={!estado} // Desabilita se nenhum estado estiver selecionado
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        >
          <option value="">Selecione...</option>
          {cidades.map((cidade) => (
            <option key={cidade.ID} value={cidade.Nome}>
              {cidade.Nome}
            </option>
          ))}
        </select>

        <label style={{ color: COLORS.placeHolder }}>Logradouro</label>
        <input
          type="text"
          value={logradouro || ""}
          onChange={(e) => setLogradouro(e.target.value)}
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        <label style={{ color: COLORS.placeHolder }}>Número</label>
        <input
          type="text"
          value={numero || ""}
          onChange={(e) => setNumero(e.target.value)}
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        <label style={{ color: COLORS.placeHolder }}>
          Bairro
          </label>
          <input
            type="text"
            value={bairro || ""}
            onChange={(e) => setBairro(e.target.value)}
            style={{
              border: `1px solid ${COLORS.secondary}`,
              backgroundColor: ` ${COLORS.inputBackground}`,
              color: COLORS.primaryText,
            }}
          />

        <label style={{ color: COLORS.placeHolder }}>
          CEP
          </label>
          <MaskedInput
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
            value={cep || ""}
            onChange={(e) => setCep(e.target.value)}
            placeholder="12345-678"
            className="masked-input"
            style={{
              border: `1px solid ${COLORS.secondary}`,
              backgroundColor: ` ${COLORS.inputBackground}`,
              color: COLORS.primaryText,
            }}
          />

        <button type="submit" className="submit-button"
          disabled={carregando ? true : false}
          style={{backgroundColor:COLORS.button, marginTop:20}}
        >
          {carregando ? <span className="loader"></span> : "Salvar Perfil"}
        </button>
      </form>
    </div>
  );
}

export default PerfilColaborador;
