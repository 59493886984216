// routes/estabelecimento.routes.js
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../pages/ADMIN_Estabelecimento/components/Dashboard";
import PrincipalE from "../pages/ADMIN_Estabelecimento/components/PrincipalE";
import AgendamentoPage from "../pages/ADMIN_Estabelecimento/Agendamentos/AgendamentoPage";
import ColaboradorCadastro from "../pages/ADMIN_Estabelecimento/Colaborador/Colaborador";
import ServicoCadastro from "../pages/ADMIN_Estabelecimento/Servicos/ServicoCadastro";
import ServicosListaEstabelecimento from "../pages/ADMIN_Estabelecimento/Servicos/ServicoLista";
import ListaColaborador from "../pages/ADMIN_Estabelecimento/Colaborador/ListaColaborador";
import VinculaServicos from "../pages/ADMIN_Estabelecimento/Servicos/VinculaServico";
import PerfilEstabelecimento from "../pages/ADMIN_Estabelecimento/Perfil";
import { useAuth } from "../contexts/AuthContext";
import GaleriaEstabelecimento from "../pages/ADMIN_Estabelecimento/Galeria";
import ListaPagamento from "../pages/ADMIN_Estabelecimento/Pagamento/Pagamento";
import HorarioDisponivelColaboradorPeloEstabelecimento from "../pages/ADMIN_Estabelecimento/Colaborador/HorarioDisponivelColaborador";
import OfertaEstabelecimento from "../pages/ADMIN_Estabelecimento/Oferta";
import AgendamentoParaCliente from "../pages/ADMIN_Estabelecimento/Agendamentos/AgendarParaCliente";

const EstabelecimentoRoutes = () => {
  const { isLogado, user } = useAuth();

  return (
    <Routes>
      <Route path="/adminE" element={<Dashboard />}>
        <Route index element={<PrincipalE />} />
        {user.liberar_agendamento && (
          <Route path="agendamento" element={<AgendamentoPage />} />
        )}

        {user.liberar_agendamento && (
          <Route
            path="cadastro-colaborador-estabelecimento"
            element={<ColaboradorCadastro />}
          />
        )}

        {user.liberar_agendamento && (
          <Route
            path="agendamento-cliente"
            element={<AgendamentoParaCliente />}
          />
        )}

        {user.liberar_agendamento && (
          <Route
            path="lista-colaboradores-estabelecimento"
            element={<ListaColaborador />}
          />
        )}

        {user.liberar_agendamento && (
          <Route
            path="horario-disponivel-colaborador-estabelecimento"
            element={<HorarioDisponivelColaboradorPeloEstabelecimento />}
          />
        )}

        {user.liberar_agendamento && (
          <Route
            path="cadastro-servico-estabelecimento"
            element={<ServicoCadastro />}
          />
        )}

        {user.limite_imagem > 0 && (
          <Route
            path="galeria-estabelecimento"
            element={<GaleriaEstabelecimento />}
          />
        )}

        {user.liberar_oferta && (
          <Route
            path="oferta-estabelecimento"
            element={<OfertaEstabelecimento />}
          />
        )}

        {user.liberar_agendamento && (
          <Route
            path="lista-servico-estabelecimento"
            element={<ServicosListaEstabelecimento />}
          />
        )}

        {user.liberar_agendamento && (
          <Route path="vincular-servico" element={<VinculaServicos />} />
        )}

        {!user.vitalicio && (
          <Route path="lista-pagamento" element={<ListaPagamento />} />
        )}

        <Route
          path="perfil-estabelecimento"
          element={<PerfilEstabelecimento />}
        />
        {/* Rota para qualquer outra página não encontrada */}
        {/* <Route path="*" element={<PrincipalE />} /> */}
      </Route>
      <Route path="*" element={<Navigate to="/adminE/" />} />
    </Routes>
  );
};

export default EstabelecimentoRoutes;
