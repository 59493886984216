import React, { useState, useEffect } from 'react';
import moment from 'moment';

const CountdownTimer = ({ targetDate, acao }) => {
  const [loading, setLoading] = useState(true);
  const [timeRemaining, setTimeRemaining] = useState('');
  const [isMessageSent, setMessageSent] = useState(false); // Estado para controlar se a mensagem já foi enviada

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = moment();
      const endDate = moment(targetDate);
      const duration = moment.duration(endDate.diff(now));
      
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      
      setTimeRemaining(`${days} dias, ${hours} horas ${minutes} minutos e ${seconds} segundos`);
      setLoading(false); // Define o estado de carregamento como falso quando os dados estiverem prontos

      if (!isMessageSent && days <= 6) { // Se a mensagem ainda não foi enviada e restam 7 dias ou menos
        // console.log("Seu plano expira em 7 dias ou menos. Considere renová-lo.");
        acao(true)
        setMessageSent(true); // Marca a mensagem como enviada para evitar repetições
      }
      if (days > 6) { // Se a mensagem ainda não foi enviada e restam mais de 7 dias ou menos
        acao(false)
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate, isMessageSent]);

  const expirationDate = moment(targetDate).format('DD/MM/YYYY'); // Formata a data de expiração

  if (loading) {
    return <span className="loader"></span>; // Exibe o componente de carregamento enquanto os dados estão sendo buscados
  }

  if (moment().isAfter(targetDate)) { // Se a data atual for depois da data de expiração, significa que já expirou
    return (
      <div style={{ backgroundColor: 'rgba(255,0,0,0.5)', fontWeight: 'bold', color: 'white', padding: 5, marginBottom: 10, borderRadius: 5, alignItems: "center", display: 'flex', justifyContent: "center" }}>
        <p>Este plano está expirado em <strong>{expirationDate}</strong>.</p>
      </div>
    );
  } else if (timeRemaining.startsWith('0')) { // Se o tempo restante começar com 0, significa que está expirando hoje
    const renewMessage = "Renove seu plano agora!"; // Mensagem de renovação do plano
    return (
      <div style={{ backgroundColor: 'rgba(255,165,0,0.8)', fontWeight: 'bold', color: 'white', padding: 5, marginBottom: 10, borderRadius: 5, alignItems: "center", display: 'flex', justifyContent: "center" }}>
        <p>Este plano expira hoje, em <strong>{expirationDate}</strong>. {renewMessage} <CountdownToRenew targetDate={targetDate} /></p>
      </div>
    );
  } else { // Se não, significa que ainda resta tempo
    return (
      <div style={{ backgroundColor: 'rgba(34,148,242,1)', fontWeight: 'bold', color: 'white', padding: 5, marginBottom: 10, borderRadius: 5, alignItems: "center", display: 'flex', justifyContent: "center" }}>
        <p>Tempo restante para vencer o plano <strong style={{ fontSize: 20 }}>{timeRemaining}</strong>. Este plano expira em <strong>{expirationDate}</strong>.</p>
      </div>
    );
  }
};

// Componente de contador regressivo para renovar o plano
const CountdownToRenew = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const now = moment();
    const endDate = moment(targetDate);
    return endDate.diff(now) / 1000; // Convertendo para segundos
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  const displayTimeLeft = timeLeft < 0 ? 0 : timeLeft; // Garante que o tempo restante não seja negativo

  // Convertendo segundos para horas, minutos e segundos
  const hours = Math.floor(displayTimeLeft / 3600);
  const minutes = Math.floor((displayTimeLeft % 3600) / 60);
  const seconds = Math.floor(displayTimeLeft % 60);

  return (
    <strong>Renove em {hours} horas, {minutes} minutos e {seconds} segundos</strong>
  );
};

export default CountdownTimer;
