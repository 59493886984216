import Api from "../index";

const pegaCupons = async (token) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.get(`/cupom`, headerToken);
  return data;
};

const cadastrarCupom = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.post(`/cupom`, dados, headerToken);
  return data;
};

const atualizaCupom = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.post(`/cupom/atualiza`, dados, headerToken);
  return data;
};

const excluiCupom = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.post(`/cupom/excluir`, dados, headerToken);
  return data;
};


export const cupomService = {
  pegaCupons,
  cadastrarCupom,
  atualizaCupom,
  excluiCupom
};
