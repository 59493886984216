export const CONSTANTES = {
  caminhoImageCategorias: "/uploads/categorias/",
  caminhoImageEstabelecimentos: "/uploads/estabelecimentos/",
  caminhoImageClientes: "/uploads/clientes/",
  caminhoImageColaboradores: "/uploads/colaboradores/",
  caminhoImageAfiliados: "/uploads/afiliados/",
  caminhoImageAdm: "/uploads/adm/",
  galeria: "/galeria/",
  oferta:'/oferta/',
  logo:"Facilite",
  logoImage:'logo.png',
  empresa:"facilite",
  uri:process.env.REACT_APP_API_URI,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  SECRET_KEY_CRIPTOGRAFIA:process.env.REACT_APP_SECRET_KEY_CRIPTOGRAFIA,
};
