import React, { useState, useEffect, useCallback } from "react";
import "./styles.css"; // Importação do CSS
import { toast } from "react-toastify";
import { useAuth } from "../../../contexts/AuthContext";
import { COLORS } from "../../../global/STYLE";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { formatarValorReal } from "../../../functions/formatarValorReal";
import { cupomService } from "../../../services/cupom";
import ModalCupon from "./ModalCupom";

const ListaCupom = () => {
  const { token, logout } = useAuth();
  const navigate = useNavigate();

  const [cupom, setCupom] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [edicao, setEdicao] = useState({});
  const [carregando, setCarregando] = useState(false);

  const [cupomDado, setCupomDado] = useState("");
  const [valor, setValor] = useState("");
  const [quantidade, setQuantidade] = useState(1);
  const [porcentagem, setPorcentagem] = useState(0);
  const [tipo, setTipo] = useState("todos");
  const [status, setStatus] = useState("ativado");
  const [validade, setValidade] = useState("");
  const [descricao, setDescricao] = useState("");

  const handleUpdate = async (cupomId) => {
    setCarregando(true);

    if (
      !cupomDado ||
      !quantidade ||
      !tipo ||
      !validade ||
      (!porcentagem && !valor)
    ) {
      toast.error(`Todos os campos devem ser preenchidos.\n
      - Cupom\n
      - Descrição\n
      - Quantidade\n
      - Validade      
      `);
      return;
    }
    try {
      const dado = {
        cupom_id: Number(cupomId),
        cupom: String(cupomDado),
        descricao: String(descricao),
        quantidade: Number(quantidade),
        porcentagem: Number(porcentagem),
        valor: Number(valor),
        tipo: String(tipo),
        status: status == 'ativado' ? true : false,
        validade: String(validade),
      };

      // console.log(cupomDado)

      const data = await cupomService.atualizaCupom(token, dado);

      if (data.error) {
        toast.error(data.message);
        if (data.reload) {
          navigate("/login/adm_facilite");
          logout();
        }
      } else {
        // alert(status)
        const novoArrayCupom = [...cupom];
        novoArrayCupom.find((item) => {
          if (item.id === cupomId) {
            item.cupom = cupomDado;
            item.descricao = descricao;
            item.quantidade = quantidade;
            item.porcentagem = porcentagem;
            item.valor = valor;
            item.tipo = tipo;
            item.status = status =='ativado' ? true : false;
            item.validade = validade;
          }
        });
        // console.log(novoArrayCupom)
        setCupom(novoArrayCupom);
        // console.log("STATUS = ",status)
        // console.log("novo array = ",novoArrayCupom)
        // console.log("antigo array = ",cupom)
        toast.success(data.message);
        // Limpa as edições pendentes
        setEdicao({});
        // console.log("CUPOM = ", cupom)
      }
    } catch (e) {
      toast.error("Erro ao tentar atualizar: ", e.message);
    }
    setCarregando(false);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const addCupom = (dado) => {
    setCupom([...cupom, dado]);
  };

  const editarCupom = (cupomId) => {
    setEdicao({ [`cupom${cupomId}`]: true });
    cupom.find((item) => {
      if (item.id == cupomId) {
        // console.log("STATUS ao clicar em editar = ",item.status ?'ativado' : 'desativado')
        setCupomDado(item.cupom);
        setDescricao(item.descricao);
        setPorcentagem(item.porcentagem);
        setQuantidade(item.quantidade);
        setStatus(item.status ?'ativado' : 'desativado');
        setTipo(item.tipo);
        setValor(item.valor);
        setValidade(moment(item.validade).format("YYYY-MM-DD"));
      }
    });
  };

  const excluirCupom = async (cupomId) => {
    if (window.confirm("Tem certeza que deseja excluir este cupom?")) {
      // Remover o plano com o ID especificado do estado

      try {
        const dado = {
          cupom_id: cupomId,
        };
        const data = await cupomService.excluiCupom(token, dado);
        if (data.error) {
          toast.error(data.message);
          if (data.reload) {
            navigate("/login/adm_facilite");
            logout();
          }
        } else {
          setCupom((prevDados) =>
            prevDados.filter((item) => item.id !== cupomId)
          );
          toast.success(data.message);
        }
      } catch (e) {
        toast.error("Erro ao tentar excluir o cupom");
      }

      // Lógica para excluir o plano com id planoId
      // console.log("Excluir plano", planoId);
    }
  };

  const handlePlanoInputValorChange = (event) => {
    let value = event;
    // Remove caracteres não numéricos
    value = value.replace(/\D/g, "");
    // Remove os zeros à esquerda
    value = value.replace(/^0+/, "");
    // Preenche com zeros à esquerda até ter 6 caracteres
    value = value.padStart(6, "0");
    // Divide o valor para obter a parte inteira e a parte decimal
    const parteInteira = value.slice(0, -2) || "0";
    const parteDecimal = value.slice(-2);
    // Formata o valor para o formato de reais
    const valorFormatado = `${parteInteira}.${parteDecimal}`;

    setValor(valorFormatado);
  };

  const pegaDados = useCallback(async () => {
    try {
      const data = await cupomService.pegaCupons(token);
      if (data.error) {
        toast.error(data.message);
        if (data.reload) {
          navigate("/login/adm_facilite");
          logout();
        }
      } else {
        // console.log(data.data)
        setCupom(data.data);
      }
    } catch (e) {}
  }, [token]);

  useEffect(() => {
    pegaDados();
  }, []);

  return (
    <div style={{ paddingBottom: 30 }}>
      <h3 style={{ color: COLORS.primaryText }}>Lista de Cupom</h3>
      <span
        className="descricao"
        style={{ backgroundColor: COLORS.bgButtonCard, marginTop: 10 }}
      >
        <p style={{ color: COLORS.primaryText }}>Lista de cupons.</p>
      </span>

      <hr style={{ marginBottom: "10px" }} />

      <div
        className="form-lstCol-estab"
        style={{ backgroundColor: COLORS.cardBackground }}
      >
        {/* <label>
          <span style={{ color: COLORS.placeHolder }}>Criar um novo cupom</span>
        </label> */}

        <label>
          <button
            className="btnVincular"
            onClick={
              // handleVincular
              () => {
                setModalIsOpen(true);
              }
            }
            style={{
              backgroundColor: COLORS.button,
              borderColor: COLORS.secondary,
              padding: 10,
            }}
          >
            Criar um novo cupom
          </button>
        </label>
      </div>

      <br />

      <div className="content-tabela-pagamento">
        <table
          className="cupom-table-adm financial-table"
          style={{
            backgroundColor: COLORS.cardBackground,
            color: COLORS.primaryText,
          }}
        >
          <thead>
            <tr>
              <th style={{ backgroundColor: COLORS.inputBackground }}>Cupom</th>
              <th style={{ backgroundColor: COLORS.inputBackground }}>
                Descrição
              </th>
              <th style={{ backgroundColor: COLORS.inputBackground }}>
                Quantidade
              </th>
              <th style={{ backgroundColor: COLORS.inputBackground }}>
                Porcentagem
              </th>
              <th style={{ backgroundColor: COLORS.inputBackground }}>Valor</th>
              <th style={{ backgroundColor: COLORS.inputBackground }}>Tipo</th>
              <th style={{ backgroundColor: COLORS.inputBackground }}>
                Status
              </th>
              <th style={{ backgroundColor: COLORS.inputBackground }}>
                Validade
              </th>
              <th style={{ backgroundColor: COLORS.inputBackground }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {cupom.map((cp, index) => (
              <tr key={index}>
                <td
                  className={edicao[`cupom${cp.id}`] ? "editavel" : ""}
                  style={{ width: "15%" }}
                >
                  {edicao[`cupom${cp.id}`] ? (
                    <input
                      type="text"
                      name="cupom"
                      value={cupomDado}
                      onChange={(e) => setCupomDado(e.target.value)}
                      required
                    />
                  ) : cp.cupom ? (
                    cp.cupom
                  ) : (
                    "-"
                  )}
                </td>

                <td
                  className={edicao[`cupom${cp.id}`] ? "editavel" : ""}
                  style={{ width: "30%" }}
                >
                  {edicao[`cupom${cp.id}`] ? (
                    <input
                      type="text"
                      name="descricao"
                      value={descricao}
                      onChange={(e) => setDescricao(e.target.value)}
                      required
                    />
                  ) : cp.descricao ? (
                    cp.descricao
                  ) : (
                    "-"
                  )}
                </td>

                <td
                  className={edicao[`cupom${cp.id}`] ? "editavel" : ""}
                  style={{ width: "8%" }}
                >
                  {edicao[`cupom${cp.id}`] ? (
                    <input
                      type="number"
                      name="quantidade"
                      value={quantidade}
                      min={0}
                      // max={100}
                      onChange={(e) => {
                        let valInput = e.target.value.length;
                        if (
                          valInput >= 0 &&
                          valInput <= 4 &&
                          Number(valInput)
                        ) {
                          setQuantidade(parseInt(e.target.value, 10));
                        }
                      }}
                      required
                    />
                  ) : cp.quantidade ? (
                    cp.quantidade
                  ) : (
                    "-"
                  )}
                </td>

                <td
                  className={edicao[`cupom${cp.id}`] ? "editavel" : ""}
                  style={{ width: "8%" }}
                >
                  {edicao[`cupom${cp.id}`] ? (
                    <input
                      type="number"
                      name="porcentagem"
                      value={porcentagem}
                      min={0}
                      max={100}
                      onChange={(e) => {
                        let valInput = e.target.value.length;
                        if (
                          valInput >= 0 &&
                          valInput <= 4 &&
                          Number(valInput)
                        ) {
                          setPorcentagem(parseInt(e.target.value, 10));
                        }
                      }}
                      disabled={Number(valor) ? true : false}
                    />
                  ) : cp.porcentagem ? (
                    cp.porcentagem + " %"
                  ) : (
                    "-"
                  )}
                </td>

                <td
                  className={edicao[`cupom${cp.id}`] ? "editavel" : ""}
                  style={{ width: "8%" }}
                >
                  {edicao[`cupom${cp.id}`] ? (
                    <input
                      type="text"
                      name="valor"
                      placeholder="00,00"
                      value={formatarValorReal(valor)}
                      onChange={(e) =>
                        handlePlanoInputValorChange(e.target.value)
                      }
                      disabled={porcentagem ? true : false}
                    />
                  ) : cp.valor ? (
                    formatarValorReal(cp.valor)
                  ) : (
                    "-"
                  )}
                </td>

                <td
                  className={edicao[`cupom${cp.id}`] ? "editavel" : ""}
                  style={{ width: "5%" }}
                >
                  {edicao[`cupom${cp.id}`] ? (
                    <select
                      name="tipo"
                      value={tipo}
                      onChange={(e) => setTipo(e.target.value)}
                      required
                    >
                      <option value="todos">Todos</option>
                      {Array.from({ length: 10 }, (_, i) => (
                        <option key={`${i + 1}`} value={i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    </select>
                  ) : cp.tipo ? (
                    cp.tipo
                  ) : (
                    "-"
                  )}
                </td>

                <td
                  className={edicao[`cupom${cp.id}`] ? "editavel" : ""}
                  style={{ width: "5%" }}
                >
                  {edicao[`cupom${cp.id}`] ? (
                    <select
                      name="status"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="ativado">Ativado</option>
                      <option value="desativado">Desativado</option>
                    </select>
                  ) : cp.status ? (
                    "Ativado"
                  ) : (
                    "Desativado"
                  )}
                  {/* {`${cp.status}`} */}
                </td>

                <td
                  className={edicao[`cupom${cp.id}`] ? "editavel" : ""}
                  style={{ width: "10%" }}
                >
                  {edicao[`cupom${cp.id}`] ? (
                    <input
                      type="date"
                      name="validade"
                      value={validade}
                      onChange={(e) => {
                        setValidade(e.target.value);
                      }}
                      required
                    />
                  ) : cp.validade ? (
                    moment(cp.validade).format("DD/MM/YYYY")
                  ) : (
                    "-"
                  )}
                </td>

                <td style={{ width: "6%" }}>
                  {edicao[`cupom${cp.id}`] ? (
                    <React.Fragment>
                      <button
                        className="botao-salvar"
                        onClick={() => handleUpdate(cp.id)}
                        disabled={carregando ? true : false}
                      >
                        <FontAwesomeIcon icon={faSave} />
                      </button>
                      <button
                        className="botao-excluir"
                        onClick={() => excluirCupom(cp.id)}
                        disabled={carregando ? true : false}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <button
                        className="botao-editar"
                        onClick={() => editarCupom(cp.id)}
                        disabled={carregando ? true : false}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button
                        className="botao-excluir"
                        onClick={() => excluirCupom(cp.id)}
                        disabled={carregando ? true : false}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </React.Fragment>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ModalCupon
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        addCupom={addCupom}
      />
    </div>
  );
};

export default ListaCupom;
