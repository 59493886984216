import React from 'react';
import { CONSTANTES } from '../../global/CONSTANTES';


function OfertaList({lista, estabelecimento_id}) {
  return (
    <div className='oferta-lista-img'>
        {lista.map((item, index) => (
            <img src={`${CONSTANTES.uri}${CONSTANTES.caminhoImageEstabelecimentos}${estabelecimento_id}${CONSTANTES.oferta}${item.foto}`} alt={`Oferta ${index}`} key={index}/>
        ))}
    </div>
  )
}

export default OfertaList;