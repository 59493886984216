// EstablishmentList.jsx
import React, { useEffect, useState } from "react";
import "./styles.css";
import { faHeart, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLORS } from "../../global/STYLE";
import Stars from "../star/Stars";
import Estabelecimento from "./Estabelecimento";
import noImage from "../../assets/images/noimage.png";
import { CONSTANTES } from "../../global/CONSTANTES";
import CompartilharEstabelecimento from "./CompartilharEstabelecimento";
import { useAuth } from "../../contexts/AuthContext";

const EstablishmentList = ({ establishments }) => {
  const {statusEstabelecimentoCompartilhado, estabelecimentoCompartilhadoURL,estabelecimentoIDCompartilhadoURL} = useAuth()
  const [modalAberto, setModalAberto] = useState(false);
  const [estabelecimentoID, setEstabelecimentoID] = useState(0);
  const [statusEstabelecimentoCompartilhadoLocal, setStatusEstabelecimentoCompartilhadoLocal] = useState(false);
  // console.log("establishments => ",establishments)

  const abrirModal = (id) => {
    setModalAberto(true);
    setEstabelecimentoID(id);
  };

  const fecharModal = () => {
    setModalAberto(false);
    statusEstabelecimentoCompartilhado({},"_",false)
    setStatusEstabelecimentoCompartilhadoLocal(false)
  };

  useEffect(() => {   
    if (
      estabelecimentoIDCompartilhadoURL != null && // Isso cobre tanto null quanto undefined
      Object.keys(estabelecimentoCompartilhadoURL).length > 0
    ) {
      // console.log("Há dados");
      abrirModal(estabelecimentoIDCompartilhadoURL);
      setStatusEstabelecimentoCompartilhadoLocal(true);
    }
    
  }, [estabelecimentoCompartilhadoURL, estabelecimentoIDCompartilhadoURL]);
  

  return (
    <div className="establishment-list">
      {establishments.map((establishment, index) => (
        <div key={index}>
        <div
          className="establishment-card"
          
          onClick={() => abrirModal(establishment.id)}
          style={{ cursor: "pointer" }}
        >
  
          <div className="image-container">
            <img
              src={
                establishment?.foto
                  ? CONSTANTES.uri +
                    CONSTANTES.caminhoImageEstabelecimentos +
                    establishment.id +
                    "/" +
                    establishment.foto
                  : noImage
              }
              alt={establishment.name}
              className="establishment-image"
            />
            {/* <button className="like-button">
              <FontAwesomeIcon
                icon={faHeart}
                size="1x"
                color={establishment.favorito ? "red" : "grey"}
              />
            </button> */}
            <div className="distance">
              <span className="distance-icon">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  color={COLORS.placeHolder}
                />
              </span>
              {establishment.distance}
            </div>
          </div>
          <div className="info-container">
            <h2 className="establishment-name">{establishment.nome}</h2>
            <div className="rating">
              <Stars stars={establishment.star} showNumber={true} />
              {/* {`⭐ ${establishment.rating}`} */}
            </div>
            <p className="address">
              {establishment.logradouro} {establishment.numero}
            </p>
            <p className="address">{establishment.bairro}</p>
            {establishment && establishment.cidade && establishment.uf && (
              <p className="city-country">{`${establishment.cidade}, ${establishment.uf}`}</p>
            )}
          </div>
        </div>
        <CompartilharEstabelecimento id={establishment.id} estabelecimento={establishment.nome} secretKey={CONSTANTES.SECRET_KEY_CRIPTOGRAFIA}  title="Compartilhar esse estabelecimento"/>
        </div>
      
      ))}

      {modalAberto && (
        <Estabelecimento
          id={estabelecimentoID}
          data={statusEstabelecimentoCompartilhadoLocal ? estabelecimentoCompartilhadoURL.estabelecimento : establishments.find((item) => item.id === estabelecimentoID)}
          onClose={fecharModal}
        />
      )}
    </div>
  );
};

export default EstablishmentList;
