import React from "react";
import { encodeID } from "../../functions/criptografia";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../global/STYLE";

const CompartilharEstabelecimento = ({ id, secretKey, estabelecimento, modelo = "escrito", size='lg', padding="10px 5px" , borderRadius = 10, backgroundColor="rgba(0,0,0,0.3)" ,title = "" }) => {
  const shareEstablishment = async () => {
    if (!secretKey || typeof secretKey !== 'string') {
      console.error("A 'secretKey' é obrigatória e deve ser uma string válida.");
      return;
    }

    const encodedId = encodeID(id, secretKey);
    const establishmentURL = `https://facilite.app/?e=${encodedId}`;

    if (navigator.share) {
      try {
        await navigator.share({
          title: "Compartilhar Estabelecimento",
          text: `Confira este estabelecimento: ${estabelecimento}`,
          url: establishmentURL,
        });
        // console.log("Compartilhado com sucesso");
      } catch (error) {
        console.error("Erro ao compartilhar:", error);
      }
    } else {
      console.error("API Web Share não está disponível neste navegador.");
    }
  };

  // eslint-disable-next-line default-case
  switch(modelo){
    case 'escrito':
      return (
        <div
          style={{
            padding: 10,
            backgroundColor: 'rgba(0,0,0,0.1)',
            // width: 40,
            borderBottomLeftRadius: 5,
            borderBottomRightRadiusRadius: 5,
            borderBottomWidth: 1,
            borderColor: "rgba(255,255,255,0.1)",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            gap:5,
            fontSize:"0.9rem",
            display:'flex',
            justifyItems:'center',
            alignItems:'center'
          }}
          onClick={shareEstablishment}
          title={title}
        >
          <FontAwesomeIcon
            icon={faShare}
            color={COLORS.placeHolder}
          />
          Compartilhar esse estabelecimento
        </div>
      );
      break
    case 'icone':
      return (
        <div
          style={{
            backgroundColor,
            // width: 40,
            borderRadius:borderRadius,
            borderBottomWidth: 1,
            borderColor: "rgba(255,255,255,0.1)",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            gap:5,
            fontSize:"0.9rem",
            display:'flex',
            justifyItems:'center',
            alignItems:'center',
            padding,


          }}
          onClick={shareEstablishment}
        >
          <FontAwesomeIcon
            icon={faShare}
            color={COLORS.primary}
            size={size}
          />
        </div>
      );
      break 
  }
  return (
    <div
      style={{
        padding: 10,
        backgroundColor: 'rgba(0,0,0,0.1)',
        // width: 40,
        borderBottomLeftRadius: 5,
        borderBottomRightRadiusRadius: 5,
        borderBottomWidth: 1,
        borderColor: "rgba(255,255,255,0.1)",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        gap:5,
        fontSize:"0.9rem",
        display:'flex',
        justifyItems:'center',
        alignItems:'center'
      }}
      onClick={shareEstablishment}
    >
      <FontAwesomeIcon
        icon={faShare}
        color={COLORS.placeHolder}
      />
      Compartilhar esse estabelecimento
    </div>
  );
};

export default CompartilharEstabelecimento;
