import React, { useState, useEffect } from "react";
import estadosData from "../../data/Estados.json";
import cidadesData from "../../data/Cidades.json";
import "./PerfilScreen.css";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { clienteService } from "../../services/cliente";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../global/STYLE";
import MaskedInput from "react-text-mask";

const PerfilScreen = () => {
  const navigate = useNavigate();
  const { user, setUser, setIsLogado, token, validarLogin, logout } = useAuth();

  const [formData, setFormData] = useState({});
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [selectedEstado, setSelectedEstado] = useState(null);
  const [selectedCidade, setSelectedCidade] = useState(null);
  const [cidadesFiltradas, setCidadesFiltradas] = useState([]);
  const [cidadeDropdownKey, setCidadeDropdownKey] = useState(0);
  const [noAct, setNoAct] = useState(true);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [senha, setSenha] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [carregando, setCarregando] = useState(false);

  const [viewPass, setViewPass] = useState(false);
  const [viewPassNew, setViewPassNew] = useState(false);
  const [viewPassRepeat, setViewPassRepeat] = useState(false);

  const handlePassword = async () => {
    setCarregando(true);
    if (senha.length < 8) {
      setCarregando(false);
      return toast.error("Por favor, preencha o campo de senha!");
    }
    if (password.length < 8) {
      setCarregando(false);
      return toast.error("Por favor, preencha o campo de nova senha!");
    }
    if (rePassword.length < 8) {
      setCarregando(false);
      return toast.error("Por favor, preencha o campo de repetir nova senha!");
    }

    try {
      const dt = {
        senha,
        password,
        re_password: rePassword,
        cliente_id: user.id,
      };
      const dados = await clienteService.mudarSenha(token, dt);
      if (!dados.error) {
        toast.success(dados.message);
        logout();
        setModalVisibility(false);
        navigate("/");
      } else if (dados.reload) {
        toast.error(dados.message);
        logout();
        navigate("/");
      } else {
        toast.error(dados.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setCarregando(false);
    }
  };

  const handleInputChange = (chave, valor) => {
    setFormData((prevState) => ({
      ...prevState,
      [chave]: valor,
    }));
  };

  const handleModalVisibility = (bool) => {
    setModalVisibility(bool);
  };

  const handleInputBlur = async () => {
    try {
      const dados = await clienteService.updatePerfil(token, formData);
      if (dados.error) {
        toast.error(dados.message);
        if (dados.reload) {
          logout()
          navigate("/")
        } 
      } else {
        validarLogin();
      }
    } catch (e) {
      console.log(`Error: ${e}`);
    }
  };

  useEffect(() => {
    if (selectedCidade && !noAct) {
      handleInputBlur();
    }
    setNoAct(false);
  }, [selectedCidade]);

  useEffect(() => {
    setEstados(estadosData);
    setCidades(cidadesData);
    setFormData((prevState) => ({
      ...prevState,
      cliente_id: user.id,
      nome: user.nome || prevState.nome,
      email: user.email || prevState.email,
      data_nascimento: user.data_nascimento || prevState.data_nascimento,
      uf: user.uf || prevState.uf,
      cidade: user.cidade || prevState.cidade,
      logradouro: user.logradouro || prevState.logradouro,
      numero: user.numero || prevState.numero,
      bairro: user.bairro || prevState.bairro,
      telefone: user.telefone || prevState.telefone,
      sexo: user.sexo || prevState.sexo,
      cep: user.cep || prevState.cep,
    }));
    
    const estadoIndice = estadosData.findIndex((obj) => obj.Nome === user.uf);
    setSelectedEstado(estadosData[estadoIndice]);
    
    if (estadoIndice !== -1) {
      const cidadesDoEstado = cidadesData.filter(
        (cidade) => cidade.Estado === estadosData[estadoIndice].ID
      );
      setCidadesFiltradas(cidadesDoEstado);
      const cidadeIndice = cidadesDoEstado.findIndex((cidade) => cidade.Nome === user.cidade);
      setSelectedCidade(cidadesDoEstado[cidadeIndice]);
    }
  }, [user]);

  useEffect(() => {
    if (selectedEstado) {
      const cidadesDoEstado = cidades.filter(
        (cidade) => cidade.Estado === selectedEstado.ID
      );
      setCidadesFiltradas(cidadesDoEstado);
      setSelectedCidade(null); // Apaga a cidade selecionada
      setFormData((prevState) => ({
        ...prevState,
        cidade: "", // Limpa a cidade no formData
        uf: selectedEstado.Nome, // Atualiza o estado no formData
      }));
      setCidadeDropdownKey((prevKey) => prevKey + 1);
      
      // Adiciona um delay de 500ms antes de chamar handleInputBlur
      setTimeout(() => {
        handleInputBlur(); // Salva a alteração no banco de dados
      }, 500);
    } else {
      setCidadesFiltradas([]);
    }
  }, [selectedEstado]);

  return (
    <div className="perfil-container">
      <div className="perfil-header">
        <h2 className="perfil-topo-title">Perfil do cliente</h2>
      </div>

      <div className="perfil-body">
        <a href={`https://facilite.app/delete-account`} style={{padding:10, textDecoration:'none', background:COLORS.bgButtonCard, width:'10%', display:'flex', textAlign:'center', justifyContent:'center', alignItems:'center', borderRadius:5, color:COLORS.cancelado, marginBottom:10, cursor:'pointer'}} title="Excluir conta" alt="excluir conta">
          Excluir conta
        </a>
        <p className="perfil-description">
          Gerencie seus dados para melhorar a visualização dos estabelecimentos.
        </p>
        <div className="perfil-scroll">
          <div className="perfil-info-container">
            <h3 className="perfil-form-title">Dados Pessoais</h3>

            {/* NOME */}
            <div className="perfil-bloco">
              <label className="perfil-label">Nome</label>
              <div className="perfil-input-group">
                <input
                  type="text"
                  className="perfil-input"
                  placeholder="Digite seu nome completo"
                  value={formData.nome}
                  onChange={(e) => handleInputChange("nome", e.target.value)}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>

            {/* E-MAIL */}
            <div className="perfil-bloco">
              <label className="perfil-label">E-mail</label>
              <div className="perfil-input-group">
                <input
                  type="email"
                  className="perfil-input"
                  placeholder="Digite o e-mail, ex: email@mail.com"
                  value={formData.email}
                  readOnly
                />
              </div>
            </div>

            {/* SENHA */}
            <div className="perfil-bloco">
              <label className="perfil-label">Senha</label>
              {/* <button > */}
                <div className="perfil-input-group" onClick={() => handleModalVisibility(true)}>
                  <input
                    type="password"
                    className="perfil-input"
                    placeholder="********"
                    readOnly
                  />
                </div>
              {/* </button> */}
            </div>

            {/* TELEFONE */}
            <div className="perfil-bloco">
              <label className="perfil-label">Telefone</label>
              <div className="perfil-input-group">
                <MaskedInput
                  mask={[
                    "(",
                    /[1-9]/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  className="perfil-input"
                  placeholder="(xx) xxxxx-xxxx"
                  value={formData.telefone}
                  onChange={(e) => handleInputChange("telefone", e.target.value)}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>

            {/* SEXO */}
            <div className="perfil-bloco">
              <label className="perfil-label">Sexo</label>
              <div className="perfil-input-group">
                <select
                  value={formData.sexo}
                  onChange={(e) => handleInputChange("sexo", e.target.value)}
                  onBlur={handleInputBlur}
                  className="perfil-input"
                >
                  <option value="0" disabled>
                    Selecione o sexo
                  </option>
                  <option value="masculino">Masculino</option>
                  <option value="feminino">Feminino</option>
                  <option value="outro">Outro</option>
                  <option value="naoDizer">Prefiro não dizer</option>
                </select>
              </div>
            </div>

            {/* DATA NASCIMENTO */}
            <div className="perfil-bloco">
              <label className="perfil-label">Data de nascimento</label>
              <div className="perfil-input-group">
                <MaskedInput
                  mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                  className="perfil-input"
                  placeholder="DD/MM/AAAA"
                  value={formData.data_nascimento}
                  onChange={(e) => handleInputChange("data_nascimento", e.target.value)}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>

            {/* LOGRADOURO */}
            <div className="perfil-bloco">
              <label className="perfil-label">Logradouro</label>
              <div className="perfil-input-group">
                <input
                  type="text"
                  className="perfil-input"
                  placeholder="Digite seu logradouro aqui"
                  value={formData.logradouro}
                  onChange={(e) => handleInputChange("logradouro", e.target.value)}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>

            {/* NÚMERO DA CASA */}
            <div className="perfil-bloco">
              <label className="perfil-label">Número</label>
              <div className="perfil-input-group">
                <input
                  type="text"
                  className="perfil-input"
                  placeholder="Digite o número do local onde você mora."
                  value={formData.numero}
                  onChange={(e) => handleInputChange("numero", e.target.value)}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>

            {/* BAIRRO */}
            <div className="perfil-bloco">
              <label className="perfil-label">Bairro</label>
              <div className="perfil-input-group">
                <input
                  type="text"
                  className="perfil-input"
                  placeholder="Digite o bairro onde você mora."
                  value={formData.bairro}
                  onChange={(e) => handleInputChange("bairro", e.target.value)}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>

            {/* ESTADO */}
            <div className="perfil-bloco">
              <label className="perfil-label">Estado</label>
              <div className="perfil-input-group">
                <select
                  value={selectedEstado?.Nome || ""}
                  onChange={(e) => {
                    const selectedItem = estados.find(
                      (estado) => estado.Nome === e.target.value
                    );
                    setSelectedEstado(selectedItem);
                    setSelectedCidade(null); // Apaga a cidade selecionada
                    setFormData((prevState) => ({
                      ...prevState,
                      cidade: "", // Limpa a cidade no formData
                      uf: e.target.value, // Atualiza o estado no formData
                    }));

                    // Adiciona um delay de 500ms antes de chamar handleInputBlur
                    setTimeout(() => {
                      handleInputBlur(); // Salva a alteração no banco de dados
                    }, 500);
                  }}
                  className="perfil-input"
                >
                  <option value="" disabled>
                    Selecione um estado
                  </option>
                  {estados.map((estado) => (
                    <option key={estado.ID} value={estado.Nome}>
                      {estado.Nome}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* CIDADE */}
            <div className="perfil-bloco">
              <label className="perfil-label">Cidade</label>
              <div className="perfil-input-group">
                <select
                  key={cidadeDropdownKey}
                  value={selectedCidade?.Nome || ""}
                  onChange={(e) => {
                    const selectedItem = cidadesFiltradas.find(
                      (cidade) => cidade.Nome === e.target.value
                    );
                    setSelectedCidade(selectedItem);
                    handleInputChange("cidade", e.target.value);
                    handleInputBlur();
                  }}
                  className="perfil-input"
                  disabled={!selectedEstado}
                >
                  <option value="" disabled>
                    Selecione uma cidade
                  </option>
                  {cidadesFiltradas.map((cidade) => (
                    <option key={cidade.ID} value={cidade.Nome}>
                      {cidade.Nome}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalVisibility && (
        <div className="perfil-modal">
          <div className="perfil-modal-content">
            <button
              onClick={() => {
                setPassword("");
                setRePassword("");
                setSenha("");
                setViewPass(false);
                setViewPassNew(false);
                setViewPassRepeat(false);
                handleModalVisibility(false);
              }}
              className="btn-pass"
            >
              <FontAwesomeIcon
                icon={faTimesCircle}
                color={COLORS.primary}
                size="2x"
                style={{ marginBottom: 10 }}
              />
            </button>
            <div>
              <div className="perfil-bloco">
                <p className="perfil-warning">
                  Aviso importante! Ao mudar a senha, você precisará fazer o
                  login novamente!
                </p>
              </div>
              <div className="perfil-bloco">
                <label className="perfil-label">Senha</label>
                <div className="perfil-input-group-senha">
                  <input
                    type={viewPass ? "text" : "password"}
                    className="perfil-input-senha"
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                  />
                  <button
                    onClick={() => setViewPass(!viewPass)}
                    className="btn-pass"
                  >
                    {viewPass ? (
                      <FontAwesomeIcon
                        icon={faEye}
                        color={COLORS.primary}
                        size="x"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        color={COLORS.primary}
                        size="x"
                      />
                    )}
                  </button>
                </div>
              </div>

              <div className="perfil-bloco">
                <label className="perfil-label">Nova Senha</label>
                <div className="perfil-input-group-senha">
                  <input
                    type={viewPassNew ? "text" : "password"}
                    className="perfil-input-senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    onClick={() => setViewPassNew(!viewPassNew)}
                    className="btn-pass"
                  >
                    {viewPassNew ? (
                      <FontAwesomeIcon
                        icon={faEye}
                        color={COLORS.primary}
                        size="x"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        color={COLORS.primary}
                        size="x"
                      />
                    )}
                  </button>
                </div>
              </div>

              <div className="perfil-bloco">
                <label className="perfil-label">Repetir Nova Senha</label>
                <div className="perfil-input-group-senha">
                  <input
                    type={viewPassRepeat ? "text" : "password"}
                    className="perfil-input-senha"
                    value={rePassword}
                    onChange={(e) => setRePassword(e.target.value)}
                  />
                  <button
                    onClick={() => setViewPassRepeat(!viewPassRepeat)}
                    className="btn-pass"
                  >
                    {viewPassRepeat ? (
                      <FontAwesomeIcon
                        icon={faEye}
                        color={COLORS.primary}
                        size="x"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        color={COLORS.primary}
                        size="x"
                      />
                    )}
                  </button>
                </div>
              </div>

              <div style={{ textAlign: "center" }}>
                <button onClick={handlePassword} className="perfil-button" disabled={carregando}>
                  {carregando ? <span className="loader"></span> : "Alterar Senha"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PerfilScreen;
