import React, { useEffect, useState } from 'react';
import './Planos.css';
import { planoService } from '../../services/plano';

const getSelo = (tipo) => {
  switch(tipo) {
    case 'Bronze':
      return (
        <svg className="selo bronze" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="40" />
          <text x="50%" y="50%" dy=".3em" textAnchor="middle">B</text>
        </svg>
      );
    case 'Prata':
      return (
        <svg className="selo prata" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="40" />
          <text x="50%" y="50%" dy=".3em" textAnchor="middle">P</text>
        </svg>
      );
    case 'Ouro':
      return (
        <svg className="selo ouro" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="40" />
          <text x="50%" y="50%" dy=".3em" textAnchor="middle">O</text>
        </svg>
      );
    case 'Diamante':
      return (
        <svg className="selo diamante" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="40" />
          <text x="50%" y="50%" dy=".3em" textAnchor="middle">D</text>
        </svg>
      );
    default:
      return null;
  }
}

const Planos = () => {
  const [planos, setPlanos] = useState([]);

  const pegaDados = async () => {
    try {
      const dados = await planoService.pegaPlanosST();
      if (!dados.error) {
        setPlanos(dados.data);
      }
    } catch (e) {
      console.error("Erro:", e.message);
    }
  }

  useEffect(() => {
    pegaDados();
  }, []);

  return (
    <div className="planos-page">
      <h1 className="titulo-planos">Nossos Planos</h1>
      <div className="planos-container">
        {planos && planos.map((plano, index) => (
          <div key={index} className="card-planos">
            {getSelo(plano.plano)}
            <h2>{plano.plano}</h2>
            <p className="preco">R$ {plano.valor}/{plano.tipo}</p>
            <ul>
              {plano.descricoes && plano.descricoes.map((descricao, idx) => (
                <li key={idx}><span className='plan-card-span'>{descricao.quantidade}</span> {descricao.descricao}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Planos;
