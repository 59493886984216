// export function converteSegundos(seconds) {
//     const hours = Math.floor(seconds / 3600);
//     const minutes = Math.floor((seconds % 3600) / 60);
  
//     let duration = '';
//     if (hours > 0) {
//       duration += `${hours}`;
//     }else{
//       duration += '00'
//     }
//     if (minutes > 0) {
//       duration += `${minutes}`;
//     }else{
//       duration += "00"
//     }
//     // if (hours === 0 && minutes === 0) {
//     //   duration = `${seconds}`;
//     // }
  
//     return duration;
//   }
  

export function converteSegundos(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

export function converteParaSegundos(horario) {
  const [hours, minutes] = horario.split(':').map(Number);
  return (hours * 3600) + (minutes * 60);
}

