import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./styles.css"; 
import { useAuth } from "../../../contexts/AuthContext";
import { planoService } from "../../../services/plano";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Set the app element to avoid screen readers issues
Modal.setAppElement("#root");

const ModalDestaquePlano = ({ isOpen, onRequestClose, plano_id, onUpdate  }) => {
  const { token, logout } = useAuth();
  const navigate = useNavigate();

  const [descriptions, setDescriptions] = useState([
    { quantidade: "", descricao: "" },
  ]);
  const [id, setID] = useState(0);
  const [carregando, setCarregando] = useState(false);

  useEffect(() => {
    setDescriptions([{ quantidade: "", descricao: "" }]);
  }, [plano_id]);

  const handleAddDescription = () => {
    setDescriptions([...descriptions, { quantidade: "", descricao: "" }]);
  };

  const handleDescriptionChange = (index, field, value) => {
    const newDescriptions = descriptions.map((desc, i) =>
      i === index ? { ...desc, [field]: value } : desc
    );
    setDescriptions(newDescriptions);
  };

  const handleRemoveLastDescription = () => {
    if (descriptions.length > 1) {
      setDescriptions(descriptions.slice(0, -1));
    }
  };

  const handleSubmit = async () => {
    setCarregando(true);
    for (const desc of descriptions) {
      if (!desc.quantidade || !desc.descricao) {
        toast.error(
          "Todos os campos de quantidade e descrição devem estar preenchidos."
        );
        setCarregando(false);
        return;
      }
    }

    const dado = {
      plano_id,
      descricoes: descriptions,
    };
    const data = await planoService.cadastraDetalhePlano(token, dado);
    if (data.error) {
      toast.error(data.message);
      if (data.reload) {
        setCarregando(false);
        navigate("/login/adm_facilite");
        logout();
      }
      setCarregando(false);
    } else {
      setCarregando(false);
      // console.log(data.data)
      toast.success(data.message);
      setDescriptions([{ quantidade: "", descricao: "" }])
      onUpdate(data.data);
      onRequestClose(); 
    }
    setCarregando(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Formulário de Plano"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <div
        style={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
          marginBottom: 15,
        }}
      >
        <h3>Cadastrar Novo Detalhe</h3>
        <button onClick={onRequestClose} className="close-button">
          Fechar
        </button>
      </div>
      <form className="form-content">
        <br />
        {descriptions.map((desc, index) => (
          <div key={index} className="form-group-inline">
            <div className="form-group">
              <label>Quantidade</label>
              <input
                type="text"
                value={desc.quantidade}
                onChange={(e) =>
                  handleDescriptionChange(index, "quantidade", e.target.value)
                }
              />
            </div>
            <div className="form-group">
              <label>Descrição</label>
              <input
                type="text"
                value={desc.descricao}
                onChange={(e) =>
                  handleDescriptionChange(index, "descricao", e.target.value)
                }
              />
            </div>
          </div>
        ))}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <button
            type="button"
            onClick={handleAddDescription}
            className="add-button"
          >
            Adicionar mais detalhes
          </button>

          <button
            type="button"
            onClick={handleRemoveLastDescription}
            className="rem-button"
          >
            Remover último detalhe
          </button>
        </div>

        <button type="button" onClick={handleSubmit} className="cad-button" disabled = {carregando ? true : false}>
        {carregando ? <span className="loader"></span> : "Cadastrar Detalhe"}
        </button>
      </form>
    </Modal>
  );
};

export default ModalDestaquePlano;
