import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faEdit,
  faTrash,
  faSave,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import "./styles.css";
import { COLORS } from "../../../global/STYLE";
import { formatarValorReal } from "../../../functions/formatarValorReal";
import { planoService } from "../../../services/plano";
import { useAuth } from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ModalDestaquePlano from "./ModalDestaque";

const ServicoPlano = ({ plano }) => {
  const { token, logout } = useAuth();
  const navigate = useNavigate();

  const [expandido, setExpandido] = useState(null);
  const [edicao, setEdicao] = useState({});
  const [dadosEditados, setDadosEditados] = useState([]);
  const [descricaoEditada, setDescricaoEditada] = useState({});

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [plano_id, setPlano_id] = useState(null);

  const openModal = (id) => {
    setPlano_id(id);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    setDadosEditados(plano);
  }, [plano]);

  const handleUpdate = (novoDado) => {
    // console.log("Novo Dado = ", novoDado);
  
    setDadosEditados((prevDados) => {
      const dadosAtualizados = prevDados.map((dado) =>
        dado.id === novoDado.plano_id
          ? {
              ...dado,
              descricoes: Array.isArray(novoDado.novasDescricoes)
                ? [...dado.descricoes, ...novoDado.novasDescricoes]
                : dado.descricoes,
            }
          : dado
      );
  
      // Caso o plano_id não exista no array atual, adicionamos um novo
      if (!dadosAtualizados.some((dado) => dado.id === novoDado.plano_id)) {
        dadosAtualizados.push({
          id: novoDado.plano_id,
          descricoes: Array.isArray(novoDado.novasDescricoes)
            ? novoDado.novasDescricoes
            : [],
        });
      }
  
      return dadosAtualizados;
    });
  
  };
  
  

  const toggleExpandido = (id) => {
    setExpandido(expandido === id ? null : id);
  };

  const handlePlanoInputChange = (event, planoId, campo) => {
    const value = event.target.value;
    const novosDados = dadosEditados.map((item) => {
      if (item.id === planoId) {
        return { ...item, [campo]: value };
      }
      return item;
    });
    setDadosEditados(novosDados);
  };

  const handlePlanoInputValorChange = (event, planoId, campo) => {
    let value = event.target.value;
    // Remove caracteres não numéricos
    value = value.replace(/\D/g, "");
    // Remove os zeros à esquerda
    value = value.replace(/^0+/, "");
    // Preenche com zeros à esquerda até ter 6 caracteres
    value = value.padStart(6, "0");
    // Divide o valor para obter a parte inteira e a parte decimal
    const parteInteira = value.slice(0, -2) || "0";
    const parteDecimal = value.slice(-2);
    // Formata o valor para o formato de reais
    const valorFormatado = `${parteInteira}.${parteDecimal}`;

    const novosDados = dadosEditados.map((item) => {
      if (item.id === planoId) {
        return { ...item, [campo]: valorFormatado };
      }
      return item;
    });
    setDadosEditados(novosDados);
  };

  const salvarDescricao = async (descricaoId) => {
    const descricaoEditadaAtual = descricaoEditada[descricaoId];
    if (!descricaoEditadaAtual) return;

    const planoId = dadosEditados.find((plano) =>
      plano.descricoes.some((descricao) => descricao.id === descricaoId)
    ).id;

    // Atualiza os dados do plano com a descrição editada
    const novosDados = dadosEditados.map((plano) => {
      if (plano.id === planoId) {
        return {
          ...plano,
          descricoes: plano.descricoes.map((descricao) => {
            if (descricao.id === descricaoId) {
              return {
                ...descricao,
                ...descricaoEditadaAtual,
              };
            }
            return descricao;
          }),
        };
      }
      return plano;
    });

    // Limpa a descrição editada do estado
    setDescricaoEditada((prevDescricaoEditada) => {
      const { [descricaoId]: deleted, ...rest } = prevDescricaoEditada;
      // console.log("REST => ",rest)
      return rest;
    });

    const data = await planoService.atualizaPlano(
      token,
      novosDados.find((item) => item.id === planoId)
    );

    if (data.error) {
      toast.error(data.message);
      if (data.reload) {
        navigate("/login/adm_facilite");
        logout();
      }
    } else {
      // Atualiza os dados editados com os novos dados do plano
      setDadosEditados(novosDados);

      toast.success(data.message);
    }

    // Log dos dados atualizados do plano e descrição
    // console.log("Salvar descrição", descricaoId, novosDados.find(item => item.id === planoId));
  };

  const salvarPlano = async (planoId) => {
    // Encontra o plano correspondente ao planoId
    const planoAtualizado = dadosEditados.find((item) => item.id === planoId);

    // Lógica para salvar o plano com id planoId
    // console.log("Salvar plano", planoId, planoAtualizado);

    const data = await planoService.atualizaPlano(token, planoAtualizado);

    if (data.error) {
      toast.error(data.message);
      if (data.reload) {
        navigate("/login/adm_facilite");
        logout();
      }
    } else {
      toast.success(data.message);
    }

    // Log das descrições atualizadas associadas ao plano
    // planoAtualizado.descricoes.forEach((descricao) => {
    //   console.log("Salvar descrição", descricao.id, descricao);
    // });

    // Limpa as edições pendentes
    setEdicao({});
  };

  const excluirPlano = async (planoId) => {
    if (window.confirm("Tem certeza que deseja excluir este plano?")) {
      // Remover o plano com o ID especificado do estado

      try {
        const dado = {
          plano_id: planoId,
        };
        const data = await planoService.deletePlano(token, dado);
        if (data.error) {
          toast.error(data.message);
          if (data.reload) {
            navigate("/login/adm_facilite");
            logout();
          }
        } else {
          setDadosEditados((prevDados) =>
            prevDados.filter((item) => item.id !== planoId)
          );
          toast.success(data.message);
        }
      } catch (e) {
        toast.error("Erro ao tentar excluir um plano");
      }

      // Lógica para excluir o plano com id planoId
      // console.log("Excluir plano", planoId);
    }
  };

  const editarPlano = (planoId) => {
    setEdicao({ [`plano${planoId}`]: true });
  };

  const editarDescricao = (planoId, descricaoId) => {
    setDescricaoEditada({
      ...descricaoEditada,
      [descricaoId]: {
        ...dadosEditados
          .find((item) => item.id === planoId)
          .descricoes.find((descricao) => descricao.id === descricaoId),
      },
    });
  };

  const handleDescricaoInputChange = (event, descricaoId, campo) => {
    const value = event.target.value;
    setDescricaoEditada((prevDescricaoEditada) => ({
      ...prevDescricaoEditada,
      [descricaoId]: {
        ...prevDescricaoEditada[descricaoId],
        [campo]: value,
      },
    }));
  };

  const excluirDescricao = async (descricaoId, itemID) => {
    // console.log(descricaoId, itemID)
    if (window.confirm("Tem certeza que deseja excluir esta descrição?")) {
      try {
        const dado = {
          descricao_id : descricaoId,
          plano_id:itemID
        };
        const data = await planoService.deleteDetalhePlano(token, dado);
        if (data.error) {
          toast.error(data.message);
          if (data.reload) {
            navigate("/login/adm_facilite");
            logout();
          }
        } else {
          // Encontrar o plano que contém a descrição a ser excluída
          const planoComDescricao = dadosEditados.find((plano) =>
            plano.descricoes.some((descricao) => descricao.id === descricaoId)
          );

          // Remover a descrição do plano
          const novoPlano = {
            ...planoComDescricao,
            descricoes: planoComDescricao.descricoes.filter(
              (descricao) => descricao.id !== descricaoId
            ),
          };

          // Atualizar o estado com o plano modificado
          setDadosEditados((prevDados) =>
            prevDados.map((plano) =>
              plano.id === novoPlano.id ? novoPlano : plano
            )
          );

          toast.success(data.message);
        }
      } catch (e) {
        toast.error("Erro ao tentar excluir detalhe do plano.");
      }

      // Lógica para excluir a descrição com id descricaoId
      // console.log("Excluir descrição", descricaoId);
    }
  };

  return (
    <div className="servico-plano servico-planow">
      <table>
        <thead>
          <tr style={{ textAlign: "left" }}>
            <th>Plano</th>
            <th>Valor</th>
            <th>Limite de Imagem</th>
            <th>Limite de Oferta</th>
            <th>Limite de Colaborador</th>
            <th>Liberar Agendamento</th>
            <th>Liberar Oferta</th>
            <th>Nível</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {dadosEditados.map((item) => (
            <React.Fragment key={item.id}>
              <tr>
                <td className={edicao[`plano${item.id}`] ? "editavel" : ""}>
                  {edicao[`plano${item.id}`] ? (
                    <input
                      type="text"
                      value={item.plano}
                      onChange={(e) =>
                        handlePlanoInputChange(e, item.id, "plano")
                      }
                    />
                  ) : (
                    item.plano
                  )}
                </td>
                {/* Colunas restantes para o plano */}
                <td className={edicao[`plano${item.id}`] ? "editavel" : ""}>
                  {edicao[`plano${item.id}`] ? (
                    <>
                      R$
                      <input
                        type="text"
                        placeholder="00,00"
                        value={item.valor}
                        onChange={(e) =>
                          handlePlanoInputValorChange(e, item.id, "valor")
                        }
                      />
                    </>
                  ) : (
                    formatarValorReal(item.valor)
                  )}
                </td>
                {/* Adicione as colunas restantes conforme necessário */}
                <td className={edicao[`plano${item.id}`] ? "editavel" : ""}>
                  {edicao[`plano${item.id}`] ? (
                    <input
                      type="number"
                      value={item.limite_imagem}
                      onChange={(e) =>
                        handlePlanoInputChange(e, item.id, "limite_imagem")
                      }
                    />
                  ) : (
                    item.limite_imagem
                  )}
                </td>
                <td className={edicao[`plano${item.id}`] ? "editavel" : ""}>
                  {edicao[`plano${item.id}`] ? (
                    <input
                      type="number"
                      value={item.limite_oferta}
                      onChange={(e) =>
                        handlePlanoInputChange(e, item.id, "limite_oferta")
                      }
                    />
                  ) : (
                    item.limite_oferta
                  )}
                </td>
                <td className={edicao[`plano${item.id}`] ? "editavel" : ""}>
                  {edicao[`plano${item.id}`] ? (
                    <input
                      type="number"
                      value={item.limite_colaborador}
                      onChange={(e) =>
                        handlePlanoInputChange(e, item.id, "limite_colaborador")
                      }
                    />
                  ) : (
                    item.limite_colaborador
                  )}
                </td>
                <td className={edicao[`plano${item.id}`] ? "editavel" : ""}>
                  <select
                    disabled={!edicao[`plano${item.id}`]}
                    value={item.liberar_agendamento}
                    onChange={(e) =>
                      handlePlanoInputChange(e, item.id, "liberar_agendamento")
                    }
                  >
                    <option value={true}>Sim</option>
                    <option value={false}>Não</option>
                  </select>
                </td>
                <td className={edicao[`plano${item.id}`] ? "editavel" : ""}>
                  <select
                    disabled={!edicao[`plano${item.id}`]}
                    value={item.liberar_oferta}
                    onChange={(e) =>
                      handlePlanoInputChange(e, item.id, "liberar_oferta")
                    }
                  >
                    <option value={true}>Sim</option>
                    <option value={false}>Não</option>
                  </select>
                </td>
                <td className={edicao[`plano${item.id}`] ? "editavel" : ""}>
                  <select
                    disabled={!edicao[`plano${item.id}`]}
                    value={item.nivel}
                    onChange={(e) =>
                      handlePlanoInputChange(e, item.id, "nivel")
                    }
                  >
                    {[...Array(10).keys()].map((n) => (
                      <option key={n + 1} value={n + 1}>
                        {n + 1}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  {edicao[`plano${item.id}`] ? (
                    <React.Fragment>
                      <button
                        className="botao-salvar"
                        onClick={() => salvarPlano(item.id)}
                      >
                        <FontAwesomeIcon icon={faSave} />
                      </button>
                      <button
                        className="botao-excluir"
                        onClick={() => excluirPlano(item.id)}
                        disabled
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <button
                        className="botao-editar"
                        onClick={() => editarPlano(item.id)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button
                        className="botao-excluir"
                        onClick={() => excluirPlano(item.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </React.Fragment>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan="8" style={{ border: "none" }}>
                  <div
                    className="expandir-detalhes"
                    style={{ cursor: "default" }}
                  >
                    <button
                      style={{
                        backgroundColor: COLORS.button,
                        padding: 5,
                        color: COLORS.primaryText,
                        width: "100px",
                        borderRadius: 5,
                        justifyContent: "space-between",
                        alignItems: "center",
                        display: "flex",
                        textAlign: "center",
                      }}
                      onClick={() => toggleExpandido(item.id)}
                    >
                      <span>
                        Detalhes{" "}
                        <FontAwesomeIcon
                          icon={expandido === item.id ? faAngleUp : faAngleDown}
                        />
                      </span>
                    </button>

                    <button
                      style={{
                        backgroundColor: COLORS.concluido,
                        padding: "5px 5px 5px 10px",
                        color: COLORS.primaryText,
                        width: "auto",
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        textAlign: "center",
                      }}
                      onClick={() => openModal(item.id)}
                    >
                      <span>
                        <FontAwesomeIcon icon={faPlus} />
                      </span>
                    </button>
                  </div>
                </td>
              </tr>
              {expandido === item.id && (
                <tr className="detalhes">
                  <td colSpan="8">
                    <table>
                      <thead>
                        <tr style={{ textAlign: "left" }}>
                          <th>Quantidade</th>
                          <th>Descrição</th>
                          <th>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.descricoes.map((descricao, index) => (
                          <tr key={index}>
                            <td
                              className={
                                descricaoEditada[descricao.id] ? "editavel" : ""
                              }
                            >
                              {descricaoEditada[descricao.id] ? (
                                <input
                                  type="text"
                                  value={
                                    descricaoEditada[descricao.id].quantidade
                                  }
                                  onChange={(e) =>
                                    handleDescricaoInputChange(
                                      e,
                                      descricao.id,
                                      "quantidade"
                                    )
                                  }
                                />
                              ) : (
                                descricao.quantidade
                              )}
                            </td>
                            <td
                              className={
                                descricaoEditada[descricao.id] ? "editavel" : ""
                              }
                            >
                              {descricaoEditada[descricao.id] ? (
                                <input
                                  type="text"
                                  value={
                                    descricaoEditada[descricao.id].descricao
                                  }
                                  onChange={(e) =>
                                    handleDescricaoInputChange(
                                      e,
                                      descricao.id,
                                      "descricao"
                                    )
                                  }
                                />
                              ) : (
                                descricao.descricao
                              )}
                            </td>
                            <td>
                              {descricaoEditada[descricao.id] ? (
                                <React.Fragment>
                                  <button
                                    className="botao-salvar"
                                    onClick={() =>
                                      salvarDescricao(descricao.id)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faSave} />
                                  </button>
                                  <button
                                    className="botao-excluir"
                                    onClick={() =>
                                      excluirDescricao(descricao.id, item.id)
                                    }
                                    disabled
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <button
                                    className="botao-editar"
                                    onClick={() =>
                                      editarDescricao(item.id, descricao.id)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </button>
                                  <button
                                    className="botao-excluir"
                                    onClick={() =>
                                      excluirDescricao(descricao.id, item.id)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                </React.Fragment>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <ModalDestaquePlano
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        plano_id={plano_id}
        onUpdate={handleUpdate}
      />
    </div>
  );
};

export default ServicoPlano;
