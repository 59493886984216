import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { homeService } from "../../services/home";
import { estabelecimentoService } from "../../services/estabelecimento";
import SearchBar from "../../components/busca/SearchBar";
import EstablishmentList from "../../components/estabelecimento/EstablishmentList";
import { toast } from "react-toastify";

// import { Container } from './styles';

function Home() {
  let navigate = useNavigate();
  const { userType, dadosHome, isLogado, logout, user, coords } = useAuth();

  const [establishments, setEstablishments] = useState([]);
  const [totalPages, setTotalPages] = useState(0); // Definido manualmente para simulação
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [busca, setBusca] = useState({});

  const [isSearchBarVisible, setIsSearchBarVisible] = useState(true);
  const [isSearchBarFixed, setIsSearchBarFixed] = useState(false);
  const [showButton, setShowButton] = useState(false);

  //carrega os dados uma vez dos estabelecimentos ao montar
  const pegaDados = useCallback(
    async (page, firstLoad = false, reset = false) => {
      setLoading(true);
      try {
        if (coords) {
          const data = await homeService.getEstabelecimentos(
            coords.lat,
            coords.lng,
            "ativado",
            page
          );
          if (!data.error) {
            if (firstLoad) {
              setTotalPages(data.totalPages);
            }
            if (reset) {
              setEstablishments(data.data);
            } else {
              // console.log("data estab = ",data.data)
              setEstablishments((prevEstablishments) => [
                ...prevEstablishments,
                ...data.data,
              ]);
            }
            setCurrentPage(page + 1);
          } else {
            console.log(data.message);
          }
        }else{
          
          const data = await homeService.getEstabelecimentos(
            0,
            0,
            "ativado",
            page
          );
          if (!data.error) {
            if (firstLoad) {
              setTotalPages(data.totalPages);
            }
            if (reset) {
              setEstablishments(data.data);
            } else {
              setEstablishments((prevEstablishments) => [
                ...prevEstablishments,
                ...data.data,
              ]);
            }
            setCurrentPage(page + 1);
          } else {
            console.log(data.message);
          }
        
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    },
    [coords]
  );

  useEffect(() => {
    pegaDados(1, true, true);
  }, [pegaDados]);

  const handleBusca = useCallback(
    async (dados, page, reset = false) => {
      
      // toast.info("handle Busca")
      if (page > totalPages && !reset) return; // Evitar chamadas adicionais desnecessárias
      setLoading(true);
      const createData = {};

      if (
        dados &&
        (dados.name ||
          dados.nomeCidade ||
          dados.nomeEstado ||
          dados.stars ||
          dados.categoria_id)
      ) {
        createData.nome = dados.name || "";
        createData.cidade = dados.nomeCidade ? dados.nomeCidade.Nome : "";
        createData.uf = dados.nomeEstado ? dados.nomeEstado.Nome : "";
        createData.star = dados.stars ? Number(dados.stars) : 0;
        createData.categoria_id = dados.categoria_id
          ? dados.categoria_id.id
          : "";
      } else if (reset) {
        setBusca({});
        setCurrentPage(1);
        pegaDados(1, true, true); // Reset to fetch all data
        return;
      } else {
        createData.nome = "";
        createData.cidade = "";
        createData.uf = "";
        createData.star = 0;
        createData.categoria_id = "";
      }

      createData.page = page;
      createData.perPage = 20;
      if(coords){
        createData.latitude = coords.lat;
        createData.longitude = coords.lng;
      }
      createData.status = "ativado";

      setBusca(createData);

      try {
        const response = await estabelecimentoService.search(createData);
        if (response.error) {
          console.log(response.message);
        } else {
          // toast.info(`${response.data.estabelecimento.nome}`)
          // console.log("RETORNOU OS DADOS: ", response.data.estabelecimento);
          const establishmentsData = response.data.estabelecimento;

          if (Array.isArray(establishmentsData)) {
            if (reset) {
              setEstablishments(establishmentsData);
            } else {
              setEstablishments((prevEstablishments) => [
                ...prevEstablishments,
                ...establishmentsData,
              ]);
            }
            setTotalPages(Math.ceil(response.data.total / createData.perPage));
            setCurrentPage(page + 1);
          } else if (reset) {
            setEstablishments([]);
            // console.error("Data received is not an array", establishmentsData);
          }
        }
      } catch (e) {
        console.error("Error during search: ", e);
      } finally {
        setLoading(false);
      }
    },
    [coords, totalPages, pegaDados]
  );

  const fetchEstablishments = useCallback(() => {
    if (Object.keys(busca).length === 0) {
      pegaDados(currentPage + 1);
    } else {
      handleBusca(busca, currentPage + 1);
    }
  }, [currentPage, pegaDados, handleBusca, busca]);

  const handleLoadMore = useCallback(() => {
    if (loading || currentPage >= totalPages) return; // Evitar chamadas adicionais se todas as páginas foram carregadas
    if (Object.keys(busca).length === 0) {
      pegaDados(currentPage + 1);
    } else {
      handleBusca(busca, currentPage + 1, false);
    }
  }, [loading, currentPage, totalPages, handleBusca, busca, pegaDados]);

  const handleSearch = (dados) => {
    const hasFilters = Object.keys(dados).some((key) => dados[key] !== "");
    if (!hasFilters) {
      setBusca({});
      setCurrentPage(1);
      pegaDados(1, true, true); // Reset to fetch all data
    } else {
      handleBusca(dados, 1, true); // Reset the establishments list for a new search
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Efeito para adicionar event listener de scroll apenas na seção estabelecimentoList
  useEffect(() => {
    const handleScroll = throttle(() => {
      const establishmentList = document.querySelector(".estabelecimentoList");
      if (!establishmentList) return;

      const { scrollTop, scrollHeight, clientHeight } = establishmentList;
      const scrolledToBottom = scrollTop + clientHeight >= scrollHeight;

      if (scrolledToBottom && !loading && currentPage < totalPages) {
        handleLoadMore();
      }
    }, 200);

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleLoadMore, loading, currentPage, totalPages]);

  function throttle(fn, wait) {
    let time = Date.now();
    return function (...args) {
      if (time + wait - Date.now() < 0) {
        fn(...args);
        time = Date.now();
      }
    };
  }

  useEffect(() => {
    const handleScroll = () => {
      const searchBar = document.querySelector(".corpoSearchHome");
      const scrollTop = window.scrollY;
      const searchBarOffsetTop = searchBar.offsetTop;

      if (scrollTop > searchBarOffsetTop + 100) {
        setShowButton(true);
        setIsSearchBarVisible(false);
        setIsSearchBarFixed(false)
      } else {
        setShowButton(false);
        setIsSearchBarVisible(true);
        setIsSearchBarFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleShowSearchBar = () => {
    setIsSearchBarFixed(true);
    setIsSearchBarVisible(true);
  };

  const handleHideSearchBar = () => {
    setIsSearchBarFixed(false);
    setIsSearchBarVisible(false);
  };

  return (
    <>
      <div className="search-placeholder">
        {isSearchBarFixed && (
          <button
            className="hide-searchbar-button"
            onClick={handleHideSearchBar}
          >
            Ocultar barra de pesquisa
          </button>
        )}
        <div
          className={`corpoSearchHome ${isSearchBarFixed ? "fixed" : ""} ${
            !isSearchBarVisible ? "hidden" : ""
          }`}
        >
          <SearchBar onSearch={handleSearch} />
        </div>
      </div>

      {showButton && !isSearchBarFixed && (
        <button className="show-searchbar-button" onClick={handleShowSearchBar}>
          Mostrar barra de pesquisa
        </button>
      )}

      <section className="estabelecimentoList">
        {establishments.length > 0 ? (
          <EstablishmentList establishments={establishments} />
        ) : (
          "Nenhum estabelecimento encontrado"
        )}
        {loading && (
          <p
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              padding: "10px 0",
            }}
          >
            <span className="loader" style={{ marginRight: 10 }}></span>{" "}
            carregando mais estabelecimentos...
          </p>
        )}
      </section>
    </>
  );
}

export default Home;
