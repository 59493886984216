import { CONSTANTES } from "../../global/CONSTANTES";
import Api from "../index";

const getDados = async () => {
  const { data } = await Api.get(`/home-page/dados`);
  return data;
};

const getComentariosHome = async () => {
  const { data } = await Api.get(`/home-page/comments`);
  return data;
};

const getEstabelecimentos = async (latitude,longitude,status,page) => {
  const { data } = await Api.get(`/estabelecimentos/WEB/cidadeEstado/${latitude}/${longitude}/${status}/${page}`);
  return data;
};
const getEstabelecimentoSemLogin = async (estabelecimento_id,status) => {
  const { data } = await Api.get(`/estabelecimento/WEB/${estabelecimento_id}/cID/${status}`);
  return data;
};

// const getDados = async (token) => {
//   // const headerToken = {
//   //   headers: {
//   //     Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
//   //   },
//   // };
//   const { data } = await Api.get(`/home-page/dados`
//   // , headerToken
//   );
//   return data;
// };

// const reverseGeocode = async (latitude, longitude) => {
//   try {
//     const response = await Api.get(
//       `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${CONSTANTES.GOOGLE_API_KEY}`,
//     );

//     const results = response.data.results;
//     // console.log(results)
//     if (results.length > 0) {
//       const addressComponents = results[0].address_components;
//       // console.log(addressComponents);
//       let cidade, estado, pais, estadoSigla, paisCode;

//       for (const component of addressComponents) {
//         if (component.types.includes('administrative_area_level_2')) {
//           cidade = component.long_name;
//         } else if (component.types.includes('administrative_area_level_1')) {
//           estado = component.long_name;
//           estadoSigla = component.short_name;
//         } else if (component.types.includes('country')) {
//           pais = component.long_name;
//           paisCode = component.short_name;
//         }
//       }

//       return {
//         error: false,
//         message: 'Dados encontrados!',
//         data: {
//           cidade,
//           estado,
//           estadoSigla,
//           paisCode,
//           pais,
//         },
//       };
//       // console.log(`Cidade: ${cidade}, Estado: ${estado}, País: ${pais}, Estado Sigla: ${estadoSigla}, País Code: ${paisCode}`);
//     }
//   } catch (error) {
//     return {
//       error: true,
//       message: `Erro na geocodificação reversa: ${error.message}`,
//     };
//   }
// };

export const homeService = {
  // reverseGeocode,
  getDados,
  getEstabelecimentos,
  getEstabelecimentoSemLogin,
  getComentariosHome
};
