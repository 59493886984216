// routes/estabelecimento.routes.js
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../pages/ADMIN/components/Dashboard";
import PrincipalADM from "../pages/ADMIN/components/PrincipalADM";
import ListaCupom from "../pages/ADMIN/Cupom/ListaCupom";
import ListaPlano from "../pages/ADMIN/Plano/ListaPlano";

const ADMRoutes = () => {
  return (
    <Routes>
      <Route path="/adm_facilite" element={<Dashboard />}>
        <Route index element={<PrincipalADM />} />
        <Route path="lista-cupom" element={<ListaCupom/>}/>
        <Route path="lista-plano" element={<ListaPlano/>}/>
        {/* <Route path="PerfilColaborador" element={<PerfilColaborador/>}/> */}
        {/* <Route path="HorarioDisponivel" element={<HorarioDisponivel/>}/> */}
        {/* <Route path="AgendamentoColaborador" element={<AgendamentoColaboradorPage/>}/> */}
      </Route>
      <Route path="*" element={<Navigate to="/adm_facilite/" />}/>
    </Routes>
  );
};

export default ADMRoutes;
