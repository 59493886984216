// src/components/ImageSlider.js
import React from 'react';
import Slider from 'react-slick';
import { CONSTANTES } from '../../global/CONSTANTES';

const ImageSlider = ({ images, selectedImage, estabelecimento_id }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: images.findIndex(image => image.id === selectedImage.id),
    nextArrow: <button className="slick-next">Next</button>,
    prevArrow: <button className="slick-prev">Prev</button>,
    arrows: true,  // Ativar setas de navegação
    centerMode: true,  // Centralizar a imagem
    centerPadding: '0',  // Remover padding ao redor da imagem centralizada
  };

  return (
    <Slider {...settings}>
      {images.map((image, index) => (
        <div key={index}>
          <img src={`${CONSTANTES.uri}${CONSTANTES.caminhoImageEstabelecimentos}${estabelecimento_id}${CONSTANTES.galeria}${image.foto}`} alt={`Slider image ${index}`}/>
        </div>
      ))}
    </Slider>
  );
};

export default ImageSlider;
