import { useState, useEffect, useCallback } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/pt-br";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ModalAgendamento from "./ModalAgendamento";
import { agendamentoService } from "../../../services/agendamento";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faCircleChevronRight,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../contexts/AuthContext";
import "./Agendamento.css";
import { COLORS } from "../../../global/STYLE";
import { useNavigate } from "react-router-dom";

moment.locale("pt-br");

const localizer = momentLocalizer(moment);

// Mensagens em português
const messages = {
  allDay: "Dia inteiro",
  previous: (
    <FontAwesomeIcon icon={faChevronLeft} color={COLORS.bgButtonCard} />
  ),
  next: <FontAwesomeIcon icon={faChevronRight} color={COLORS.bgButtonCard} />,
  today: "Hoje",
  month: "Mês",
  week: "Semana",
  day: "Dia",
  agenda: "Agenda",
  date: "Data",
  time: "Hora",
  event: "Evento",
  showMore: (total) => `+ Ver mais (${total})`, // Adicione esta linha
  noEventsInRange: "Não há eventos neste período", // Ajuste esta linha
};

const formats = {
  timeGutterFormat: (date, culture, localizer) =>
    localizer.format(date, "HH:mm", culture), // Formato 24 horas para a hora
  dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
    localizer.format(start, "D MMM", culture) +
    " — " +
    localizer.format(end, "D MMM", culture),
  // Formatação personalizada para os dias da semana
  weekdayFormat: (date, culture, localizer) => {
    const day = localizer.format(date, "ddd", culture);
    return day.charAt(0).toUpperCase() + day.slice(1); // Primeira letra maiúscula
  },
};

const eventStyleGetter = (event) => {
  let style = {};
  if (event.status === "cancelado") {
    style = {
      backgroundColor: "#d32f2f",
      color: COLORS.primaryText, // Cor do texto
      borderRadius: "0px",
      border: "none",
    };
  } else if (event.status === "concluido") {
    style = {
      backgroundColor: "#388e3c",
      color: COLORS.primaryText, // Cor do texto
      borderRadius: "0px",
      border: "none",
    };
  } else if (event.status === "pendente") {
    style = {
      backgroundColor: "#fbc02d",
      color: COLORS.primaryText, // Cor do texto
      borderRadius: "0px",
      border: "none",
    };
  }

  return {
    style: style,
  };
};

const AgendamentoPage = () => {
  const { token, logout } = useAuth();
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([]);

  const handleEventClick = useCallback((event) => {
    setSelectedEvent(event);
    setModalOpen(true);
  }, []);

  const pegaDados = useCallback(async () => {
    // console.log("pega dados")
    try {
      const data = await agendamentoService.agendamentoEstabelecimento(token);
      if (data.error) {
        if(data.reload){
          navigate("/login/estabelecimento")
          logout()  
        }
        toast.error(data.message);
      } else {
        // console.log(data.data)
        const dados = data.data.map((dado) => {
          const dt = {
            title: dado.title,
            inicio: `${dado.start[0][0]}/${dado.start[0][1]}/${dado.start[0][2]} as ${dado.start[1][0]}:${dado.start[1][1]}`,
            allDay: dado.allDay,
            valor: dado.valor,
            status: dado.status,
            id: dado.id,
            start: new Date(
              dado.start[0][2],
              dado.start[0][1] - 1,
              dado.start[0][0],
              dado.start[1][0],
              dado.start[1][1]
            ),
            end: new Date(
              dado.end[0][2],
              dado.end[0][1] - 1,
              dado.end[0][0],
              dado.end[1][0],
              dado.end[1][1]
            ),
            cliente: dado.cliente,
            colaborador: dado.colaborador,
          };
          return dt;
        });
        setEvents(dados);
        // return toast.success(data.message)
      }
    } catch (e) {
      // console.log(`Error: ${e}`);
    }
  }, []);

  useEffect(() => {
    pegaDados();
  }, [pegaDados]);

  return (
    <div
      className="calendario-container"
      style={{ backgroundColor: COLORS.primary }}
    >
      <Calendar
        localizer={localizer}
        events={events}
        formats={formats}
        messages={messages}
        // dayPropGetter={date => dayStyleGetter(date, events)}
        eventPropGetter={eventStyleGetter}
        startAccessor="start"
        endAccessor="end"
        // style={{ height: 'auto' }}
        onSelectEvent={handleEventClick}
        style={{ color: COLORS.secondaryText }}
      />

      {selectedEvent && (
        <ModalAgendamento
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          event={selectedEvent}
          reload={pegaDados}
        />
      )}
    </div>
  );
};

export default AgendamentoPage;
