import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCalendarCheck,
  faChartLine,
  faCogs,
  faBusinessTime,
} from "@fortawesome/free-solid-svg-icons";
import {useAuth} from '../../../contexts/AuthContext'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import "../../ADMIN_Estabelecimento/components/PrincipalE"; // Certifique-se de criar este arquivo CSS
import NewsFeed from "./NewsFeed";
import { toast } from "react-toastify";
import { newsService } from "../../../services/news";

// Exemplo de dados para o gráfico
const data = [
  { name: "Seg", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Ter", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Qua", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Qui", uv: 2780, pv: 3908, amt: 2000 },
  { name: "Sex", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Sáb", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Dom", uv: 3490, pv: 4300, amt: 2100 },
];

const PrincipalC = () => {
  const {user, token} = useAuth()

  return (
    <div className="principalE-container">
      {/* Cards */}
      {/* <div className="cards-container">
        <div className="card">
          <FontAwesomeIcon icon={faUser} size="2x" />
          <h3>Total Colaboradores</h3>
          <p>{user.estabelecimentoColaborador ? Object.keys(user.estabelecimentoColaborador).length : 0}</p>
        </div>
        <div className="card">
          <FontAwesomeIcon icon={faCalendarCheck} size="2x" />
          <h3>Agendamentos Hoje</h3>
          <p>35</p>
        </div>
        <div className="card">
          <FontAwesomeIcon icon={faBusinessTime} size="2x" />
          <h3>Plano Atual</h3>
          <h4>Bronze</h4>
          <h5>Vencimento: 02/02/2024</h5>
        </div>
        <div className="card">
          <FontAwesomeIcon icon={faCogs} size="2x" />
          <h3>Status do Serviço </h3>
            <h4 style={{color:user.status === "ativado" ? "darkgreen" : "darkred", marginBottom:"5px"}}>{user.status.toUpperCase()}</h4>
        </div>
      </div> */}

      {/* Seção de Novidades e Gráficos */}
      <div className="novidades-graficos-container">
        
        <section className="novidades">
          <NewsFeed/>
        </section>


        {/* <section className="graficos">
          <h2>Gráficos</h2>
          <ResponsiveContainer width="100%" height={300}>
    <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="pv" fill="#8884d8" />
            <Bar dataKey="uv" fill="#82ca9d" />
          </BarChart>
          </ResponsiveContainer>
        </section> */}

      </div>
    </div>
  );
};

export default PrincipalC;
