import React, { createContext, useContext, useState, useEffect } from "react";
import { estabelecimentoService } from "../services/estabelecimento";
import { colaboradorService } from "../services/colaborador";
import { afiliadoService } from "../services/afiliado";
import { homeService } from "../services/home";
import { admService } from "../services/adm";
import { clienteService } from "../services/cliente";

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState([]);
  const [token, setToken] = useState(null);
  const [userType, setUserType] = useState("");
  const [isLogado, setIsLogado] = useState(false);
  const [dadosHome, setDadosHome] = useState([]);
  const [coords, setCoords] = useState(null);
  // const [location, setLocation] = useState({})

  /**COMPARTILHAMENTO DO ESTABELECIMENTO */
  const [estabelecimentoCompartilhadoURL, setEstabelecimentoCompartilhadoURL] =
    useState({});
  const [
    estabelecimentoIDCompartilhadoURL,
    setEstabelecimentoIDCompartilhadoURL,
  ] = useState(null);

  const statusEstabelecimentoCompartilhado = (
    dataEstabelecimento,
    idEstabCompartilhado,
    status = false
  ) => {
    switch (status) {
      case true:
        setEstabelecimentoCompartilhadoURL(dataEstabelecimento);
        setEstabelecimentoIDCompartilhadoURL(idEstabCompartilhado);
        break;
      default:
        setEstabelecimentoCompartilhadoURL({});
        setEstabelecimentoIDCompartilhadoURL(null);
    }
  };

  useEffect(() => {
    getLocation();
    validarLogin();
    dataHome();
  }, []);

  const validarLogin = async () => {
    try {
      // Adicione await para aguardar a conclusão da chamada assíncrona
      const storedAuth = await localStorage.getItem("auth");
      const separa = JSON.parse(storedAuth);
      // console.log("separa dados = ", separa)
      if (separa.token && separa.token.length > 0) {
        let data;

        if (separa.userType === "estabelecimento") {
          // console.log("validou como estabelecimento")
          data = await estabelecimentoService.validateToken(
            separa.token.toString()
          );
        } else if (separa.userType === "colaborador") {
          // console.log("validou como colaborador")
          data = await colaboradorService.validateToken(
            separa.token.toString()
          );
        } else if (separa.userType === "afiliado") {
          // console.log("validou como afiliado")
          data = await afiliadoService.validateToken(separa.token.toString());
        } else if (separa.userType === "adm_facilite") {
          // console.log("validou como adm_facilite")
          data = await admService.validateToken(separa.token.toString());
        } else if (separa.userType === "cliente") {
          // console.log("validou como cliente")
          data = await clienteService.validateToken(separa.token.toString());
        }
        // console.log(data)
        if (data && !data.error) {
          setUser(data.data);
          setUserType(data.userType);
          setToken(separa.token);
          setIsLogado(true);
          return true;
        } else {
          logout(); // Desloga se a validação do token falhar
          return false;
        }
      }
      return false;
    } catch (e) {
      // console.log("Erro:", e);
      return false;
    }
    // console.log(userType)
  };

  const login = async (userData, uType, token) => {
    const newAuth = { userType: uType, token };
    // const newAuth = { user: userData, userType: uType, token };
    await localStorage.setItem("auth", JSON.stringify(newAuth));
    setUser(userData);
    setToken(token);
    setUserType(uType);
    setIsLogado(true);
  };

  const logout = async () => {
    // setAuth({ user: null, userType: '', token: null });
    setUser([]);
    setUserType("");
    setToken(null);
    setIsLogado(false);
    localStorage.removeItem("auth");
  };

  const changeData = async (chave, valor) => {
    try {
      const updatedUser = { ...user };
      updatedUser[chave] = valor;
      setUser(updatedUser);

      const storedAuth = await localStorage.getItem("auth");
      if (storedAuth) {
        const authData = JSON.parse(storedAuth);
        authData.user = updatedUser;
        await localStorage.setItem("auth", JSON.stringify(authData));
      }
    } catch (e) {
      console.error("Error updating user data:", e);
    }
  };

  const dataHome = async () => {
    try {
      const data = await homeService.getDados();
      if (!data.error) {
        // console.log(data.data)
        setDadosHome(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          // console.log("COORDS = ", position.coords)
          setCoords({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          // console.log("Latitude:", position.coords.latitude);
          // console.log("Longitude:", position.coords.longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userType,
        token,
        login,
        logout,
        changeData,
        isLogado,
        dadosHome,
        coords,
        setIsLogado,
        validarLogin,
        estabelecimentoCompartilhadoURL,
        estabelecimentoIDCompartilhadoURL,
        statusEstabelecimentoCompartilhado,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
