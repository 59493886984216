import Api from "../index";


const create = async(dados) => {
  const {data} = await Api.post('/adm_facilite', dados)
  return data
}




const login = async (email, password) => {
  const dados = {
    email,
    password,
  };
  const { data } = await Api.post("/login/adm_facilite", dados);
  return data;
};

const validateToken = async ( token ) => {
  // console.log("VALIDA TOKEN SERVICE = ",token)
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer ' seguido do token
    },
  };
  const {data} = await Api.post(
    "/validateToken/adm_facilite",
    null,
    headerToken
  );
  return data;
};

const changePhoto = async (file, token, colaborador_id) => {
  const MAX_RETRIES = 3;
  let attempts = 0;
  let success = false;
  // Crie um FormData e anexe a imagem e outros dados
  const formData = new FormData();

  formData.append("colaborador_id", colaborador_id.toString());
  formData.append("file", file);
  // console.log('FormData:', { cliente_id: cliente_id.toString(), file: { uri: uri.uri, type: uri.type, name: uri.fileName } });
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  while (attempts < MAX_RETRIES && !success) {
    try {
      const { data } = await Api.post(
        "/colaborador/mudarFoto",
        formData,
        config
      );
      return data;
      return false;
    } catch (error) {
      attempts += 1;
      // console.log('Erro no upload da imagem:', error);
      // opcional: aguarde um pouco antes de tentar novamente
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }
};

const updateColaborador = async (token, perfil) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.put(
    "/colaborador/update",
    perfil,
    headerToken
  );
  return data;
};



export const admService = {
  login,
  validateToken,
  create,
  changePhoto,
  // updateColaborador,
  
};
