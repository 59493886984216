import React from "react";
import {
  faHeart,
  faMapMarkerAlt,
  faStar,
  faStarHalf,
  faStarHalfAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLORS } from "../../global/STYLE";
// import './styles.css';

const Stars = ({ stars, showNumber, colorStar, size="1x" }) => {
  // console.log("estrelas = ",stars)
  let s = [0, 0, 0, 0, 0];
  let floor = Math.floor(stars);
  let left = stars - floor;

  for (var i = 0; i < floor; i++) {
    s[i] = 2;
  }
  if (left > 0) {
    s[i] = 1;
  }

  return (
    <div style={{ display: "flex" }}>
      {s.map((i, k) => (
        <div key={k}>
          {i === 0 && (
            <FontAwesomeIcon
              icon={faStar}
              size={size}
              color={COLORS.placeHolder}
              style={{ marginRight: "5px" }}
            />
          )}
          {i === 1 && (
            <FontAwesomeIcon
              icon={faStarHalf}
              size={size}
              color={colorStar ? colorStar : COLORS.star}
              style={{ marginRight: "5px" }}
            />
          )}
          {i === 2 && (
            <FontAwesomeIcon
              icon={faStar}
              size={size}
              color={colorStar ? colorStar : COLORS.star}
              style={{ marginRight: "5px" }}
            />
          )}
        </div>
      ))}
      {showNumber && stars}
    </div>
  );
};

export default Stars;
