import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./ListaColaborador.css";
import { toast } from "react-toastify";
import { colaboradorService } from "../../../services/colaborador";
import { useAuth } from "../../../contexts/AuthContext";
import { CONSTANTES } from "../../../global/CONSTANTES";
import { COLORS } from "../../../global/STYLE";
import { useNavigate } from "react-router-dom";

function ListaColaborador() {
  const { token, logout } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [colaborador, setColaborador] = useState({});
  const [lastSearchedEmail, setLastSearchedEmail] = useState("");
  const [carregando, setCarregando] = useState(false);
  const [img, setImg] = useState({});


  const pegaDados = useCallback(async () => {
    try {
      const data = await colaboradorService.pegaColaboradoresEstabelecimento(
        token
      );
      if (data.error) {
        if (data.reload) {
          navigate("/login/estabelecimento");
          logout();
        }
        toast.error(data.message);
      } else {
        // toast.success(data.message);
        // console.log(data.data)
        setColaboradores(data.data);
      }
    } catch (e) {
      toast.error(e);
    }
  }, []);

  const handleStatusChange = (idc, e) => {
    // console.log(colaboradores, idc);
    handleServicoChange(idc, "status", e.target.checked);
  };

  const handleServicoChange = async (id, campo, novoValor) => {
    const novosStatus = colaboradores.map((colaborador) => {
      if (colaborador.id === id) {
        return {
          ...colaborador,
          [campo]: novoValor === true ? "ativado" : "desativado",
        };
      }
      return colaborador;
    });

    try {
      const data = await colaboradorService.statusColaboradoresEstabelecimento(
        token,
        novoValor === true ? "ativado" : "desativado",
        id
      );
      if (data.error) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        setColaboradores(novosStatus);
      }
    } catch (e) {
      toast.error(e);
    }

    // console.log('Serviços Atualizados:', novosServicos);
  };

  const search = async () => {
    if (email !== lastSearchedEmail) {
      try {
        const data = await colaboradorService.searchColaboradorEstabelecimento(
          email
        );
        if (data.error) {
          toast.error(data.message);
        } else {
          setColaborador(data.data);
          setLastSearchedEmail(email);
          toast.success(data.message);
        }
      } catch (e) {
        toast.error(e.message);
      }
    } else {
      toast.info("Por favor, altere o email para realizar uma nova busca.");
    }
  };

  const handleVincular = useCallback(async () => {
    const emailValido = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    if (!emailValido) {
      toast.error("Por favor, insira um email válido.");
      return;
    }

    if (Object.keys(colaborador).length > 0) {
      try {
        const data =
          await colaboradorService.vincularColaboradorEstabelecimento(
            token,
            colaborador.id
          );
        if (data.error) {
          if (data.reload) {
            navigate("/login/estabelecimento");
            logout();
          }
          toast.error(data.message);
        } else {
          // console.log(data)
          toast.success(data.message);
          setColaborador({});
          setEmail("");
          setLastSearchedEmail("");
          pegaDados();
        }
      } catch (e) {
        // toast.error(e);
      }
    } else {
      toast.error("Por favor, pesquise o colaborador antes de vincular!");
    }
  }, [colaborador]);

  const handleDesvincular = useCallback(async (col_id) => {
    const confirmacao = window.confirm(
      "Você deseja realmente devincular o colaborador?"
    );

    if (confirmacao) {
      try {
        const data =
          await colaboradorService.desvincularColaboradorEstabelecimento(
            token,
            col_id
          );
        if (data.error) {
          if (data.reload) {
            navigate("/login/estabelecimento");
            logout();
          }
          toast.error(data.message);
        } else {
          pegaDados();
          toast.success(data.message);
        }
      } catch (e) {
        // toast.error(e);
      }
    }
  }, []);

  const handleImageUpload =
    async (event, colaboradorID) => {
      // console.log(event.target.files, colaboradorID)
      // return false
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];

        const maxSize = 2 * 1024 * 1024; // 2MB em bytes

        if (file && file.size > maxSize) {
          toast.error(
            "O tamanho do arquivo excede o limite de 2MB. Por favor, selecione um arquivo menor."
          );
          // alert('O tamanho do arquivo excede o limite de 2MB. Por favor, selecione um arquivo menor.');
          event.target.value = ""; // Limpa o valor do input file
          setCarregando(false);
          return false;
        }

        // Atualize o estado com o URL da imagem para o colaborador específico
    const imageURL = URL.createObjectURL(file);
    setImg((prevImg) => ({
      ...prevImg,
      [colaboradorID]: imageURL,  // Armazena a imagem para o colaborador específico
    }));


        let colaborador_id = colaboradorID;
        try {
          const data = await colaboradorService.changePhotoByEstab(
            file,
            token,
            colaborador_id
          );
          if (data.error) {
            toast.error(data.message);
          } else {
            // changeData("foto", data.data.foto);
            setCarregando(true)
            toast.success(data.message);
            // console.log(data.data.foto);
          }
        } catch (e) {
          toast.error("Erro ao enviar imagem", e.message);
          // console.error("Erro ao enviar imagem", e);
        }finally{
          event.target.value = "";
          setCarregando(false);
        }
      }
      event.target.value = "";
      setCarregando(false);
    }

  useEffect(() => {
    pegaDados();
  }, [pegaDados]);

  return (
    <div className="container">
      <div className="topo">
        <h3 style={{ color: COLORS.primaryText }}>Lista de Colaboradores</h3>
        <span
          className="descricao"
          style={{ backgroundColor: COLORS.bgButtonCard, marginTop: 10 }}
        >
          <p style={{ color: COLORS.primaryText }}>
            Abaixo segue a lista dos colaboradores vinculados ao seu
            estabelecimento
          </p>
          <p style={{ color: COLORS.primaryText }}>
            Para vincular um colaborador, primeiro busque pelo email do
            colaborador e clique na "lupa" para encontrar.
          </p>
          <p style={{ color: COLORS.primaryText }}>
            Após encontrar o colaborador, é só clicar em "Vincular Colaborador"
            e o mesmo aparecerá em baixo na lista.
          </p>
        </span>
      </div>
      <div
        className="form-lstCol-estab"
        style={{ backgroundColor: COLORS.cardBackground }}
      >
        <label>
          <span style={{ color: COLORS.placeHolder }}>Colaborador</span>
          <input
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="digite o email do colaborador"
            required
            style={{
              border: `1px solid ${COLORS.secondary}`,
              backgroundColor: ` ${COLORS.inputBackground}`,
              color: COLORS.primaryText,
              borderRadius: 5,
            }}
          />
        </label>

        <label>
          <button
            onClick={search}
            className="searchIcon"
            style={{
              backgroundColor: COLORS.button,
              borderColor: COLORS.secondary,
            }}
          >
            <FontAwesomeIcon icon={faSearch} size="1x" color="white" />
          </button>
        </label>

        <label>
          <input
            type="text"
            value={colaborador.nome || ""}
            disabled
            style={{
              border: `1px solid ${COLORS.secondary}`,
              backgroundColor: ` ${COLORS.inputBackground}`,
              color: COLORS.primaryText,
              borderRadius: 5,
            }}
          />
        </label>

        <label>
          <button
            className="btnVincular"
            onClick={handleVincular}
            style={{
              backgroundColor: COLORS.button,
              borderColor: COLORS.secondary,
            }}
          >
            Vincular Colaborador
          </button>
        </label>
      </div>

      <div className="body">
        {colaboradores.map((item, index) => (
          <div
            className="lista"
            key={index}
            style={{ backgroundColor: COLORS.cardBackground }}
          >
            <button
              className="btnTrash"
              onClick={() => handleDesvincular(item.id)}
              style={{ backgroundColor: COLORS.cardBackground }}
            >
              <FontAwesomeIcon icon={faTrash} color={COLORS.primary} />
            </button>
              <label htmlFor={`image-upload-colab-${item.id}`}>
            <div className="imagem">
                {/* {item.foto ? (
                  <img
                    src={img[item.id] || `${CONSTANTES.uri}${CONSTANTES.caminhoImageColaboradores}${item.id}/${item.foto}`}
                    alt="Avatar"
                    className="avatar"
                  />
                ) : (
                  <img
                    src={img.length > 0 ? img :`https://via.placeholder.com/100`}
                    // src="https://via.placeholder.com/100"
                    alt="Avatar"
                    className="avatar"
                  />
                )} */}

<img
          // Verifica se há uma imagem no estado `img`, caso contrário usa `item.foto` ou um placeholder
          src={
            img[item.id] ||  // Mostra a imagem carregada se existir
            (item.foto 
              ? `${CONSTANTES.uri}${CONSTANTES.caminhoImageColaboradores}${item.id}/${item.foto}`  // Mostra a imagem do colaborador se houver
              : 'https://via.placeholder.com/100'  // Mostra o placeholder se não houver imagem
            )
          }
          style={{cursor:'pointer'}}
          title="Clique para alterar foto do colaborador criado por você"
          alt="Avatar"
          className="avatar"
        />

                <input
                  type="file"
                  id={`image-upload-colab-${item.id}`}
                  accept="image/*"
                  onChange={(e)=>{
                    // console.log(item.id)
                    handleImageUpload(e, item.id)
                    e.target.value = '';
                  }}
                  placeholder="imagem do colaborador"
                  alt="imagem do colaborador"
                  title="imagem do colaborador"
                  hidden
                />
            </div>
              </label>
            <div className="nome" style={{ color: COLORS.primaryText }}>
              {item.nome}
            </div>
            <div className="email" style={{ color: COLORS.primaryText }}>
              {item.email}
            </div>
            <div className="telefone" style={{ color: COLORS.primaryText }}>
              {item.telefone}
            </div>
            <div className="switch-container-lstCol">
              <label>
                <div className="switch-lstCol-estab">
                  <input
                    type="checkbox"
                    id={`status-switch-estab-${index}`}
                    checked={
                      item.status && item.status === "ativado" ? true : false
                    }
                    onChange={(e) => handleStatusChange(item.id, e)}
                    //   disabled={!editando[servico.id]}
                  />
                  <label
                    htmlFor={`status-switch-estab-${index}`}
                    className="slider-lstCol-estab round"
                  ></label>
                </div>
              </label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ListaColaborador;
