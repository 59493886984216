import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import "./styles.css";
import { toast } from "react-toastify";
import { colaboradorService } from "../../../../services/colaborador";
import { servicoService } from "../../../../services/servico";
import moment from "moment";
import CadHorarioModal from "./cadHorarioModal";
import { COLORS } from "../../../../global/STYLE";
import { useNavigate } from "react-router-dom";

function HorarioDisponivelColaboradorPeloEstabelecimento() {
  const { token, logout } = useAuth();
  const navigate = useNavigate()

  const [colaboradores, setColaboradores] = useState([]);
  const [colaborador, setColaborador] = useState(null);
  const [servicos, setServicos] = useState([]);
  const [atualiza, setAtualiza] = useState(false);

  const [horariosDetalhes, setHorariosDetalhes] = useState({});

  const reload = () => {
    // console.log("chama Reload")
    setAtualiza(true);
    pegaServicoPorColaborador();
    setAtualiza(false);
  };

  const pegaDadosColaboradorEscolhido = async () => {
    try {
      const data = await colaboradorService.pegaColaboradoresEstabelecimento(
        token
      );
      if (data.error) {
        if(data.reload){
          navigate("/login/estabelecimento")
          logout()  
        }
        toast.error(data.message);
      } else {
        if (data.data.length > 0) {
          // toast.success(data.message);
          setColaboradores(data.data);
          // console.log(data.data)
        } else {
          toast.info("Não há vínculo com um colaborador!");
        }
      }
    } catch (e) {
      // toast.error(e);
    }
  };

  const pegaServicoPorColaborador = useCallback(async () => {
    // console.log("pega serviço função");
    if (colaborador) {
      // console.log("colaborador_id = ", colaborador);
      try {
        const data =
          await servicoService.pegaServicoPorColaboradorEEstabelecimento_PeloEstabelecimento(
            token,
            colaborador
          );
        if (data.error) {
          if(data.reload){
            navigate("/login/estabelecimento")
            logout()  
          }
          toast.error(data.message);
        } else {
          // toast.info(data.message);
          // console.log(data.data)
          setServicos(data.data);
        }
      } catch (e) {
        // toast.error(e);
      }
    }
  }, [colaborador, atualiza]);

  const dicionarioDias = (dia) => {
    switch (dia) {
      case "SEG":
        return "Segunda-Feira";
      case "TER":
        return "Terça-Feira";
      case "QUA":
        return "Quarta-Feira";
      case "QUI":
        return "Quinta-Feira";
      case "SEX":
        return "Sexta-Feira";
      case "SAB":
        return "Sábado";
      case "DOM":
        return "Domingo";
    }
  };

  useEffect(() => {
    pegaDadosColaboradorEscolhido();
  }, []);

  useEffect(() => {
    if (colaborador) {
      pegaServicoPorColaborador();
    }
  }, [colaborador]);

  return (
    <div>
      <h3 style={{ color: COLORS.primaryText }}>
        Horário Disponível dos colaboradores
      </h3>
      <span
        className="descricao"
        style={{ backgroundColor: COLORS.bgButtonCard, marginTop: 10 }}
      >
        <p style={{ color: COLORS.primaryText }}>
          Cadastre aqui os horários disponíveis para os colaboradores vinculados
          a você.
        </p>
        <p style={{ color: COLORS.primaryText }}>
          Alguns campos são estritamente obrigatórios, pois são campos
          importantes. Cuidado ao preenchê-los.
        </p>
        <p style={{ color: COLORS.primaryText }}>
          O campo <strong>Repetir Data</strong> vai repetir os mesmos horários
          para todos os dias que escolheu.
        </p>
        <p style={{ color: COLORS.primaryText }}>
          O campo <strong>Feriado</strong> vai alterar os horários padrões
          determinados em <strong>Repetir Data</strong> para o dia que
          especificar no campo <strong>Data</strong>.
        </p>
      </span>

      <div style={{ marginBottom: "10px" }}>
        <form
          className="formulario-perfil"
          style={{ backgroundColor: COLORS.cardBackground }}
        >
          <h3 style={{ color: COLORS.primary }}>Selecione o colaborador</h3>
          <small style={{ color: COLORS.placeHolder }}>
            Essa área você pode escolher qual colaborador quer gerenciar os
            horários.
          </small>
          <label style={{ margin: "10px 0 0 0" }}>
            <select
              value={colaborador || ""}
              onChange={(e) => {
                setColaborador(e.target.value);
              }}
              className="formulario-servico-select"
              style={{
                border: `1px solid ${COLORS.secondary}`,
                backgroundColor: ` ${COLORS.inputBackground}`,
                color: COLORS.primaryText,
              }}
            >
              <option value="">Selecione um colaborador!</option>
              {colaboradores && colaboradores.length > 0 ? (
                colaboradores.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.nome}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  Não há colaboradores vinculados!
                </option>
              )}
            </select>
          </label>
        </form>
      </div>

      <hr />

      <div style={{ margin: "10px 0" }}>
        <h3 style={{ marginBottom: "20px", color: COLORS.primaryText }}>
          Ficha de Serviços
        </h3>

        <div className="grid-container">
          {servicos.map(({ servico, horarios_disponiveis }, index) => (
            <form
              // onSubmit={salvarEdicao}
              className="formulario-servico com-position-cad-horas"
              key={index}
              style={{ backgroundColor: COLORS.cardBackground }}
            >
              <div className="com-position-absolute-cad-horas">
                <CadHorarioModal
                  servicoId={servico.id}
                  colaboradorId={colaborador}
                  reload={() => reload()}
                />
              </div>
              <label>
                <h4 style={{ textAlign: "center", color: COLORS.primaryText }}>
                  {servico.titulo}
                </h4>
              </label>

              <h5 style={{ color: COLORS.placeHolder, textAlign: "center" }}>
                - Horários disponíveis -
              </h5>
              <hr style={{ margin: "10px 0" }} />

              <div className="grid-container-horario-disponivel">
                {horarios_disponiveis.map((item, index) => (
                  <div key={index}>
                    <div
                      className="horario-disponivel-informacao-lista"
                      onClick={() =>
                        setHorariosDetalhes((prevDetalhes) => ({
                          ...prevDetalhes,
                          [`${servico.id}-${item.id}`]:
                            prevDetalhes[`${servico.id}-${item.id}`] !==
                            undefined
                              ? !prevDetalhes[`${servico.id}-${item.id}`]
                              : true,
                        }))
                      }
                      style={{ backgroundColor: COLORS.inputBackground }}
                    >
                      <h3 className="horario-disponivel-dia-da-semana">
                        {item.dia_da_semana}
                      </h3>
                      {item.repetir_data && (
                        <small>{`Repetir para ${
                          item.dia_da_semana == "DOM" ||
                          item.dia_da_semana == "SAB"
                            ? "todos os"
                            : "todas as"
                        } ${dicionarioDias(item.dia_da_semana)}`}</small>
                      )}
                      {item.feriado && (
                        <div className="horario-disponivel-informacao-coluna">
                          <p>{`Feriado`}</p>
                          <p>
                            <small>
                              {moment(item.data).format("DD/MM/YYYY")}
                            </small>
                          </p>
                        </div>
                      )}
                      {!item.feriado && !item.repetir_data && (
                        <div className="informacao-coluna">
                          <p>{`Avulso`}</p>
                          <p>
                            <small>
                              {moment(item.data).format("DD/MM/YYYY")}
                            </small>
                          </p>
                        </div>
                      )}
                    </div>

                    {/* Detalhes do horário selecionado para cada lista */}
                    {horariosDetalhes[`${servico.id}-${item.id}`] && (
                      <div className="detalhes-horario">
                        <p>
                          <small style={{ color: COLORS.placeHolder }}>
                            <strong style={{ color: COLORS.primary }}>
                              Hora Início:
                            </strong>{" "}
                            {moment(item.hora_inicio, "HH:mm:ss").format(
                              "HH:mm"
                            )}{" "}
                          </small>
                        </p>
                        <p>
                          <small style={{ color: COLORS.placeHolder }}>
                            <strong style={{ color: COLORS.primary }}>
                              Hora Fim:
                            </strong>{" "}
                            {moment(item.hora_fim, "HH:mm:ss").format("HH:mm")}{" "}
                          </small>
                        </p>
                        <p>
                          <small style={{ color: COLORS.placeHolder }}>
                            <strong style={{ color: COLORS.primary }}>
                              Início Almoço:
                            </strong>{" "}
                            {item.inicio_almoco
                              ? moment(item.inicio_almoco, "HH:mm:ss").format(
                                  "HH:mm"
                                )
                              : "-"}{" "}
                          </small>
                        </p>
                        <p>
                          <small style={{ color: COLORS.placeHolder }}>
                            <strong style={{ color: COLORS.primary }}>
                              Fim Almoço:
                            </strong>{" "}
                            {item.fim_almoco
                              ? moment(item.fim_almoco, "HH:mm:ss").format(
                                  "HH:mm"
                                )
                              : "-"}{" "}
                          </small>
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </form>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HorarioDisponivelColaboradorPeloEstabelecimento;
