// src/components/ImageModal.js
import React from 'react';
import Modal from 'react-modal';
import ImageSlider from './ImageSlider';
import { CONSTANTES } from '../../global/CONSTANTES';

const ImageModal = ({ images, selectedImage, onClose, estabelecimento_id }) => {
  const customStyles = {
    content: {
    //   top: '50%',
    //   left: '50%',
    //   right: 'auto',
    //   bottom: 'auto',
    //   marginRight: '-50%',
    //   transform: 'translate(-50%, -50%)',
    //   width: '80%',
    //   maxWidth: '800px',
    //   zIndex: 1001, 
    },
  };

  return (
    <div className="modal-background-galeria_img">
      <div className="modal-galeria_img">
        <div className="modal-fundo-galeria_img">
          <button className="fechar" onClick={onClose}>
            Fechar
          </button>
        </div>
        <div className="modal-conteudo-galeria_img">

    {/* <Modal isOpen={true} onRequestClose={onClose} style={customStyles} ariaHideApp={false}>
      <button onClick={onClose}>Close</button>
    </Modal> */}
      <ImageSlider images={images} selectedImage={selectedImage} estabelecimento_id={estabelecimento_id} />
    </div>
    </div>
    </div>
  );
};

export default ImageModal;
