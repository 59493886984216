import React, { useState, useEffect, useRef, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../contexts/AuthContext";
import { COLORS } from "../../global/STYLE";
import { CONSTANTES } from "../../global/CONSTANTES";
import noImage from "../../assets/images/noimage.png";
import Stars from "../star/Stars";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { colaboradorService } from "../../services/colaborador";
import { toast } from "react-toastify";
import { horarioService } from "../../services/horario";
import { agendamentoService } from "../../services/agendamento";
import { useNavigate } from "react-router-dom";

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const days = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"];

function AgendamentoModal({ onClose, dados }) {
  const scrollViewRef = useRef(null);
  const { setIsLogado, token } = useAuth();
  const navigate = useNavigate()
  // let token =    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzE4NzU5OTMyLCJleHAiOjE3MjEzNTE5MzJ9.z65n13X-_ifzEUC3Qrquw1TRIzPtT-x6zAMgANmslAQ";

  //dados recebidos
  const { visibility, estabelecimento_id, servico_id, titulo_servico } = dados;

  const [selectedYear, setSelectedYear] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedDay, setSelectedDay] = useState(0);
  const [selectedHour, setSelectedHour] = useState(null);
  const [listDays, setListDays] = useState([]);
  const [listHours, setListHours] = useState([]);

  const [colaboradores, setColaboradores] = useState([]);
  const [horarioDisponivel, setHorarioDisponivel] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingHora, setLoadingHora] = useState(false);
  const [loadingFinalizar, setLoadingFinalizar] = useState(false);

  const [selectedColaborador, setSelectedColaborador] = useState(null);

  //   const finalizarFake = async () => {
  //     setLoadingFinalizar(true);
  //     await new Promise((resolve) => setTimeout(resolve, 3000));
  //     setLoadingFinalizar(false);
  //   };

  const handleLeftDateClick = () => {
    let mountDate = new Date(selectedYear, selectedMonth, 1);
    if (mountDate > new Date()) {
      mountDate.setMonth(mountDate.getMonth() - 1);
      setSelectedYear(mountDate.getFullYear());
      setSelectedMonth(mountDate.getMonth());
      setSelectedDay(0);
    }
  };

  const handleRightDateClick = () => {
    let mountDate = new Date(selectedYear, selectedMonth, 1);
    mountDate.setMonth(mountDate.getMonth() + 1);
    setSelectedYear(mountDate.getFullYear());
    setSelectedMonth(mountDate.getMonth());
    setSelectedDay(0);
  };

  const handleFinalizar = async () => {
    // console.log("finalizar agendamento")
    if (
      estabelecimento_id &&
      selectedYear > 0 &&
      selectedMonth >= 0 &&
      selectedDay > 0 &&
      selectedHour != null &&
      selectedColaborador != null
    ) {
      setLoadingFinalizar(true);
      const dadoAgendamento = {
        servico_id,
        colaborador_id: selectedColaborador,
        estabelecimento_id,
        dia: selectedDay,
        mes: selectedMonth,
        ano: selectedYear,
        hora: selectedHour,
        // cliente_id,
        titulo_servico: titulo_servico,
      };
      const agendamento = await agendamentoService.criarAgendamentoCliente(
        token,
        dadoAgendamento,
      );
      if (agendamento.error != true) {
        toast.success(agendamento.message)
        onClose();
        navigate('/meus-agendamentos');
      } else {
        if (agendamento.reload) {
          setLoadingFinalizar(false);
          setIsLogado(false); //   setIsAuthenticated(false);
          return toast.error(agendamento.message)
        }
        if (agendamento.status === 'msg') {
          toast.info(agendamento.message)
        } else if (agendamento.status === 'clg') {
          // console.log('disp: ', agendamento.message);
        }
        pegaDisponibilidadeColaborador();
        setSelectedDay(selectedDay);
        setSelectedMonth(selectedMonth);
        setSelectedYear(selectedYear);
        // console.log(agendamento.message);
      }
      setLoadingFinalizar(false);
    } else {
        toast.error('Por favor preencha todos os campos.')
    }
  };

  const pegaDisponibilidadeColaborador = async () => {
    // console.log("chama disponibilidade do colaborador");
    if (selectedColaborador) {
      // console.log("ID do colaborador selecionado: ", selectedColaborador);
      try {
        const dado = await horarioService.horarioDisponivelServicoColaborador(
          token,
          selectedColaborador,
          servico_id
        );
        if (dado.error) {
          if (dado.reload) {
            setLoadingData(false);
            setIsLogado(false); //   setIsAuthenticated(false);
            return toast.error(dado.message);
          }
          if (dado.status === "msg") {
            toast.info(dado.message);
          } else if (dado.status === "clg") {
            // console.log("disp: ", dado.message);
          }
        } else {
          if (dado.data.length > 0) {
            setHorarioDisponivel(dado.data);
            // console.log('horarios disponíveis: ', dado.data);
          } else {
            setHorarioDisponivel([]);
            toast.info("Esse colaborador não tem horários disponíveis.");
          }
        }
      } catch (e) {
        console.log(`Error: ${e}`);
      } finally {
        setLoadingData(false);
      }
    }
  };

  const pegaColaboradorServico = async () => {
    // console.log("chama ");
    setLoading(true);
    try {
      // console.log("SERVIÇO ID => ", servico_id);
      const dado = await colaboradorService.listColaboradorPorServico(
        token,
        servico_id,
        "ativado"
      );
      // console.log("fez a requisição");
      if (dado.error) {
        // console.log("entrou no erro");
        if (dado.reload) {
          // console.log("recarregamento solicitado");
          setLoading(false);
          setIsLogado(false); //   setIsAuthenticated(false);
          return toast.error(dado.message);
        }
        if (dado.status === "msg") {
          toast.info(dado.message);
        } else if (dado.status === "clg") {
          // console.log("disp: ", dado.message);
        }
      } else {
        // console.log("Retorna os dados pois não teve erro:");
        // console.log(dado.data);
        setColaboradores(dado.data);
      }
    } catch (e) {
      console.log(e);
    }
    // console.log("FINALIZOU");
    setLoading(false);
  };

  const configuraDias = useCallback(() => {
    //CONDIÇÃO QUE MOSTRA OS DIAS
    if (horarioDisponivel && horarioDisponivel.length > 0) {
      // console.log(
      //   'se tiver horário disponível ele entra na condição para pegar os dias',
      // );
      let daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
      let newListDays = [];

      //ADICIONADA
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();
      // FIM ADIÇÃO

      for (let i = 1; i <= daysInMonth; i++) {
        let d = new Date(selectedYear, selectedMonth, i);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let day = d.getDate();
        month = month < 10 ? "0" + month : month;
        day = day < 10 ? "0" + day : day;
        let selDate = `${year}-${month}-${day}`;

        let availability = horarioDisponivel.filter((e) => e.date === selDate);

        // newListDays.push({
        //   status: availability.length > 0 ? true : false,
        //   weekday: days[d.getDay()],
        //   number: i,
        // });

        if (
          availability.length >= 0 &&
          (d > currentDate ||
            (d.getDate() === currentDay &&
              d.getMonth() === currentMonth &&
              d.getFullYear() === currentYear))
        ) {
          newListDays.push({
            status: availability.length > 0 ? true : false,
            weekday: days[d.getDay()],
            number: i,
          });
        }
      }

      setListDays(newListDays);
      setSelectedDay(0);
      setListHours([]);
      setSelectedHour(null);
      // setSelectedColaborador(null);
    }
  }, [selectedMonth, selectedYear, horarioDisponivel, selectedColaborador]);

  const configuraHorario = useCallback(() => {
    //CONDIÇÃO QUE MOSTRA AS HORAS
    if (horarioDisponivel && horarioDisponivel.length > 0) {
      setLoadingHora(true);
      // console.log(
      //   'se tiver horario disponivel, entra na condição que mostra as horas',
      // );
      if (selectedDay > 0) {
        let d = new Date(selectedYear, selectedMonth, selectedDay);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let day = d.getDate();
        month = month < 10 ? "0" + month : month;
        day = day < 10 ? "0" + day : day;
        let selDate = `${year}-${month}-${day}`;

        let availability = horarioDisponivel.filter((e) => e.date === selDate);

        //ESSA CONDIÇÃO APARECE TODAS AS HORAS DISPONÍVEIS
        // if (availability.length > 0) {
        //   setListHours(availability[0].hours);
        // }

        //ESSA CONDIÇÃO MOSTRA SOMENTE NO DIA ATUAL AS HORAS AINDA DISPONÍVEIS E SOME COM AS ANTERIORES
        if (availability.length > 0) {
          const currentHour = new Date().getHours();
          const currentMinute = new Date().getMinutes();

          if (selectedDay === new Date().getDate()) {
            // Para o dia atual, filtre apenas as horas futuras
            const filteredHours = availability[0].hours.filter((time) => {
              const [hour, minute] = time.split(":");
              return (
                currentHour < parseInt(hour) ||
                (currentHour === parseInt(hour) &&
                  currentMinute < parseInt(minute))
              );
            });
            setListHours(filteredHours);
          } else {
            // Para o dia anterior, exiba todas as horas disponíveis
            setListHours(availability[0].hours);
          }
        }
      }
      setSelectedHour(null);
      // setSelectedColaborador(null);
      setLoadingHora(false);
    }
  }, [selectedDay]);

  //CARREGA O MÊS atual
  useEffect(() => {
    // console.log('Carrega o mês');
    let today = new Date();
    setSelectedYear(today.getFullYear());
    setSelectedMonth(today.getMonth());
    // setSelectedDay(today.getDate());
    setSelectedDay(0);
  }, []);

  //SEMPRE QUE ABRIR MODAL, ZERA O COLABORADOR SELECIONADO
  useEffect(() => {
    if (servico_id) {
      setHorarioDisponivel([]);
      setSelectedColaborador(null);
      pegaColaboradorServico();
    }
    // setColaboradores(estabelecimento);
  }, [servico_id]);

  //COLABORADOR SEMPRE QUE MUDAR
  useEffect(() => {
    setHorarioDisponivel([]);
    setSelectedHour(null);
    setSelectedDay(0);
    if (selectedColaborador) {
      setLoadingData(true);
      pegaDisponibilidadeColaborador();
    }
    // setColaboradores(estabelecimento);
  }, [selectedColaborador]);

  // DIAS
  useEffect(() => {
    configuraDias();
  }, [configuraDias]);

  // HORAS
  useEffect(() => {
    configuraHorario();
  }, [configuraHorario]);

  return (
    <div className="modal-background2">
      <div className="modal2">
        <div className="modal-fundo2-agendamento">
          <button className="fechar" onClick={onClose}>
            Fechar
          </button>
        </div>
        <div className="modal-conteudo2" ref={scrollViewRef}>
            <div>
          {!loading ? (
            <div style={styles.ColaboradorArea}>
              <h3 style={styles.ColaboradorTitle}>Escolha um profissional</h3>
              {colaboradores && colaboradores.length <= 0 && (
                <h3 style={styles.ColaboradorTitle}>
                  Não foi encontrado nenhum profissional para esse serviço!
                </h3>
              )}
              <div style={styles.ColaboradorScroll}>
                {colaboradores &&
                  colaboradores.map((item) => (
                    <a
                      href="#"
                      key={item.id}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedColaborador(item.id);
                      }}
                      style={{
                        ...styles.ColaboradorItem,
                        backgroundColor:
                          selectedColaborador === item.id
                            ? COLORS.button
                            : COLORS.cardBackground,
                      }}
                    >
                      <img
                        src={
                          item.foto
                            ? CONSTANTES.uri +
                              CONSTANTES.caminhoImageColaboradores +
                              item.id +
                              "/" +
                              item.foto
                            : noImage
                        }
                        style={styles.ColaboradorImage}
                      />
                      <h3
                        style={{
                          ...styles.ColaboradorNome,
                          fontWeight:
                            selectedColaborador === item.id ? "bold" : "normal",
                        }}
                      >
                        {item.nome}
                      </h3>
                      {/* ["2xs","xs","sm","lg","xl","2xl","1x","2x","3x","4x","5x","6x","7x","8x","9x","10x"] */}
                      <Stars
                        stars={item.star}
                        showNumber={true}
                        size="xs"
                        colorStar={
                          selectedColaborador === item.id ? "white" : ""
                        }
                      />
                    </a>
                  ))}
              </div>{" "}
              {/*CONTEÚDO COLABORADOR*/}
              {/*AGENDA COLABORADOR*/}
            </div>
          ) : (
            <span className="loader"></span>
          )}{" "}
          </div>
          {/**LOAD COLABORADOR */}
         
          {/* CALENDARIO */}
          <div>          
          {loadingData && <span className="loader"></span>}
          {selectedColaborador &&
            selectedColaborador != null &&
            horarioDisponivel &&
            horarioDisponivel.length > 0 && (
              <div style={styles.Calendario}>
                <div style={styles.DateInfo}>
                  <div
                    style={styles.DatePrevArea}
                    onClick={handleLeftDateClick}
                  >
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      size={"lg"}
                      color={COLORS.primary}
                    />
                  </div>
                  <div style={styles.DateTitleArea}>
                    <h3 style={styles.DateTitle}>
                      {months[selectedMonth]} {selectedYear}
                    </h3>
                  </div>
                  <div
                    style={styles.DateNextArea}
                    onClick={handleRightDateClick}
                  >
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      size={"lg"}
                      color={COLORS.primary}
                    />
                  </div>
                </div>

                <div style={styles.DateList}>
                  {listDays.map((item, index) => (
                    <div
                      style={styles.DateItem}
                      key={index}
                      onClick={() =>
                        item.status ? setSelectedDay(item.number) : null
                      }
                    >
                      <div style={styles.DateItemWeekday}>{item.weekday}</div>
                      <div
                        style={{
                          ...styles.DateNumberBackground,
                          backgroundColor:
                            item.number === selectedDay
                              ? COLORS.button
                              : COLORS.cardBackground,
                        }}
                      >
                        <div
                          style={{
                            ...styles.DateItemNumber,
                            opacity: item.status ? 1 : 0.5,
                          }}
                        >
                          {item.number}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            </div>
          {/* FECHA CALENDARIO */}
          
          {/* HORA */}
          <div>
          {loadingHora && <span className="loader"></span>}
          {selectedDay > 0 && listHours.length > 0 && (
            <div style={styles.TimeArea}>
              <h3 style={styles.TimeTitle}>Horários</h3>

              <div style={styles.TimeList}>
                {listHours.map((item, index) => (
                  <div
                    style={{
                      ...styles.TimeItem,
                      backgroundColor:
                        item === selectedHour
                          ? COLORS.button
                          : COLORS.cardBackground,
                    }}
                    key={index}
                    onClick={() => {
                      setSelectedHour(item);
                      // Quando um colaborador é selecionado, role automaticamente para o botão "Finalizar"
                      if (scrollViewRef.current) {
                        scrollViewRef.current.scrollTop =
                          scrollViewRef.current.scrollHeight;
                      }
                    }}
                  >
                    <span
                      style={{
                        ...styles.TimeItemText,
                        fontWeight: item === selectedHour ? "bold" : "normal",
                      }}
                    >
                      {item}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
          </div>
          {/* FECHA HORA */}
          
          {/* BOTÃO FINALIZAR */}
          <div>
          {horarioDisponivel &&
            horarioDisponivel.length > 0 &&
            selectedHour &&
            selectedHour != null && (
              <div style={styles.FinalAreal}>
                {!loadingFinalizar ? (
                  <div
                    style={{
                      ...styles.FinalButton,
                      backgroundColor:
                        selectedHour != null ? COLORS.button : COLORS.secondary,
                    }}
                    // onClick={finalizarFake}
                    onClick={handleFinalizar}
                    disabled={selectedHour != null ? false : true}
                  >
                    Confirmar Agendamento
                  </div>
                ) : (
                  <div
                    style={{
                      ...styles.FinalButton,
                      backgroundColor:
                        selectedHour != null ? COLORS.button : COLORS.secondary,
                    }}
                    onClick={() => {}}
                    disabled={selectedHour != null ? false : true}
                  >
                    <span className="loader"></span>
                  </div>
                )}
              </div>
            )}</div>
          {/* FECHA BOTÃO FINALIZAR */}
        </div>{" "}
        {/* MODAL CONTEUDO */}
      </div>{" "}
      {/**MODAL 2 */}
      {/**MODAL BACKGROUND */}
    
    </div>
  );
}

const styles = {
  Container: {
    flex: 1,
    backgroundColor: COLORS.background,
  },
  Calendario: {
    flex: 1,
    borderRadius: 10,
    marginTop:0,
    marginBottom: 15,
    marginRight: 20,
    marginLeft: 20,
    minHeight:10,
    maxHeight: 210, //tamanho da janela da data
    padding: "20px 10px",
    backgroundColor: COLORS.cardBackground,
  },
  DateInfo: {
    display:"flex",
    flexDirection:'row',
  },
  DatePrevArea: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "flex-end",
    cursor:'pointer'
    
  },
  DateTitleArea: {
    width: "400px",
    justifyContent: "center",
    alignItems: "center",
    textAlign:"center"
  },
  DateTitle: {
    fontSize: "1.5rem",
    color: COLORS.primaryText,
  },
  DateNextArea: {
    flex: 1,
    alignItems: "flex-start",
    cursor:'pointer'
  },
  DateList: {
    marginTop: 10,
    display:"flex",
    flexDirection:'row',
    overflowX:"auto"
  },
  DateItem: {
    display:'flex',
    flexDirection:'column',
    width: "80px",
    justifyContent: "center",
    alignItems: "center",
    textAlign:"center",
    paddingTop: 5,
    paddingBottom: 5,
    marginTop: 5,
    marginLeft:5,
    marginRight:5,
    marginBottom: 5,
    cursor:'pointer',
    // backgroundColor:"red",
  },

  DateItemWeekday: {
    fontSize: "1.1rem",
    marginTop: 10,
    marginBottom: 5,
    color: COLORS.primaryText,
  },
  DateNumberBackground: {
    width: "40px",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 10px",
    border: `1px solid ${COLORS.cardBackground}`,
    borderRadius: 50,
  },
  DateItemNumber: {
    fontSize: "1.1rem",
    color: COLORS.primaryText,
  },


  TimeArea: {
    flex: 1,
    margin: "0px 20px 0px 20px",
    minHeight: "10px",
    padding: "20px 10px",
    // backgroundColor:'red',
  },
  TimeTitle: {
    color: COLORS.primaryText,
    fontSize: "1.5rem",
    marginBottom: 10,
  },
  TimeList: {
    display:"flex",
    // flex-direction: row,
    flexWrap:
      "wrap" /* Isso permite que os itens quebrem e desçam quando não couberem na mesma linha */,
    alignItems: "center" /* Centraliza os itens verticalmente em cada linha */,
  },
  TimeItem: {
    display:'flex',
    width: "21.5%",
    height: "40px",
    justifyContent: "center",
    alignItems: "center",
    textAlign:'center',
    borderRadius: 10,
    marginRight: "3%",
    marginBottom: 15,
  },
  TimeItemText: {
    fontSize: "1.1rem",
    fontWeight: "bold",
    color: COLORS.primaryText,
  },


  ColaboradorArea: {
    flex: 1,
    marginTop: 0,
    marginBottom: 15,
    marginRight: 20,
    marginLeft: 20,
    minHeight: 10,
    maxHeight: 280,
    padding: "20px 10px",
    overflowX: "auto", // Alterado para 'auto' ou 'scroll' para permitir o scroll vertical se necessário
    overflowY: "hidden", // Alterado para 'auto' ou 'scroll' para permitir o scroll vertical se necessário
    // backgroundColor: 'pink',
  },
  ColaboradorTitle: {
    color: COLORS.primaryText,
    fontSize: "1rem",
    marginBottom: 15,
  },
  ColaboradorScroll: {
    display: "flex",
    flex: 1,
    flexDirection: 'row',
    overflowX: "auto",
    minWidth: "100%", // Para garantir que ocupe toda a largura disponível
    paddingBottom:5,
    // backgroundColor: 'red',
  },  
  ColaboradorItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    minWidth: "150px", // Alterado para 'minWidth' para garantir que cada item tenha pelo menos 200px de largura
    height: "180px",
    marginRight: 10,
    borderRadius: 10,
    textDecoration:"none",
    color:COLORS.primaryText,
    overflow: 'hidden',
  },
  ColaboradorImage: {
    width: "100px",
    height: "100px",
    borderRadius: 50,
    marginBottom: 5,
  },
  ColaboradorNome: {
    color: COLORS.primaryText,
    fontSize: "1rem",
    maxWidth: "280px",
    overflow: "hidden" /* Evita que o texto derrame para fora da View */,
    textAlign: "center",
    maxHeight: "70px",
  },


  FinalAreal: {
  },
  FinalButton: {
    display:'flex',
    textAlign:'center',
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    marginRight: 20,
    marginLeft: 20,
    borderRadius: 10,
    marginBottom: 20,
    cursor:'pointer',
    fontSize: "1.25rem",
    fontWeight: "bold",
    color: COLORS.primaryText,
  },
  FinalButtonText: {
  },
};

export default AgendamentoModal;
