import React, { useState, useEffect, useCallback } from "react";
import MaskedInput from "react-text-mask";
import "./ColaboradorCadastro.css"; // Importação do CSS
import { toast } from "react-toastify";
import { colaboradorService } from "../../../services/colaborador";
import { servicoService } from "../../../services/servico";
import { useAuth } from "../../../contexts/AuthContext";
import { COLORS } from "../../../global/STYLE";
import { useNavigate } from "react-router-dom";

const CadastroColaborador = () => {
  const { token, logout } = useAuth();
  const navigate = useNavigate()

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [servicosSelecionados, setServicosSelecionados] = useState([]);
  const [telefone, setTelefone] = useState("");
  const [servicos, setServicos] = useState([]);
  const [carregando, setCarregando] = useState(false);

  // Exemplo de serviços como objetos
  // const servicos = [
  //   { id: 1, titulo: "Serviço 1" },
  //   { id: 2, titulo: "Serviço 2" },
  //   { id: 3, titulo: "Serviço 3" },
  // ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCarregando(true);
    const dadosCreate = {
      nome,
      email,
      password: senha,
      servicos: servicosSelecionados,
      telefone: telefone,
    };
    // Lógica de submissão do formulário
    // console.log({ nome, email, senha, servicosSelecionados, telefone });
    try {
      const data = await colaboradorService.cadColaboradorEstabelecimento(
        token,
        dadosCreate
      );
      if (data.error) {
        if(data.reload){
          navigate("/login/estabelecimento")
          logout()  
        }
        toast.error(data.message);
      } else {
        setNome("")
        setEmail("")
        setSenha("")
        setServicosSelecionados([])
        setTelefone("")
        toast.success(data.message);
      }
    } catch (e) {
      // toast.error(e);
    }
    setCarregando(false);
  };

  const handleServicosChange = (e) => {
    const selecionados = Array.from(e.target.selectedOptions).map(
      (option) => option.value
    );
    setServicosSelecionados(selecionados);
  };

  // useEffect()

  const pegaServicos = useCallback(async () => {
    try {
      const data = await servicoService.pegaServicoEstabelecimento(
        token,
        "ativado"
      );
      if (data.error) {
        if(data.reload){
          navigate("/login/estabelecimento")
          logout()  
        }
        toast.error(data.message);
      } else {
        // console.log(data.data)
        setServicos(data.data);
      }
    } catch (e) {
      // toast.error(e);
    }
  }, []);

  useEffect(() => {
    // Configura o estado inicial com os dados do banco
    // setServicosSelecionados(dadosDoBanco);
    pegaServicos();
  }, []);

  return (
    <div style={{paddingBottom:30}}>
      <h3 style={{ color: COLORS.primaryText }}>Cadastrar Colaborador</h3>
      <span
        className="descricao"
        style={{ backgroundColor: COLORS.bgButtonCard, marginTop: 10 }}
      >
        <p style={{ color: COLORS.primaryText }}>
          Cadastre um novo colaborador já vinculado a algum serviço.
        </p>
        <p style={{ color: COLORS.primaryText }}>
          O colaborador pode ter acesso a outros estabelecimentos, porém ao
          cadastrar por aqui, o colaborador já tem vínculo com o seu serviços no seu 
          estabelecimento.
        </p>
        <p style={{ color: COLORS.primaryText }}>
          Em "Serviços", utilize o atalho do teclado "shift" para selecionar
          mais de um serviço.
        </p>
      </span>

      <hr style={{ marginBottom: "10px" }} />
      
      <form
        onSubmit={handleSubmit}
        className="formulario-colaborador"
        style={{ backgroundColor: COLORS.cardBackground}}
      >
        <label style={{ color: COLORS.placeHolder }}>Nome ou apelido</label>
        <small style={{ color: COLORS.placeHolder, marginTop:-10 }}>Mínimo 4 caracteres</small>
        <input
          type="text"
          placeholder="Digite o nome do colaborador"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          required
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        <label style={{ color: COLORS.placeHolder }}>Email</label>
        <input
          type="email"
          placeholder="Digite o e-mail para acesso do colaborador"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        <label style={{ color: COLORS.placeHolder }}>Senha</label>
        <input
          type="password"
          placeholder="Digite a senha para acesso do colaborador"
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
          required
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        <label style={{ color: COLORS.placeHolder }}>Serviços</label>
        <select
          multiple
          value={servicosSelecionados}
          onChange={handleServicosChange}
          className="form-control"
          required
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        >
          {servicos.map((servico) => (
            <option
              key={servico.id}
              value={servico.id}
              style={{ padding: "5px 0" }}
            >
              {servico.titulo}
            </option>
          ))}
        </select>

        <label style={{ color: COLORS.placeHolder }}>Telefone</label>
        <MaskedInput
          mask={[
            "(",
            /[1-9]/,
            /\d/,
            ")",
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          className="form-control"
          placeholder="Digite o telefone"
          guide={false}
          value={telefone}
          onChange={(e) => setTelefone(e.target.value)}
          required
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        <button
          type="submit"
          className="submit-button"
          disabled={carregando ? true : false}
          style={{ backgroundColor: COLORS.button, marginTop: 20 }}
        >
          {carregando ? <span className="loader"></span> : "Cadastrar"}
        </button>
      </form>
    </div>
  );
};

export default CadastroColaborador;
