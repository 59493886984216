import { useCallback, useState, useEffect } from "react";
import { CONSTANTES } from "../../../global/CONSTANTES";
import "./ModalPagamento.css";
import Modal from "react-modal";
import { formatarValorReal } from "../../../functions/formatarValorReal";
import { toast } from "react-toastify";
import { planoService } from "../../../services/plano";
import { useAuth } from "../../../contexts/AuthContext";
import {
  calcularDesconto,
  calcularPorcentagem,
} from "../../../functions/calculaPorcentagem";
import { pagamentoService } from "../../../services/pagamento";
import { COLORS } from "../../../global/STYLE";
Modal.setAppElement("#root"); // ou o seletor do seu elemento raiz

const RenovarPlanoModal = ({ isOpen, onRequestClose, event, tipoPlano }) => {
  const { token, user } = useAuth();
  // console.log(event);

  const [periodo, setPeriodo] = useState("");
  const [plano, setPlano] = useState("");
  const [cupom, setCupom] = useState("");
  const [descontoValor, setDescontoValor] = useState(0);
  const [descontoPorcento, setDescontoPorcento] = useState(0);
  const [desconto, setDesconto] = useState(0);
  const [total, setTotal] = useState(0);
  const [valorPlano, setValorPlano] = useState(0);
  const [carregando, setCarregando] = useState(false);
  // const [meuSaldo, setMeuSaldo] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCarregando(true);
    try {
      const dadosCreate = {
        plano: plano.plano,
        cupom,
        periodo,
      };
      // console.log(dadosCreate);
      const data = await pagamentoService.createOrder(token, dadosCreate);
      if (data.error) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        window.location.href = data.data;
      }
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  };

  const ajustePeriodoPlano = (e) => {
    const addDesconto = (val) => {
      // console.log(val)
      if (descontoValor > 0) {
        //desconto valor
        setDesconto(descontoValor);
        setTotal(val - desconto);
      } else if (descontoPorcento > 0) {
        //desconto em porcento
        setDesconto(calcularDesconto(descontoPorcento, val));
        setTotal(val - calcularDesconto(descontoPorcento, val));
      } else {
        setTotal(val);
      }
    };
    switch (e.target.value) {
      case "mensal":
        setPeriodo(e.target.value);
        setValorPlano(parseFloat(plano.valor) * 1);
        addDesconto(parseFloat(plano.valor) * 1);
        break;
      case "trimestral":
        setPeriodo(e.target.value);
        setValorPlano(parseFloat(plano.valor) * 3);
        addDesconto(parseFloat(plano.valor) * 3);
        break;
      case "semestral":
        setPeriodo(e.target.value);
        setValorPlano(parseFloat(plano.valor) * 6);
        addDesconto(parseFloat(plano.valor) * 6);
        break;
      case "anual":
        setPeriodo(e.target.value);
        setValorPlano(parseFloat(plano.valor) * 12);
        addDesconto(parseFloat(plano.valor) * 12);
        break;
      default:
        setPeriodo(e.target.value);
        setValorPlano(parseFloat(plano.valor) * 1);
        addDesconto(valorPlano);
    }
  };

  const aplicarCupom = async () => {
    try {
      const data = await planoService.pegaCupom(token, cupom);
      if (data.error) {
        toast.error(data.message);
      } else {
        //verifica se o cupom é porcentagem ou valor
        if (data.data.valor <= 0) {
          //atribui desconto pela porcentagem
          setDesconto(calcularDesconto(data.data.porcentagem, valorPlano));
          setDescontoPorcento(parseFloat(data.data.porcentagem));
          setTotal(
            valorPlano - calcularDesconto(data.data.porcentagem, valorPlano)
          );
        } else {
          //atribui desconto pelo valor
          let valor = parseFloat(data.data.valor);
          setDesconto(valor);
          setTotal(valorPlano - valor);
          setDescontoValor(parseFloat(data.data.valor));
        }
        toast.success(data.message);
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const pegaDados = useCallback(async () => {
    try {
      const data = await planoService.pegaPlanoPeloPlano(token, event.plano);
      if (data.error) {
        toast.error(data.message);
      } else {
        setPlano(data.data);
        // setValorPlano(data.data.valor);
      }
    } catch (e) {
      toast.error(e);
    }
  }, [event, tipoPlano]);

  useEffect(() => {
    pegaDados();
  }, [pegaDados]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: { zIndex: 1000, width: "100%", height: "auto", backgroundColor: COLORS.background },
      }}
      contentLabel="Renovar Plano"
    >
      <div className="containerPagamentoModal">
        <div className="headerModalPagamento">
          <h2>{tipoPlano}</h2>
          <button
            onClick={onRequestClose}
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 5,
              fontSize: 12,
            }}
            className="buttonModalPagamento"
          >
            Fechar
          </button>
        </div>

        <hr
          style={{
            marginBottom: 20,
            borderColor: "rgba(0,0,0,0.2)",
            borderWidth: 1,
          }}
        />

        <div className="bodyModalPagamento">
          <div>
            <strong>Plano atual: {event.plano.toUpperCase()}</strong>
            <p>
              <br />
            </p>
          </div>

          <form
            onSubmit={handleSubmit}
            className="formulario-perfil-modal-pagamento"
          >
            <label>
              Escolha o período do plano
              <select
                value={periodo}
                onChange={(e) => ajustePeriodoPlano(e)}
                className="formulario-servico-select"
                onKeyDown={handleKeyDown}
              >
                <option value="">Período...</option>
                <option value="mensal">Mensal (1 mês)</option>
                <option value="trimestral">Trimestral (3 meses)</option>
                <option value="semestral">Semestral (6 meses)</option>
                <option value="anual">Anual (1 ano)</option>
                {/* <option value="a">Teste inválido</option> */}
              </select>
            </label>

            <label>
              Cupom de desconto:
              <input
                type="text"
                value={cupom || ""}
                onChange={(e) => setCupom(e.target.value)}
                placeholder="adicione aqui o seu cupom"
                onKeyDown={handleKeyDown}
              />
            </label>
            
            <label className="descPag-modal">
              {cupom.length >= 5 ? (
                <span
                  className="buttonCUPOMModalPagamento"
                  onClick={aplicarCupom}
                >
                  Aplicar cupom
                </span>
              ) : (
                <span className="buttonCUPOMFakeModalPagamento">.</span>
              )}
            </label>

            <hr />
            <br />
            <label className="descPag-modal">
              <div>
                <strong>Valor do Plano</strong>
              </div>
              <div>
                {valorPlano ? formatarValorReal(valorPlano) : "R$ 0,00"}
              </div>
            </label>

            <label className="descPag-modal">
              <div>
                <strong>Desconto com CUPOM</strong>
              </div>
              <div>
                {desconto ? `- ${formatarValorReal(desconto)}` : "R$ 0,00"}
              </div>
            </label>
            
            {/* <hr style={{marginBottom:10}}/>
            <label className="descPag-modal">
              <div>
              <input type="checkbox" id="saldo" name="saldo" value={meuSaldo} onClick={(e)=>setMeuSaldo(!meuSaldo)} /> <strong>Saldo estabelecimento: {user.saldo ? formatarValorReal(user.saldo) : "R$ 0,00"}</strong>
              </div>
              <div>
                {user.saldo ? formatarValorReal(user.saldo) : "R$ 0,00"}
              </div>
            </label> */}

            <label className="descPag-modal-seta">
              <div></div>
              <div>
                <strong>___________</strong>
              </div>
            </label>
            <label className="descPag-modal">
              <div>
                <strong>Valor Total do Plano</strong>
              </div>
              <div>
                <strong>{total ? formatarValorReal(total) : "R$ 0,00"}</strong>
              </div>
            </label>
            <br />
            {periodo.length > 0 && (
              <button className="submit-button">
                {carregando ? (
                  <span className="loader"></span>
                ) : (
                  "Renovar Plano"
                )}
              </button>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default RenovarPlanoModal;
