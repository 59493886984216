import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext"; // Importando useAuth
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faPlus,
  faTimes,
  faTrash,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom"; // Garanta que esta importação está correta
import { CONSTANTES } from "../../global/CONSTANTES";
import "./styles.css";
import { clienteService } from "../../services/cliente";
import { toast } from "react-toastify";

const NavbarHome = () => {
  const { userType, isLogado, user, logout, token, changeData } = useAuth(); // Utilizando userType, isLogado, user e logout
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const navigate = useNavigate(); // Função para navegação
  const [avatarExpanded, setAvatarExpanded] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleOutsideClick = (event) => {
    if (
      isNavExpanded &&
      !event.target.closest(".sidebar_home") &&
      !event.target.closest(".nav_home_toggle") &&
      !event.target.closest(".nav_home_avatar")
    ) {
      setIsNavExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isNavExpanded]);

  useEffect(() => {
    if (isNavExpanded) {
      document.body.classList.add("nav-home-expanded");
    } else {
      document.body.classList.remove("nav-home-expanded");
    }
  }, [isNavExpanded]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && !(isLogado && userType === "cliente")) {
        setIsNavExpanded(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isLogado, userType]);

  const handleLogout = () => {
    logout();
    if (window.innerWidth > 768) {
      setIsNavExpanded(false);
    }
    navigate("/")
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile); // Atualiza o estado do arquivo selecionado

    if (selectedFile) {
      try {
       
        const dados = await clienteService.changePhoto(
          token,
          selectedFile,
          user.id
        );

        if (dados.error) {
          toast.error(dados.message);
          if (dados.reload) {
            logout();
            navigate("/");
          }
        } else {
          toast.success("Foto atualizada com sucesso!");
          changeData("foto", dados.data.foto); // Atualiza a foto do usuário no contexto
        }
      } catch (e) {
        console.error("Error:", e.message);
        toast.error("Erro ao atualizar a foto. Tente novamente.");
      }
    }
  };

  const handleDeletePhoto = async () => {
    if (window.confirm("Você realmente quer excluir a foto do perfil?")) {
     try{
const dados = await clienteService.excluirFotoPerfil(token)
      if (dados.error) {
        toast.error(dados.message);
        if (dados.reload) {
          logout();
          navigate("/");
        }
      } else {
        toast.success("Foto excluída com sucesso!");
        changeData("foto", ""); // Atualiza a foto do usuário no contexto
      }
    } catch (e) {
      console.error("Error:", e.message);
      toast.error("Erro ao excluir a foto. Tente novamente.");
    }
    }
  };

  return (
    <nav className="navbar_home">
      <div className="logo">
        <img
          src={`${CONSTANTES.logoImage}`}
          alt={CONSTANTES.logo}
          title={CONSTANTES.logo}
          width={150}
          height={50}
        />
      </div>
      <div className="nav_home_right">
        {isLogado && userType === "cliente" ? (
          user?.foto ? (
            <img
              src={`${CONSTANTES.uri}${CONSTANTES.caminhoImageClientes}${user.id}/${user.foto}`} // Substitua pelo caminho correto do avatar
              alt="User Avatar"
              className="nav_home_avatar" // Nova classe para o avatar
              onClick={() => setIsNavExpanded(!isNavExpanded)}
              style={{
                cursor: "pointer",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
              }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faUserCircle} // Ícone padrão se não houver avatar
              className="nav_home_avatar"
              onClick={() => setIsNavExpanded(!isNavExpanded)}
              style={{
                cursor: "pointer",
                width: "40px",
                height: "40px",
                color: "white",
              }}
            />
          )
        ) : (
          <>
            <div className="nav_home_buttons">
              <button
                className="nav_home_item"
                onClick={() => {
                  navigate("/login/cliente");
                  setIsNavExpanded(false);
                }}
                style={{ borderRadius: 5 }}
              >
                Cliente
              </button>
              <button
                className="nav_home_item"
                onClick={() => {
                  userType === "estabelecimento"
                    ? navigate("/adminE")
                    : navigate("/login/estabelecimento");
                  setIsNavExpanded(false);
                }}
                style={{ borderRadius: 5 }}
              >
                Estabelecimento
              </button>
              <button
                className="nav_home_item"
                onClick={() => {
                  userType === "colaborador"
                    ? navigate("/adminC")
                    : navigate("/login/colaborador");
                  setIsNavExpanded(false);
                }}
                style={{ borderRadius: 5 }}
              >
                Colaborador
              </button>
            </div>
            <button
              className="nav_home_toggle"
              aria-label="Toggle navigation"
              onClick={() => setIsNavExpanded(!isNavExpanded)}
            >
              <FontAwesomeIcon icon={isNavExpanded ? faTimes : faBars} />
            </button>
          </>
        )}
      </div>
      {isNavExpanded && (
        <div className={`sidebar_home ${isNavExpanded ? "expanded_home" : ""}`}>
          {isLogado && userType === "cliente" ? (
            <>
              <div className="avatar_section">
                {user?.foto ? (
                  <img
                    src={`${CONSTANTES.uri}${CONSTANTES.caminhoImageClientes}${user.id}/${user.foto}`}
                    alt="User Avatar"
                    className="avatar"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    className="avatar"
                    color="white"
                  />
                )}
                <div className="avatar_controls">
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />

                  <button
                    onClick={() => {
                      // console.log("File input button clicked");
                      document.getElementById("fileInput").click();
                    }}
                    alt="Adicionar ou alterar foto do perfil"
                    title="Adicionar ou alterar foto do perfil"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                  <button
                    onClick={handleDeletePhoto}
                    alt="Excluir foto do perfil"
                    title="Excluir foto do perfil"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              </div>
              <button
                className="nav_home_item"
                onClick={() => {
                  navigate("/");
                }}
              >
                Início
              </button>
              <button
                className="nav_home_item"
                onClick={() => {
                  // console.log("meu agendamento")
                  navigate("/meus-agendamentos");
                }}
              >
                Meus agendamentos
              </button>
              <button
                className="nav_home_item"
                onClick={() => {
                  navigate("/perfil-cliente");
                }}
              >
                Perfil
              </button>
              <button className="nav_home_item" onClick={handleLogout}>
                Sair
              </button>
            </>
          ) : (
            <>
              <button
                className="nav_home_item"
                onClick={() => {
                  navigate("/login/cliente");
                  setIsNavExpanded(false);
                }}
              >
                Cliente
              </button>
              <button
                className="nav_home_item"
                onClick={() => {
                  userType === "estabelecimento"
                    ? navigate("/adminE")
                    : navigate("/login/estabelecimento");
                  setIsNavExpanded(false);
                }}
              >
                Estabelecimento
              </button>
              <button
                className="nav_home_item"
                onClick={() => {
                  userType === "colaborador"
                    ? navigate("/adminC")
                    : navigate("/login/colaborador");
                  setIsNavExpanded(false);
                }}
              >
                Colaborador
              </button>
            </>
          )}
        </div>
      )}
    </nav>
  );
};

export default NavbarHome;
