import Api from "../index";

const getNews = async (token, tipo,page) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.get(`/news/${tipo}/${page}`, headerToken);
  return data;
};


export const newsService = {
  getNews,
  
};
