// NewsFeed.js
import React, { useCallback, useEffect, useState } from 'react';
import NewsItem from './NewsItem';
// import './News.css';
import '../../ADMIN_Estabelecimento/components/News.css';
import { useAuth } from '../../../contexts/AuthContext';
import { newsService } from '../../../services/news';
import { toast } from 'react-toastify';
import { COLORS } from '../../../global/STYLE';
import { useNavigate } from 'react-router-dom';

const NewsFeed = () => {
  const {user, token, logout} = useAuth()
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [novidades, setNovidades] = useState([])
  const [totalPages, setTotalPages] = useState(0); 

  const pegaNews = useCallback(async () => {
    try {
      const data = await newsService.getNews(token, "colaborador", page);
      if (data.error) {
        if(data.reload){
          navigate("/login/colaborador")
          logout()  
        }
        toast.error(data.message);
      } else {
        setNovidades(data.data)
        setTotalPages(data.totalPages)
        // console.log(data.data)
      }
    } catch (e) {
      // toast.error(e);
    }
  }, [page, token]);

  useEffect(() => {
    pegaNews()
  }, [pegaNews]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setPage(pageNumber); // Adicionado para manter a consistência entre currentPage e page
  };

  return (
    <div className="news-feed" style={{backgroundColor:COLORS.cardBackground}}>
      <h2 style={{color:COLORS.primaryText}}>Novidades</h2>
      {novidades.map((news) => (
        <NewsItem key={news.id} news={news} />
      ))}
      {totalPages > 1 && (
        <div className="pagination">
          {[...Array(totalPages)].map((_, index) => (
          // {[...Array(Math.ceil(novidades.length / newsPerPage))].map((_, index) => (
            <button key={index} onClick={() => paginate(index + 1)}>
              {index + 1}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default NewsFeed;
