// LoginEstabelecimento.js

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CONSTANTES } from "../../global/CONSTANTES";
import { useAuth } from "../../contexts/AuthContext";
import "./Login.css";
import { toast } from "react-toastify";
import { resetSenhaService } from "../../services/resetSenha";
import { COLORS } from "../../global/STYLE";

const ResetSenha = () => {
  let navigate = useNavigate();
  const { user, login, logout } = useAuth();
  // console.log(user, login, logout)

  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [carregando, setCarregando] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCarregando(true);
    // console.log(email, password)
    const createData = {
      newPassword: password,
      token: token,
    };

    // console.log(createData)
    try {
      const data = await resetSenhaService.resetartSenha(createData);
      if (data.error) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        //apaga os dados
        setPassword("");
        setToken("");
        navigate(`/login/${data.loginPage}`);
      }
    } catch (e) {
      toast.error(e);
    }
    setCarregando(false);
  };

  useEffect(() => {
    // Obtenha a URL completa
    const url = window.location.href;
    // Use uma biblioteca ou uma lógica personalizada para analisar a URL e obter o parâmetro desejado
    const params = new URL(url).searchParams;
    setToken(params.get("token"));
  }, []);

  return (
    <div className="bodyLogin" style={{ backgroundColor: COLORS.background }}>
      <div className="login-estabelecimento-container">
        <div className="logo-container">
          <img
            src={`/${CONSTANTES.logoImage}`}
            alt={CONSTANTES.logo}
            title={CONSTANTES.logo}
            width={200}
            height={75}
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          />{" "}
          {/* <img src="caminho/para/sua/logo.png" alt={CONSTANTES.logo} /> */}
        </div>
        <form onSubmit={handleSubmit} className="login-estabelecimento-form">
          <label htmlFor="password" style={{ color: COLORS.placeHolder }}>
            Nova Senha:
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            required
            style={{
              border: `1px solid ${COLORS.secondary}`,
              backgroundColor: ` ${COLORS.inputBackground}`,
              color: COLORS.primaryText,
            }}
          />

          <div className="form-actions">
            <button
              type="submit"
              style={{
                backgroundColor: COLORS.button,
                color: COLORS.primaryText,
              }}
              disabled = {carregando ? true : false}
            >
              {carregando ? <span className="loader"></span> : "Resetar Senha"}
            </button>

            <span
              type="button"
              onClick={() => navigate("/")}
              style={{ color: COLORS.button }}
            >
              Voltar
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetSenha;
