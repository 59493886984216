import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./styles.css";
import { formatarValorReal } from "../../../functions/formatarValorReal";
import { useAuth } from "../../../contexts/AuthContext";
import { planoService } from "../../../services/plano";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { cupomService } from "../../../services/cupom";

// Set the app element to avoid screen readers issues
Modal.setAppElement("#root");

const ModalCupom = ({ isOpen, onRequestClose, addCupom }) => {
  const { token, logout } = useAuth();
  const navigate = useNavigate();
  // !cupom || !quantidade || (!porcentagem || !valor) || !tipo || !validade
  const [cupom, setCupom] = useState("");
  const [valor, setValor] = useState("");
  const [quantidade, setQuantidade] = useState(1);
  const [porcentagem, setPorcentagem] = useState(0);
  const [tipo, setTipo] = useState("todos");
  const [status, setStatus] = useState(1);
  const [validade, setValidade] = useState("");
  const [descricao, setDescricao] = useState("");

  const [carregando, setCarregando] = useState(false);

  const handlePlanoInputValorChange = (event) => {
    let value = event;
    // Remove caracteres não numéricos
    value = value.replace(/\D/g, "");
    // Remove os zeros à esquerda
    value = value.replace(/^0+/, "");
    // Preenche com zeros à esquerda até ter 6 caracteres
    value = value.padStart(6, "0");
    // Divide o valor para obter a parte inteira e a parte decimal
    const parteInteira = value.slice(0, -2) || "0";
    const parteDecimal = value.slice(-2);
    // Formata o valor para o formato de reais
    const valorFormatado = `${parteInteira}.${parteDecimal}`;

    setValor(valorFormatado);
  };

  const handleSubmit = async () => {
    setCarregando(true);
    if (!cupom || !quantidade || !tipo || !validade || (!porcentagem && !valor) ) {
      toast.error(`Todos os campos devem ser preenchidos.\n
      - Cupom\n
      - Descrição\n
      - Quantidade\n
      - Validade      
      `);
      setCarregando(false);
      return;
    }   

    try{

      const dado = {
        cupom,
        descricao,
        quantidade:Number(quantidade),
        porcentagem:Number(porcentagem),
        valor:Number(valor),
        tipo:String(tipo),
        status: Boolean(status),
        validade:String(validade)
      };
      // console.log(dado)
      const data = await cupomService.cadastrarCupom(token, dado);
      if (data.error) {
        toast.error(data.message);
        if (data.reload) {
          setCarregando(false);
          navigate("/login/adm_facilite");
          logout();
        }
        setCarregando(false);
      } else {
        addCupom(data.data)
        toast.success(data.message);
        setCupom("");
        setDescricao("");
        setQuantidade(1)
        setPorcentagem(0)
        setValor("")
        setValidade("")
        setTipo("todos")
        setCarregando(false);
      }
      setCarregando(false);
    }catch(e){
      setCarregando(false);
    }

  };

  useEffect(() => {
      setCupom("");
      setDescricao("");
      setQuantidade(1)
      setPorcentagem(0)
      setValor("")
      setValidade("")
      setTipo("todos")
    
  }, []);

  useEffect(() => {
    if (quantidade < 0) {
      setQuantidade(0);
    }
  }, [quantidade]);

  useEffect(() => {
    if (porcentagem <= 0 || porcentagem > 100) {
      setPorcentagem(0);
    }
  }, [porcentagem]);

  // useEffect(() => {
  //   console.log(Number(valor))
  // }, [valor]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Formulário de Plano"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <div
        style={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
          marginBottom: 15,
        }}
      >
        <h3>Cadastrar novo Plano</h3>
        <button onClick={onRequestClose} className="close-button">
          Fechar
        </button>
      </div>
      <form className="form-content">
        <div className="form-group">
          <label>Cupom</label>
          <input
            type="text"
            name="cupom"
            value={cupom}
            onChange={(e) => setCupom(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Descrição</label>
          <input
            type="text"
            name="descricao"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Quantidade</label>
          <input
            type="number"
            name="quantidade"
            value={quantidade}
            min={0}
            // max={100}
            onChange={(e) => {
              let valInput = e.target.value.length;
              if (valInput >= 0 && valInput <= 4 && Number(valInput)) {
                setQuantidade(parseInt(e.target.value, 10));
              }
            }}
            required
          />
        </div>

        <div className="form-group">
          <label>Porcentagem</label>
          <input
            type="number"
            name="porcentagem"
            value={porcentagem}
            min={0}
            max={100}
            onChange={(e) => {
              let valInput = e.target.value.length;
              if (valInput >= 0 && valInput <= 4 && Number(valInput)) {
                setPorcentagem(parseInt(e.target.value, 10));
              }
            }}
            
            disabled={Number(valor) ? true : false}
          />
        </div>

        <div className="form-group">
          <label>Valor</label>
          <input
            type="text"
            name="valor"
            
            placeholder="00,00"
            value={formatarValorReal(valor)}
            onChange={(e) => handlePlanoInputValorChange(e.target.value)}
            disabled={porcentagem ? true : false}
          />
        </div>

        <div className="form-group">
          <label>Tipo de Cupom</label>
          <select
            name="tipo"
            value={tipo}
            onChange={(e) => setTipo(e.target.value)}
            required
          >
            <option value="todos">Todos</option>
            {Array.from({ length: 10 }, (_, i) => (
              <option key={`${i + 1}`} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Status</label>
          <select
            name="status"
            value={status}
            onChange={(e) => setStatus(Number(e.target.value))}
          >
            <option value="true">Ativado</option>
            <option value="false">Desativado</option>
          </select>
        </div>

        <div className="form-group">
          <label>Validade do cupom</label>
          <input
            type="date"
            name="validade"
            value={validade}
            onChange={(e) => {setValidade(e.target.value)}}
            required
          />
        </div>

        <button type="button" onClick={handleSubmit} className="cad-button" disabled = {carregando ? true : false}>
        {carregando ? <span className="loader"></span> : "Cadastrar Cupom"}
        </button>
      </form>
    </Modal>
  );
};

export default ModalCupom;
