import React, { useState } from "react";
import MaskedInput from "react-text-mask";
import { toast } from "react-toastify";
import "./styles.css";
import estadosData from "../../../data/Estados.json";
import cidadesData from "../../../data/Cidades.json";
import { useEffect } from "react";
import { estabelecimentoService } from "../../../services/estabelecimento";
import { categoriaService } from "../../../services/categoria";
import { useAuth } from "../../../contexts/AuthContext";
import { COLORS } from "../../../global/STYLE";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importar os estilos
import { useNavigate } from "react-router-dom";
import { CONSTANTES } from "../../../global/CONSTANTES";

function PerfilEstabelecimento() {
  const { token, changeData, logout } = useAuth();
  const navigate = useNavigate();

  const [categorias, setCategorias] = useState([]);
  const [categoriasSelected, setCategoriasSelected] = useState([]);
  const [nome, setNome] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [email, setEmail] = useState("");
  const [descricao, setDescricao] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [telefone, setTelefone] = useState("");
  const [tipoDocumento, setTipoDocumento] = useState("");
  const [documento, setDocumento] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [logradouro, setLogradouro] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [cep, setCep] = useState("");
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [carregando, setCarregando] = useState(false);
  const [site, setSite] = useState("");
  const [instagram, setInstagram] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [facebook, setFacebook] = useState("");
  const [youtube, setYoutube] = useState("");

  const handleSubmit = async (e) => {
    setCarregando(true);

    e.preventDefault();
    try {
      let uf = "";
      if (estado) {
        // console.log("tem estado")
        const estadoFiltrado = estados.filter((e) => e.ID === estado);
        uf = estadoFiltrado[0].Nome;
      }

      if (categoriasSelected.length <= 0) {
        return toast.error("Escolha uma categoria para o seu estabelecimento!");
      }

      const cat = categoriasSelected.map((c) => c.id);

      const dadosCreate = {
        nome,
        nomeCompleto,
        razao_social: razaoSocial,
        descricao,
        data_nascimento: dataNascimento,
        telefone,
        documento_tipo: tipoDocumento,
        documento_numero: documento,
        uf,
        cidade,
        logradouro,
        numero,
        bairro,
        cep,
        latitude: lat,
        longitude: lng,
        categoria: cat,
        site,
        instagram,
        facebook,
        youtube,
        whatsapp,
      };

      const data = await estabelecimentoService.updateEstabelecimento(
        token,
        dadosCreate
      );
      if (data.error) {
        if (data.reload) {
          navigate("/login/estabelecimento");
          logout();
        }
        toast.error(data.message);
      } else {
        changeData("nome", nome);
        toast.success(data.message);
      }
      // console.log(dadosCreate);
      // Fazer algo com os dadosCreate, como enviar para a API
    } catch (e) {
      // toast.error(e);
    } finally {
      setCarregando(false);
    }
  };

  const handleChange = (value) => {
    setDescricao(value);
  };

  const pegaDadosEstabelecimento = async () => {
    try {
      const data = await estabelecimentoService.dataEstabelecimento(token);
      if (data.error) {
        if (data.reload) {
          navigate("/login/estabelecimento");
          logout();
        }
        toast.error(data.message);
      } else {
        setNome(data.data.nome);
        setNomeCompleto(data.data.nomeCompleto);
        setRazaoSocial(data.data.razao_social);
        setDescricao(data.data.descricao || "");
        setEmail(data.data.email);
        setDataNascimento(data.data.data_nascimento);
        setTelefone(data.data.telefone);
        setTipoDocumento(data.data.documento_tipo);
        setDocumento(data.data.documento_numero);
        setLogradouro(data.data.logradouro);
        setNumero(data.data.numero);
        setBairro(data.data.bairro);
        setSite(data.data.site);
        setInstagram(data.data.instagram);
        setFacebook(data.data.facebook);
        setYoutube(data.data.youtube);
        setWhatsapp(data.data.whatsapp);
        setCep(data.data.cep);
        // console.log(data.data.categorias)
        setCategoriasSelected(data.data.categorias);

        //estado
        // console.log("estado banco = ", data.data.uf);
        if (data.data.uf) {
          // console.log("tem estado")
          const estadoFiltrado = estados.filter((e) => e.Nome === data.data.uf);

          // console.log("estado encontrado: ", estadoFiltrado);
          handleEstadoChange(estadoFiltrado[0].ID);
          setEstado(estadoFiltrado[0].ID);

          if (data.data.cidade) {
            const cidadeFiltrada = cidadesData.filter(
              (c) => c.Nome === data.data.cidade
            );
            setCidade(cidadeFiltrada[0].Nome);
            // console.log("Cidade = ",cidadeFiltrada);
          }
        }
      }
    } catch (e) {
      // toast.error(e);
    }
  };

  const pegaLocalizacao = async () => {
    let uf = "";
    if (estado) {
      // console.log("tem estado")
      const estadoFiltrado = estados.filter((e) => e.ID === estado);
      uf = estadoFiltrado[0].Nome;
    }

    // const adicionarComVirgula = (valor, anterior) =>
    //   valor ? (anterior ? `,${valor}` : valor) : "";

    // let endereco = "";

    // endereco += adicionarComVirgula(logradouro, "");
    // endereco += adicionarComVirgula(numero, endereco);
    // endereco += adicionarComVirgula(bairro, endereco);
    // endereco += adicionarComVirgula(cidade, endereco);
    // endereco += adicionarComVirgula(uf, endereco);
    // endereco += adicionarComVirgula(cep, endereco);

    const endereco = `${logradouro}, ${numero}, ${bairro}, ${cidade}, ${uf}`;

    // console.log(endereco)
    const apiKey = CONSTANTES.GOOGLE_API_KEY; // Substitua com sua chave de API do Google Maps
    // console.log("API GOOGLE = ",apiKey)
    try {
      // const data = await estabelecimentoService.mapsLatLong(endereco);
      // console.log("localização = ", data);
      // if (data.error) {
      //   toast.error(data.message);
      // } else {
      //   console.log(data[0])
      //   setLat(data[0].lat);
      //   setLng(data[0].lon);
      // }
      // console.log(endereco)
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          endereco
        )}&key=${apiKey}`
      );
      const data = await response.json();
      // console.log("RESPOSTA = ",data)
      if (data.status === "OK" && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        setLat(lat);
        setLng(lng);
        // setFormData(prevState => ({
        //   ...prevState,
        //   latitude: lat,
        //   longitude: lng,
        // }));
      }
      // else {
      //   console.log("Endereço não encontrado");
      // }
    } catch (e) {
      // toast(e);
    }
  };

  const pegaCategoria = async () => {
    try {
      const data = await categoriaService.categoriaAll();
      if (data.error) {
        if (data.reload) {
          return false;
        }
        toast.error(data.message);
      } else {
        // toast.success(data.message);
        setCategorias(data.data);
      }
    } catch (e) {
      // toast.error(e);
    }
  };

  useEffect(() => {
    pegaCategoria();
  }, []);

  useEffect(() => {
    pegaLocalizacao();
  }, [cidade, estado, numero, bairro, logradouro, cep]);

  useEffect(() => {
    setEstados(estadosData);
    pegaDadosEstabelecimento();
  }, [estados]);

  const handleEstadoChange = (estadoSelecionado) => {
    setEstado(estadoSelecionado);

    // Filtra as cidades baseado no estado selecionado
    const cidadesFiltradas = cidadesData.filter(
      (cidade) => cidade.Estado === estadoSelecionado
    );

    // console.log(estadoSelecionado)

    setCidades(cidadesFiltradas);
  };

  return (
    <div className="">
      <h3 style={{ color: COLORS.primaryText }}>Perfil do estabelecimento</h3>
      <div
        className="descricao"
        style={{
          backgroundColor: COLORS.bgButtonCard,
          marginTop: 10,
          fontFamily: "Calibri",
        }}
      >
        <p style={{ color: COLORS.primaryText }}>
          Para ajudar os clientes a te encontrar melhor, mantenha todos os dados
          devidamente preenchidos.
        </p>
        <p style={{ color: COLORS.primaryText }}>
          Alguns campos são estritamente obrigatórios, pois são campos
          importantes, por isso estão com um asterisco (
          <span style={{ color: "red", cursor: "default" }}>*</span>) na cor
          vermelha. Cuidado ao preenchê-los.
        </p>
        <a
          href={`https://facilite.app/delete-account`}
          style={{
            padding: 10,
            textDecoration: "none",
            background: COLORS.cardBackground,
            width: "10%",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 5,
            color: COLORS.cancelado,
            marginTop: 10,
            cursor: "pointer",
          }}
          title="Excluir conta"
          alt="excluir conta"
        >
          Excluir conta
        </a>
      </div>

      <form
        onSubmit={handleSubmit}
        className="formulario-perfil"
        style={{ backgroundColor: COLORS.background }}
      >
        <label style={{ color: COLORS.placeHolder }}>
          Nome do estabelecimento (Nome Fantasia){" "}
          <span style={{ color: "red", cursor: "default" }}>*</span>
        </label>
        <input
          type="text"
          value={nome || ""}
          onChange={(e) => setNome(e.target.value)}
          required={true}
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />
        <label style={{ color: COLORS.placeHolder }}>
          Razão Social{" "}
          <span style={{ color: "red", cursor: "default" }}>*</span>
        </label>
        <input
          type="text"
          value={razaoSocial || ""}
          onChange={(e) => setRazaoSocial(e.target.value)}
          required={true}
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />
        <label style={{ color: COLORS.placeHolder }}>
          Nome do proprietário{" "}
          <span style={{ color: "red", cursor: "default" }}>*</span>
        </label>
        <input
          type="text"
          value={nomeCompleto || ""}
          onChange={(e) => setNomeCompleto(e.target.value)}
          required
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />
        <label
          style={{
            color: COLORS.placeHolder,
          }}
        >
          Descrição:
        </label>
        <ReactQuill
          theme="snow" // Escolha o tema (snow é um tema padrão)
          value={descricao}
          onChange={handleChange}
          style={{
            height: "250px",
            marginBottom: 70,
            color: COLORS.primaryText,
          }}
        />
        <label style={{ color: COLORS.placeHolder }}>E-mail</label>
        <input
          type="email"
          value={email}
          // onChange={(e) => setEmail(e.target.value)}
          disabled
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />
        <label
          style={{
            color: COLORS.placeHolder,
          }}
        >
          Data de Nascimento da empresa
        </label>
        <MaskedInput
          mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
          value={dataNascimento}
          onChange={(e) => setDataNascimento(e.target.value)}
          placeholder="DD/MM/AAAA"
          className="masked-input"
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />
        <label
          style={{
            color: COLORS.placeHolder,
          }}
        >
          Telefone <span style={{ color: "red", cursor: "default" }}>*</span>
        </label>
        <MaskedInput
          mask={[
            "(",
            /[1-9]/,
            /\d/,
            ")",
            " ",
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          value={telefone || ""}
          onChange={(e) => setTelefone(e.target.value)}
          placeholder="(31) 9 4567-8901"
          className="masked-input"
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
          required={true}
        />
        <label
          style={{
            color: COLORS.placeHolder,
          }}
        >
          Tipo de Documento{" "}
          <span style={{ color: "red", cursor: "default" }}>*</span>
        </label>
        <select
          value={tipoDocumento || ""}
          onChange={(e) => setTipoDocumento(e.target.value)}
          className="formulario-servico-select"
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        >
          <option value="">Selecione...</option>
          <option value="cpf">CPF</option>
          <option value="cnpj">CNPJ</option>
        </select>
        <span style={{ color: COLORS.primaryText }}>
          {tipoDocumento === "cpf" ? "CPF" : "CNPJ"}
        </span>{" "}
        <span style={{ color: "red", cursor: "default" }}>*</span>
        <MaskedInput
          mask={
            tipoDocumento === "cpf"
              ? [
                  /\d/,
                  /\d/,
                  /\d/,
                  ".",
                  /\d/,
                  /\d/,
                  /\d/,
                  ".",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                ]
              : [
                  /\d/,
                  /\d/,
                  ".",
                  /\d/,
                  /\d/,
                  /\d/,
                  ".",
                  /\d/,
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                ]
          }
          value={documento || ""}
          onChange={(e) => setDocumento(e.target.value)}
          placeholder={
            tipoDocumento === "cpf" ? "123.456.789-01" : "12.345.678/0001-90"
          }
          className="masked-input"
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />
        <label
          style={{
            color: COLORS.placeHolder,
          }}
        >
          Estado <span style={{ color: "red", cursor: "default" }}>*</span>
        </label>
        <select
          value={estado}
          onChange={(e) => handleEstadoChange(e.target.value)}
          className="formulario-servico-select"
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        >
          <option value="">Selecione...</option>
          {estados.map((estado) => (
            <option key={estado.ID} value={estado.ID}>
              {estado.Nome}
            </option>
          ))}
        </select>
        <label
          style={{
            color: COLORS.placeHolder,
          }}
        >
          Cidade <span style={{ color: "red", cursor: "default" }}>*</span>
        </label>
        <select
          value={cidade}
          onChange={(e) => setCidade(e.target.value)}
          className="formulario-servico-select"
          disabled={!estado} // Desabilita se nenhum estado estiver selecionado
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        >
          <option value="">Selecione...</option>
          {cidades.map((cidade) => (
            <option key={cidade.ID} value={cidade.Nome}>
              {cidade.Nome}
            </option>
          ))}
        </select>
        <label
          style={{
            color: COLORS.placeHolder,
          }}
        >
          Logradouro <span style={{ color: "red", cursor: "default" }}>*</span>
        </label>
        <input
          type="text"
          value={logradouro || ""}
          onChange={(e) => setLogradouro(e.target.value)}
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />
        <label
          style={{
            color: COLORS.placeHolder,
          }}
        >
          Número <span style={{ color: "red", cursor: "default" }}>*</span>
        </label>
        <input
          type="text"
          value={numero || ""}
          onChange={(e) => setNumero(e.target.value)}
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />
        <label
          style={{
            color: COLORS.placeHolder,
          }}
        >
          Bairro <span style={{ color: "red", cursor: "default" }}>*</span>
        </label>
        <input
          type="text"
          value={bairro || ""}
          onChange={(e) => setBairro(e.target.value)}
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />
        <label
          style={{
            color: COLORS.placeHolder,
          }}
        >
          CEP <span style={{ color: "red", cursor: "default" }}>*</span>
        </label>
        <MaskedInput
          mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
          value={cep || ""}
          onChange={(e) => setCep(e.target.value)}
          placeholder="12345-678"
          className="masked-input"
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />
        <label
          style={{
            color: COLORS.placeHolder,
          }}
        >
          Latitude
        </label>
        <input
          type="text"
          value={lat || ""}
          // onChange={(e) => setBairro(e.target.value)}
          disabled
          style={{ color: COLORS.toastWarning }}
        />
        <label
          style={{
            color: COLORS.placeHolder,
          }}
        >
          Longitude
        </label>
        <input
          type="text"
          value={lng || ""}
          // onChange={(e) => setBairro(e.target.value)}
          disabled
          style={{ color: COLORS.toastWarning }}
        />
        {/* REDES SOCIAIS */}
        <h3
          style={{
            color: COLORS.primaryText,
            marginBottom: 20,
            marginTop: 20,
            borderTop: "1px solid",
            borderTopColor: COLORS.placeHolder,
            paddingTop: 20,
          }}
        >
          Minhas Redes
        </h3>
        <label style={{ color: COLORS.placeHolder }}>Site</label>
        <input
          type="text"
          value={site || ""}
          onChange={(e) => {
            let valorAtualizado = e.target.value.toLowerCase();

            if (!valorAtualizado.startsWith("https://")) {
              valorAtualizado = "https://" + valorAtualizado;
            }

            setSite(valorAtualizado);
          }}
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: `${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        <label style={{ color: COLORS.placeHolder }}>Whatsapp</label>
        <MaskedInput
          mask={[
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          value={
            (whatsapp && whatsapp.replace(/^https:\/\/wa\.me\/\+55/, "")) || ""
          }
          onChange={(e) => {
            let onlyNumbers = e.target.value.replace(/[^0-9]/g, "");

            // Atualiza o valor do WhatsApp com o link correto, sem adicionar o "0"
            setWhatsapp(`https://wa.me/+55${onlyNumbers}`);
          }}
          placeholder="31912345678"
          className="masked-input"
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: `${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        <label style={{ color: COLORS.placeHolder }}>Instagram</label>
        <input
          type="text"
          value={instagram || ""}
          onChange={(e) => {
            let valorAtualizado = e.target.value.toLowerCase();

            if (!valorAtualizado.startsWith("https://")) {
              valorAtualizado = "https://" + valorAtualizado;
            }

            setInstagram(valorAtualizado);
          }}
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: `${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        <label style={{ color: COLORS.placeHolder }}>Youtube</label>
        <input
          type="text"
          value={youtube || ""}
          onChange={(e) => {
            let valorAtualizado = e.target.value.toLowerCase();

            if (!valorAtualizado.startsWith("https://")) {
              valorAtualizado = "https://" + valorAtualizado;
            }

            setYoutube(valorAtualizado);
          }}
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: `${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        <label style={{ color: COLORS.placeHolder }}>Facebook</label>
        <input
          type="text"
          value={facebook || ""}
          onChange={(e) => {
            let valorAtualizado = e.target.value.toLowerCase();

            if (!valorAtualizado.startsWith("https://")) {
              valorAtualizado = "https://" + valorAtualizado;
            }

            setFacebook(valorAtualizado);
          }}
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: `${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        <h3
          style={{
            color: COLORS.primaryText,
            marginBottom: 20,
            marginTop: 20,
            borderTop: "1px solid",
            borderTopColor: COLORS.placeHolder,
            paddingTop: 20,
          }}
        >
          Categorias
        </h3>
        {/* <label
          style={{
            color: COLORS.placeHolder,
          }}
        >
          Categorias
        </label> */}
        <div
          className="categorias-list-PERFIL-ESTABELECIMENTO"
          style={{ borderColor: COLORS.secondary }}
        >
          <ul>
            {categorias.map((categoria) => (
              <li key={categoria.id} style={{ margin: "10px 0" }}>
                <input
                  type="checkbox"
                  className="chkPerfilEstab"
                  checked={categoriasSelected.some(
                    (selectedCat) => selectedCat.id === categoria.id
                  )}
                  onChange={(e) => {
                    if (e.target.checked) {
                      // console.log([...categoriasSelected, categoria])
                      // Se a caixa de seleção estiver marcada, adicione a categoria às categorias selecionadas
                      setCategoriasSelected([...categoriasSelected, categoria]);
                    } else {
                      // console.log(categoriasSelected.filter((selectedCat) => selectedCat.id !== categoria.id))
                      // Se a caixa de seleção estiver desmarcada, remova a categoria das categorias selecionadas
                      setCategoriasSelected(
                        categoriasSelected.filter(
                          (selectedCat) => selectedCat.id !== categoria.id
                        )
                      );
                    }
                  }}
                />
                <strong style={{ color: COLORS.placeHolder }}>
                  {categoria.nome}
                </strong>
              </li>
            ))}
          </ul>
        </div>
        <button
          type="submit"
          className="submit-button"
          disabled={carregando ? true : false}
          style={{ backgroundColor: COLORS.button }}
        >
          {carregando ? <span className="loader"></span> : "Salvar Perfil"}
        </button>
      </form>
    </div>
  );
}

export default PerfilEstabelecimento;
