import { useEffect, useState } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import HomePage from "../pages/Home/HomePage";
import LoginEstabelecimento from "../pages/SignIn/LoginEstabelecimento";
import LoginColaborador from "../pages/SignIn/LoginColaborador";
import SignupColaborador from "../pages/SignUp/SingupColaborador";
import SignupEstabelecimento from "../pages/SignUp/SingupEstabelecimento";
import EsqueceuSenha from "../pages/SignIn/EsqueceSenha";
import ResetSenha from "../pages/SignIn/ResetSenha";
import RedirectSenhaAppPage from "../pages/SignIn/RedirectSenhaApp";
import LoginAfiliado from "../pages/SignIn/LoginAfiliado";
import SignupAfiliado from "../pages/SignUp/SingupAfiliado";
import TelaVotacao from "../pages/Votacao";
import DeleteAccountScreen from "../pages/Delete_account";
import PrivacyPolicyPage from "../pages/Privacy_policy";
import LoginADM from "../pages/SignIn/LoginADM";
import LoginCliente from "../pages/SignIn/LoginCliente";
import SignupCliente from "../pages/SignUp/SingupCliente";
import Home from "../pages/Home/Home";
import NotFoundPage from "../pages/NotFound/NotFoundPage";
import AgendamentoCliente from "../pages/Agendamento/AgendamentoCliente";
import PerfilScreen from "../pages/Perfil_cliente/PerfilScreen";

const AuthRoutes = () => {
  const { isLogado, userType } = useAuth();

  return (
    <Routes>
      <Route path="/" element={<HomePage />}>
        <Route index element={<Home />} />
        {isLogado && userType === "cliente" && (
          <Route path="/meus-agendamentos" element={<AgendamentoCliente/>} />
        )}
        {isLogado && userType === "cliente" && (
          <Route path="/perfil-cliente" element={<PerfilScreen />} />
        )}
      </Route>

      {!isLogado || userType !== 'cliente' ? (
        <>
          <Route path="/login/adm_facilite" element={<LoginADM />} />
          <Route path="/login/estabelecimento" element={<LoginEstabelecimento />} />
          <Route path="/login/colaborador" element={<LoginColaborador />} />
          <Route path="/login/afiliado" element={<LoginAfiliado />} />
          <Route path="/login/cliente" element={<LoginCliente />} />
          <Route path="/signup/estabelecimento" element={<SignupEstabelecimento />} />
          <Route path="/signup/colaborador" element={<SignupColaborador />} />
          <Route path="/signup/afiliado" element={<SignupAfiliado />} />
          <Route path="/signup/cliente" element={<SignupCliente />} />
          <Route path="/forgot-password" element={<EsqueceuSenha />} />
          <Route path="/reset-password" element={<ResetSenha />} />
          <Route path="/redirect-password/app" element={<RedirectSenhaAppPage />} />
        </>
      ) : (
        <Route path="*" element={<Navigate to="/" />} />

      )}
      {/* {
        isLogado  && userType === 'cliente' ? navigate("/") : (
          <>
          <Route path="/login/adm_facilite" element={<LoginADM />} />

          <Route path="/login/estabelecimento" element={<LoginEstabelecimento />} />

          <Route path="/login/colaborador" element={<LoginColaborador />} />

          <Route path="/login/afiliado" element={<LoginAfiliado />} />
     
          <Route path="/login/cliente" element={<LoginCliente />} />
        
          <Route
            path="/signup/estabelecimento"
            element={<SignupEstabelecimento />}
            />
      <Route path="/signup/colaborador" element={<SignupColaborador />} />
      <Route path="/signup/afiliado" element={<SignupAfiliado />} />
      <Route path="/signup/cliente" element={<SignupCliente />} />

      <Route path="/forgot-password" element={<EsqueceuSenha />} />
      <Route path="/reset-password" element={<ResetSenha />} />
      <Route path="/redirect-password/app" element={<RedirectSenhaAppPage />} />
            </>
        )
      } */}

      <Route path="/votacao" element={<TelaVotacao />} />
      <Route path="/delete-account" element={<DeleteAccountScreen />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AuthRoutes;
