import React from 'react';

// Função para converter o nome de inglês para português
const traduzirNome = (nomeEmIngles) => {
  const traducoes = {
    'payment': 'Pagamento',
    'sale': 'Venda',
    'completed': 'Pago',
    'created': 'Criado',
    'expired': 'Expirado',
    'pending': 'Pendente',
    'declined': 'Recusado',
    'finalizado': 'Finalizado',
    'alterado': 'Alterado',
    'canceled': 'Cancelado',
    // Adicione mais traduções conforme necessário
  };

  const palavraMinuscula = nomeEmIngles.toLowerCase();
  return traducoes[palavraMinuscula] || nomeEmIngles;

};

const TraduzirStatusPagamento = (nomeEmIngles) => {
  // console.log(nomeEmIngles)
  // Usa a função de tradução para converter o nome
  const nomeTraduzido = traduzirNome(nomeEmIngles);

  return nomeTraduzido
};

export default TraduzirStatusPagamento;
