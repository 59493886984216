// src/components/ImageGallery.js
import React, { useState } from 'react';
import ImageModal from './ImageModal';
import { CONSTANTES } from '../../global/CONSTANTES';

const ImageGallery = ({ images, estabelecimento_id }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery">
      {images.map((image, index) => (
        <div className="gallery-item" key={index} onClick={() => openModal(image)}>
          <img src={`${CONSTANTES.uri}${CONSTANTES.caminhoImageEstabelecimentos}${estabelecimento_id}${CONSTANTES.galeria}${image.foto}`} alt={`Galeria de imagem ${index}`} />
        </div>
      ))}
      {selectedImage && <ImageModal images={images} selectedImage={selectedImage} onClose={closeModal} estabelecimento_id={estabelecimento_id} />}
    </div>
  );
};

export default ImageGallery;
