import { faShop, faUser, faUserTie, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { COLORS } from "../../global/STYLE";

// import { Container } from './styles';

const MembrosHome = () => {
    const { dadosHome } = useAuth();

  return (
    <>
      <div className="plans-header" style={{color:COLORS.primaryText}}>
        <h2 className="titlePlan">Membros da Plataforma</h2>
        <p>Conectando Pessoas e Negócios: Uma Comunidade em Crescimento.</p>
      </div>

      <section className="quadroAcessosGrid">
        <div className="quadro" style={{backgroundColor:COLORS.bgButtonCard, borderColor:COLORS.separator}}>
          <h2 style={{color:COLORS.primaryText}}>Estabelecimentos</h2>
          <FontAwesomeIcon icon={faShop} size="5x" color={COLORS.placeHolder} />
          <div className="quantidade" style={{color:COLORS.button}}>{dadosHome.estabelecimentos}</div>
        </div>

        <div className="quadro" style={{backgroundColor:COLORS.bgButtonCard, borderColor:COLORS.separator}}>
          <h2 style={{color:COLORS.primaryText}}>Colaboradores</h2>
          <FontAwesomeIcon icon={faUserTie} size="5x" color={COLORS.placeHolder} />
          <div className="quantidade" style={{color:COLORS.button}}>{dadosHome.colaboradores}</div>
        </div>

        <div className="quadro" style={{backgroundColor:COLORS.bgButtonCard, borderColor:COLORS.separator}}>
          <h2 style={{color:COLORS.primaryText}}>Clientes</h2>
          <FontAwesomeIcon icon={faUser} size="5x" color={COLORS.placeHolder} />
          <div className="quantidade" style={{color:COLORS.button}}>{dadosHome.clientes}</div>
        </div>

        <div className="quadro" style={{backgroundColor:COLORS.bgButtonCard, borderColor:COLORS.separator}}>
          <h2 style={{color:COLORS.primaryText}}>Usuários</h2>
          <FontAwesomeIcon icon={faUsers} size="5x" color={COLORS.placeHolder} />
          <div className="quantidade" style={{color:COLORS.button}}>{dadosHome.usuarios}</div>
        </div>
      </section>
    </>
  );
};

export default MembrosHome;
