import React from "react";
const NotFoundPage = () => {
  return (
    <div style={{color:'white', justifyContent:'center', display:'flex', alignItems:'center', flexDirection:'column', width:'100%', height:'100vh'}}>
      <h1>Página não encontrada</h1>
      <p>Desculpe, a página que você está procurando não existe.</p>
    </div>
  );
};

export default NotFoundPage;
