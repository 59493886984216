import React, { useEffect, useState, useMemo } from "react";
import "./styles.css";
import estadosData from "../../data/Estados.json";
import cidadesData from "../../data/Cidades.json";
import { categoriaService } from "../../services/categoria";
import { toast } from "react-toastify";

const SearchBar = ({ onSearch }) => {
  const [localEstados, setLocalEstados] = useState(estadosData);
  const [localCidades, setLocalCidades] = useState(cidadesData);
  const [categorias, setCategorias] = useState([]);
  const [name, setName] = useState("");
  const [stars, setStars] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [category, setCategory] = useState("");
  const [showFilters, setShowFilters] = useState(false);

  const handleSearch = () => {
    let categoria_id = categorias.find(item => item.nome === category);
    let nomeCidade = localCidades.find(item => item.ID == city);
    let nomeEstado = localEstados.find(item => item.ID == state);

    onSearch({ name, stars, nomeCidade, nomeEstado, categoria_id });
  };

  const pegaCategorias = async () => {
    const response = await categoriaService.categoriaAll();
    if (response.error) {
      toast.error(response.message);
    } else if (Array.isArray(response.data)) {
      setCategorias(response.data);
    } else {
      toast.error("Erro ao carregar categorias");
    }
  };

  useEffect(() => {
    pegaCategorias();
  }, []);

  const filteredCities = useMemo(() => {
    return state ? localCidades.filter((cidade) => cidade.Estado === state) : localCidades;
  }, [state, localCidades]);

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setState(selectedState);
    setCity(""); // Resetar cidade ao mudar o estado
  };


  useEffect(() => {
    const handleScroll = () => {
      const searchBar = document.querySelector(".corpoSearchHome");
      const scrollTop = window.scrollY;
      const searchBarOffsetTop = searchBar.offsetTop;

      if (scrollTop > searchBarOffsetTop + 100) {
        setShowFilters(false);
      } else {
        
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="search-container">
      <div className="main-search">
        <input
          type="text"
          className="search-input-full"
          placeholder="Encontrar um estabelecimento"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <button className="search-button" onClick={handleSearch}>
          Buscar
        </button>
      </div>
      <button
        className="toggle-filters-button"
        onClick={() => setShowFilters(!showFilters)}
      >
        {showFilters ? "Ocultar Filtros" : "Mostrar Filtros"}
      </button>
      {showFilters && (
        <div className="filters-container">
          <select
            className="search-select"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">Categoria</option>
            {categorias.map((item, index) => (
              <option value={item.nome} key={index}>
                {item.nome}
              </option>
            ))}
          </select>

          <select
            className="search-select"
            value={state}
            onChange={handleStateChange}
          >
            <option value="">Estado</option>
            {localEstados.map((estado) => (
              <option value={estado.ID} key={estado.ID}>
                {estado.Nome}
              </option>
            ))}
          </select>

          <select
            className="search-select"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          >
            <option value="">Cidade</option>
            {filteredCities.map((cidade) => (
              <option value={cidade.ID} key={cidade.ID}>
                {cidade.Nome}
              </option>
            ))}
          </select>

          <select
            className="search-select"
            value={stars}
            onChange={(e) => setStars(e.target.value)}
          >
            <option value="">Estrelas</option>
            <option value="1">1 estrela</option>
            <option value="2">2 estrelas</option>
            <option value="3">3 estrelas</option>
            <option value="4">4 estrelas</option>
            <option value="5">5 estrelas</option>
          </select>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
