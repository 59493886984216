// TermosDeUsoModal.js

import React, { useState } from "react";
import Modal from "react-modal";
import "./TermosDeUsoModal.css";
import { COLORS } from "../../global/STYLE";

Modal.setAppElement("#root");

const TermosDeUsoModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <a href="#" onClick={openModal} style={{marginLeft:5, color:COLORS.primaryText}}>
        Termos de Uso
      </a>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Termos de Uso"
        className="termos-modal"
        overlayClassName="termos-modal-overlay"
      >
        <div className="termos-container">
          <h2>Termos de Uso</h2>

          <p>
            Bem-vindo aos Termos de Uso do Facilite. Ao acessar ou usar o Facilite, você concorda em cumprir estes termos e condições.
          </p>

          <h3>1. Aceitação dos Termos</h3>
          <p>
            Ao utilizar o Facilite, você concorda com estes Termos de Uso. Se você não concorda com esses termos, por favor, não use o aplicativo.
          </p>

          <h3>2. Uso do Aplicativo</h3>
          <p>
            Facilite concede a você uma licença limitada, não exclusiva, intransferível e revogável para usar o aplicativo de acordo com estes termos.
          </p>

          <h3>3. Conta do Usuário</h3>
          <p>
            Você é responsável por manter a segurança de sua conta e senha. Não compartilhe suas credenciais com terceiros.
          </p>

          <h3>4. Privacidade</h3>
          <p>
            Respeitamos sua privacidade. Consulte nossa Política de Privacidade para entender como tratamos suas informações.
          </p>

          <h3>5. Modificações</h3>
          <p>
            Podemos atualizar ou modificar estes Termos de Uso periodicamente. Fique atento a eventuais alterações.
          </p>

          <h3>6. Conduta do Usuário</h3>
          <p>
            Ao usar o Facilite, você concorda em não:
            <ul>
              <li>Violar qualquer lei ou regulamento;</li>
              <li>Fornecer informações falsas ou enganosas;</li>
              <li>Interferir na segurança do aplicativo;</li>
              <li>Enviar spam ou mensagens não solicitadas.</li>
            </ul>
          </p>

          <h3>7. Encerramento de Conta</h3>
          <p>
            Reservamo-nos o direito de encerrar contas de usuários que violarem estes Termos de Uso ou que estejam envolvidos em atividades prejudiciais ao aplicativo.
          </p>

          <button onClick={closeModal}>Fechar</button>
        </div>
      </Modal>
    </>
  );
};

export default TermosDeUsoModal;
