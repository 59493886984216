import Api from "../index";

const create = async(dados) => {
    const {data} = await Api.post('/estabelecimento', dados)
    return data
  }

const search = async(dados) => {
    const {data} = await Api.post('/estabelecimento/web/search', dados)
    return data
  }

const login = async (email, password) => {
  const dados = {
    email,
    password,
  };
  const { data } = await Api.post("/login/estabelecimento", dados);
  return data;
};

const validateToken = async ( token ) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer ' seguido do token
    },
  };
  const {data} = await Api.post(
    "/validateToken/estabelecimento",
    null,
    headerToken
  );
  return data;
};

const updateEstabelecimento = async (token, perfil) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.put(
    "/estabelecimento/update",
    perfil,
    headerToken
  );
  return data;
};

const dataEstabelecimento = async (token) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.get("/estabelecimento/data", headerToken);
  return data;
};

const pegaEstabelecimentoIDsemJWT = async (id, status) => {

  const { data } = await Api.get(`/estabelecimento/WEB/${id}/cID/${status}`);
  return data;
};

const dadosDashboard = async (token) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.get("/estabelecimento/dadosDashboard", headerToken);
  return data;
};

const mapsLatLong = async (endereco) => {
  // console.log("ENDERECO = ", endereco);

  // Construa a URL da API Nominatim
  const apiUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
    endereco
  )}`;

  try {
    // Use uma função assíncrona para aguardar a resposta do fetch
    const response = await fetch(apiUrl);

    // Verifique se a resposta está ok
    if (!response.ok) {
      throw new Error(`Erro na requisição: ${response.statusText}`);
    }

    // Agora você pode usar o await na função json() para obter os dados JSON
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Erro ao acessar a API Nominatim:", error);
    throw error; // Certifique-se de lançar o erro novamente para que ele seja tratado corretamente no código que chama esta função
  }
};

const changePhoto = async (file, token, estabelecimento_id) => {
  const MAX_RETRIES = 3;
  let attempts = 0;
  let success = false;
  // Crie um FormData e anexe a imagem e outros dados
  const formData = new FormData();

  formData.append("estabelecimento_id", estabelecimento_id.toString());
  formData.append("file", file);
  // console.log('FormData:', { cliente_id: cliente_id.toString(), file: { uri: uri.uri, type: uri.type, name: uri.fileName } });
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  while (attempts < MAX_RETRIES && !success) {
    try {
      const { data } = await Api.post(
        "/estabelecimento/mudarFoto",
        formData,
        config
      );
      return data;
    } catch (error) {
      attempts += 1;
      // console.log('Erro no upload da imagem:', error);
      // opcional: aguarde um pouco antes de tentar novamente
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }
};

const changePhotoBG = async (file, token, estabelecimento_id) => {
  const MAX_RETRIES = 3;
  let attempts = 0;
  let success = false;
  // Crie um FormData e anexe a imagem e outros dados
  const formData = new FormData();

  formData.append("estabelecimento_id", estabelecimento_id.toString());
  formData.append("file", file);
  // console.log('FormData:', { cliente_id: cliente_id.toString(), file: { uri: uri.uri, type: uri.type, name: uri.fileName } });
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  while (attempts < MAX_RETRIES && !success) {
    try {
      const { data } = await Api.post(
        "/estabelecimento/mudarFotoBG",
        formData,
        config
      );
      return data;
      return false;
    } catch (error) {
      attempts += 1;
      // console.log('Erro no upload da imagem:', error);
      // opcional: aguarde um pouco antes de tentar novamente
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }
};

const addFotoGaleria = async (file, token, estabelecimento_id) => {
  const MAX_RETRIES = 3;
  let attempts = 0;
  let success = false;
  // Crie um FormData e anexe a imagem e outros dados
  const formData = new FormData();

  formData.append("estabelecimento_id", estabelecimento_id.toString());
  formData.append("status", "desativado");
  formData.append("file", file);
  // console.log('FormData:', { cliente_id: cliente_id.toString(), file: { uri: uri.uri, type: uri.type, name: uri.fileName } });
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  while (attempts < MAX_RETRIES && !success) {
    try {
      const { data } = await Api.post(
        "/galeria/imagem",
        formData,
        config
      );
      return data;
      return false;
    } catch (error) {
      attempts += 1;
      // console.log('Erro no upload da imagem:', error);
      // opcional: aguarde um pouco antes de tentar novamente
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }
};

const dataGaleria = async (token) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.get("/galeria/estabelecimento", headerToken);
  return data;
};

const mudaStatusFotoGaleria = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.put("/galeria/estabelecimento/status", dados ,headerToken);
  return data;
};

const excluirFotoGaleria = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.post("/galeria/estabelecimento/foto/delete", dados ,headerToken);
  return data;
};

const addFotoOferta = async (file, token, estabelecimento_id) => {
  const MAX_RETRIES = 3;
  let attempts = 0;
  let success = false;
  // Crie um FormData e anexe a imagem e outros dados
  const formData = new FormData();

  formData.append("estabelecimento_id", estabelecimento_id.toString());
  formData.append("status", "desativado");
  formData.append("file", file);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  while (attempts < MAX_RETRIES && !success) {
    try {
      const { data } = await Api.post(
        "/oferta/imagem",
        formData,
        config
      );
      return data;
      return false;
    } catch (error) {
      attempts += 1;
      // console.log('Erro no upload da imagem:', error);
      // opcional: aguarde um pouco antes de tentar novamente
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }
};

const dataOferta = async (token) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.get("/oferta/estabelecimento", headerToken);
  return data;
};

const mudaStatusFotoOferta = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.put("/oferta/estabelecimento/status", dados ,headerToken);
  return data;
};

const excluirFotoOferta = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.post("/oferta/estabelecimento/foto/delete", dados ,headerToken);
  return data;
};

const deleteAccount = async (dados) => {
  const { data } = await Api.post(
    `/estabelecimento/deleteAccount`,
    dados
  );
  return data;
};

export const estabelecimentoService = {
  create,
  login,
  changePhoto,
  changePhotoBG,
  dataEstabelecimento,
  mapsLatLong,
  updateEstabelecimento,
  validateToken,
  dadosDashboard,
  addFotoGaleria,
  dataGaleria,
  mudaStatusFotoGaleria,
  excluirFotoGaleria,
  addFotoOferta,
  dataOferta,
  mudaStatusFotoOferta,
  excluirFotoOferta,
  search,
  pegaEstabelecimentoIDsemJWT,
  deleteAccount,
};
