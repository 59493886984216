import Api from "../index";

const cadServicoEstabelecimento = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.post("/servico", dados, headerToken);
  return data;
};

const atualizaServicoEstabelecimento = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.post(
    "/servico/estabelecimento/atualiza",
    dados,
    headerToken
  );
  return data;
};

const pegaServicoEstabelecimento = async (token, status) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.get(
    `/servico/estabelecimento/${status}`,
    headerToken
  );
  return data;
};

const vinculaServicoAoColaboradorEstabelecimento = async (token, colaborador_id, servicos) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.post(
    `/servico/colaborador`,
    {colaborador_id, servicos},
    headerToken
  );
  return data;
};


const pegaServicoPorColaboradorEEstabelecimento = async (token, estabelecimento_id) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.get(`/servico/colaborador/estabelecimento/${estabelecimento_id}`, headerToken);
  return data;
};

const pegaServicoPorColaboradorEEstabelecimento_PeloEstabelecimento = async (token, colaborador_id) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.get(`/servico/colaborador/estabelecimento/byEstab/${colaborador_id}`, headerToken);
  return data;
};

const deletaServico = async (token, servico_id) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.get(`/servico/delete/${servico_id}`, headerToken);
  return data;
};

export const servicoService = {
  cadServicoEstabelecimento,
  pegaServicoEstabelecimento,
  atualizaServicoEstabelecimento,
  vinculaServicoAoColaboradorEstabelecimento,
  pegaServicoPorColaboradorEEstabelecimento,
  pegaServicoPorColaboradorEEstabelecimento_PeloEstabelecimento,
  deletaServico,
};
