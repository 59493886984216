// routes/estabelecimento.routes.js
import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../pages/ADMIN_Colaborador/components/Dashboard";

import PrincipalC from "../pages/ADMIN_Colaborador/components/PrincipalC";
import PerfilColaborador from "../pages/ADMIN_Colaborador/Perfil";
import HorarioDisponivel from "../pages/ADMIN_Colaborador/HorarioDisponivel";
import AgendamentoColaboradorPage from "../pages/ADMIN_Colaborador/Agendamentos/AgendamentoPage";

const ColaboradorRoutes = () => {

    return (
      <Routes>
        <Route path="/adminC" element={<Dashboard />}>
          <Route index element={<PrincipalC />} />
          <Route path="PerfilColaborador" element={<PerfilColaborador/>}/>
          <Route path="HorarioDisponivel" element={<HorarioDisponivel/>}/>
          <Route path="AgendamentoColaborador" element={<AgendamentoColaboradorPage/>}/>
        </Route>
        <Route path="*" element={<Navigate to="/adminC/" />}/>
      </Routes>
    );
 
};

export default ColaboradorRoutes;
