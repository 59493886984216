import React from "react";
import { useAuth } from "../contexts/AuthContext";
import EstabelecimentoRoutes from "./estabelecimento.routes";
import ColaboradorRoutes from "./colaborador.routes";
import AuthRoutes from "./auth.routes";
import ADMRoutes from "./adm.routes";
// import { toast } from 'react-toastify';

const Routes = () => {
  const { userType, isLogado } = useAuth();

  // toast.info(userType)
  if (!isLogado) {
    return <AuthRoutes />;
  }

  switch (userType) {
    case "adm_facilite":
      return <ADMRoutes />;
    case "estabelecimento":
      return <EstabelecimentoRoutes />;
    case "colaborador":
      return <ColaboradorRoutes />;
    case "afiliado":
      // return <AfiliadoRoutes />;
      return <AuthRoutes />;
    default:
      return <AuthRoutes />;
  }
};

export default Routes;
