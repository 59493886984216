import React, { Children } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import Badge from './Badge'

function ToolTip({id,text, style, tooltip}) {
  return (
    <>
      <a
       href="#" 
       onClick={(e) => e.preventDefault()}
        data-tooltip-id={id}
        data-tooltip-content={tooltip}
        style={style}
      >
        <Badge texto={text}/>
       
      </a>
      
      <Tooltip id={id}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {Children}
        </div>
      </Tooltip>
    </>
  );
}

export default ToolTip;
