import { faCalendarAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Modal from "react-modal";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { toast } from "react-toastify";
import MaskedInput from "react-text-mask";
import moment from "moment/moment";
import { useAuth } from "../../../contexts/AuthContext";
import { horarioService } from "../../../services/horario";
import { COLORS } from "../../../global/STYLE";
import { useNavigate } from "react-router-dom";

// import "./styles.css";

Modal.setAppElement("#root");

registerLocale("pt-BR", ptBR);
setDefaultLocale("pt-BR");

const CadHorarioModal = ({ servicoId, reload }) => {
  const { token, logout } = useAuth();
  const navigate = useNavigate()

  const [carregando, setCarregando] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [diaDaSemana, setDiaDaSemana] = useState("");
  const [horaInicio, setHoraInicio] = useState("");
  const [horaFim, setHoraFim] = useState("");
  const [inicioAlmoco, setinicioAlmoco] = useState("");
  const [fimAlmoco, setFimAlmoco] = useState("");

  const [selectedDate, setSelectedDate] = useState(null);

  const [feriadoChecked, setFeriadoChecked] = useState(false);
  const [repeteDiaChecked, setRepeteDiaChecked] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Funções para lidar com a mudança de estado dos checkboxes
  const handleFeriadoCheckboxChange = () => {
    setFeriadoChecked(!feriadoChecked);
  };

  const handleRepeteDiaCheckboxChange = () => {
    setRepeteDiaChecked(!repeteDiaChecked);
    setSelectedDate(null);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCarregando(true);

    if (feriadoChecked && !repeteDiaChecked) {
      if (!selectedDate) {
        toast.error("Para marcar como feriado, adicione uma data");
        setCarregando(false);
        return false;
      }
    } else if (!feriadoChecked && !repeteDiaChecked) {
      if (!selectedDate) {
        toast.error("Para marcar como dia avulso, adicione uma data");
        setCarregando(false);
        return false;
      }
    }

    // console.log("passou na verificação do feriado e se repete dia ou é avulso")

    const verificaHora = (a) => {
      if (a) {
        let valor = a.split("_").length;
        if (valor === 1) {
          return true;
        }
      }
      return false;
    };

    const verificaHoraSemRequire = (a) => {
      let valor = a.split("_").length;
      if (valor === 1) {
        return true;
      }

      setCarregando(false);
      return false;
    };

    if (!verificaHora(horaInicio) || !verificaHora(horaFim)) {
      // console.log("Não passaram")
      toast.error("Preencha as horas de início e fim.");
      setCarregando(false);
      return false;
    }

    // console.log("passou na verificação se existe hora inicio e fim")

    if (inicioAlmoco && fimAlmoco) {
      // console.log("tem em ambos", inicioAlmoco, fimAlmoco);
      if (
        !verificaHoraSemRequire(inicioAlmoco) ||
        !verificaHoraSemRequire(fimAlmoco)
      ) {
        // console.log("Não passaram")
        toast.error(
          "Preencha as horas de início e fim de almoço ou deixe ambos vazios."
        );
        setCarregando(false);
        return false;
      }
      // console.log("passou do verifica hora sem require")
      // return true;
    } else if (!inicioAlmoco && !fimAlmoco) {
      // console.log("não tem em ambos", inicioAlmoco, fimAlmoco)
      // return true;
    } else {
      // console.log("são diferentes", inicioAlmoco,fimAlmoco)
      toast.error(
        "Preencha as horas de início e fim de almoço ou deixe ambos vazios."
      );
      setCarregando(false);
      return false;
    }

    // console.log("passou das verificações")

    const dadosCreate = {
      servico_id: servicoId,
      dia_da_semana: diaDaSemana,
      hora_inicio: horaInicio,
      hora_fim: horaFim,
      inicio_almoco: inicioAlmoco,
      fim_almoco: fimAlmoco,
      data_avulso: selectedDate
        ? moment(selectedDate).format("YYYY-MM-DD")
        : null,
      repetir_data: repeteDiaChecked,
      feriado: feriadoChecked,
    };
    // console.log("passou até aqui")
    try {
      const data = await horarioService.cadHorarioDisponivelColaborador(
        token,
        dadosCreate
      );
      if (data.error) {
        if(data.reload){
          navigate("/login/colaborador")
          logout()  
        }
        toast.error(data.message);
      } else {
        toast.success(data.message);
        toast.info(data.sucessos);
        toast.error(data.falhas);
        setDiaDaSemana("");
        setHoraInicio("");
        setHoraFim("");
        setinicioAlmoco("");
        setFimAlmoco("");
        setSelectedDate(null);
        setFeriadoChecked(false);
        setRepeteDiaChecked(false);
        reload();
      }
    } catch (e) {
      // toast.error(e);
    }
    setCarregando(false);
  };

  return (
    <>
      <FontAwesomeIcon
        icon={faPlusCircle}
        color="rgba(0,200,100,0.8)"
        className="icon-plus-horas"
        onClick={() => openModal()}
      />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="CadHorarioColaborador"
        className="cad-horario-modal"
        overlayClassName="cad-horario-modal-overlay"
      >
        <div className="cad-horario-container">
          <button onClick={closeModal}>Fechar</button>
          <br />
          <br />

          <div style={{ marginBottom: "10px" }}>
            <form className="formulario-perfil" onSubmit={handleSubmit} style={{background:COLORS.cardBackground}}>
              <h3 style={{color:COLORS.primary, paddingBottom:10}}>Cadastrar um novo Horário</h3>
              <p style={{color:COLORS.primary}}>
                <small>
                  Essa área você pode cadastrar um novo horário de acordo com o
                  serviço que escolheu.
                </small>
              </p>

              <label style={{ margin: "10px 0 0 0" }}>
                <select
                  className="formulario-servico-select"
                  required
                  onChange={(e) => setDiaDaSemana(e.target.value)}
                  value={diaDaSemana}
                  style={{
                    border: `1px solid ${COLORS.secondary}`,
                    backgroundColor: ` ${COLORS.inputBackground}`,
                    color: COLORS.primaryText,
                  }}
                >
                  <option value="">Selecione um dia da semana</option>
                  <option value="DOM">Domingo</option>
                  <option value="SEG">Segunda-Feira</option>
                  <option value="TER">Terça-Feira</option>
                  <option value="QUA">Quarta-Feira</option>
                  <option value="QUI">Quinta-Feira</option>
                  <option value="SEX">Sexta-Feira</option>
                  <option value="SAB">Sábado</option>
                  <option value="SSe">Segunda a Sexta</option>
                  <option value="SSa">Segunda a Sábado</option>
                  <option value="SDo">Segunda a Domingo</option>
                </select>
              </label>

              <div className="duas-colunas-modal-cad-horario">
                <div className="modal-cad-horario-parte">
                  <label>
                    <p>
                      <strong style={{ color: COLORS.placeHolder }}>Hora Início</strong>
                    </p>
                    <MaskedInput
                      mask={[/\d/, /\d/, ":", /\d/, /\d/]}
                      className="form-control"
                      placeholder="HH:MM"
                      value={horaInicio}
                      onChange={(e) => setHoraInicio(e.target.value)}
                      required
                      style={{
                        border: `1px solid ${COLORS.secondary}`,
                        backgroundColor: ` ${COLORS.inputBackground}`,
                        color: COLORS.primaryText,
                      }}
                    />
                  </label>
                </div>
                <div className="modal-cad-horario-parte">
                  <label>
                    <p>
                      <strong style={{ color: COLORS.placeHolder }}>Hora Fim</strong>
                    </p>
                    <MaskedInput
                      mask={[/\d/, /\d/, ":", /\d/, /\d/]}
                      className="form-control"
                      placeholder="HH:MM"
                      value={horaFim}
                      onChange={(e) => setHoraFim(e.target.value)}
                      required
                      style={{
                        border: `1px solid ${COLORS.secondary}`,
                        backgroundColor: ` ${COLORS.inputBackground}`,
                        color: COLORS.primaryText,
                      }}
                    />
                  </label>
                </div>
              </div>

              <div className="duas-colunas-modal-cad-horario">
                <div className="modal-cad-horario-parte">
                  <label>
                    <p>
                      <strong style={{ color: COLORS.placeHolder }}>Início Almoço</strong>
                    </p>
                  </label>
                  <MaskedInput
                    mask={[/\d/, /\d/, ":", /\d/, /\d/]}
                    className="form-control"
                    placeholder="HH:MM"
                    value={inicioAlmoco}
                    onChange={(e) => setinicioAlmoco(e.target.value)}
                    style={{
                      border: `1px solid ${COLORS.secondary}`,
                      backgroundColor: ` ${COLORS.inputBackground}`,
                      color: COLORS.primaryText,
                    }}
                  />
                </div>
                <div className="modal-cad-horario-parte">
                  <label>
                    <p>
                      <strong style={{ color: COLORS.placeHolder }}>Fim Almoço</strong>
                    </p>
                    <MaskedInput
                      mask={[/\d/, /\d/, ":", /\d/, /\d/]}
                      className="form-control"
                      placeholder="HH:MM"
                      value={fimAlmoco}
                      onChange={(e) => setFimAlmoco(e.target.value)}
                      style={{
                        border: `1px solid ${COLORS.secondary}`,
                        backgroundColor: ` ${COLORS.inputBackground}`,
                        color: COLORS.primaryText,
                      }}
                    />
                  </label>
                </div>
              </div>

              <p>
                <small style={{ color: COLORS.placeHolder }}>
                  Clique no calendário abaixo para escolher uma data{" "}
                  <strong>AVULSA</strong> ou para algum <strong>FERIADO</strong>
                  .
                </small>
              </p>
              <label>
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="dd/MM/yyyy"
                  disabled={!feriadoChecked && repeteDiaChecked ? true : false}
                  required={!feriadoChecked && repeteDiaChecked ? true : false}
                  locale="pt-BR"
                  showIcon
                  toggleCalendarOnIconClick
                  icon={
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      color="white"
                      style={{
                        cursor:
                          !feriadoChecked && repeteDiaChecked
                            ? "not-allowed"
                            : "pointer",
                        padding: "10px",
                        backgroundColor: COLORS.inputBackground,
                        borderRadius: "4px",
                        borderColor: COLORS.placeHolder,
                      }}
                    />
                  }
                  minDate={new Date()}
                  // disabledKeyboardNavigation
                />
              </label>

              <br />

              <div className="duas-colunas-modal-cad-horario">
                <div className="modal-cad-horario-parte">
                  <label className="centraliza-cad-horario-label">
                    <strong className="rd" style={{ color: COLORS.placeHolder }}>Repetir dia</strong>
                    <input
                      type="checkbox"
                      checked={repeteDiaChecked}
                      onChange={handleRepeteDiaCheckboxChange}
                      style={{ marginLeft: "10px" }}
                      disabled={feriadoChecked ? true : false}
                    />
                  </label>
                </div>
                <div className="modal-cad-horario-parte">
                  <label className="centraliza-cad-horario-label">
                    <strong className="rd" style={{ color: COLORS.placeHolder }}>Feriado</strong>
                    <input
                      type="checkbox"
                      checked={feriadoChecked}
                      onChange={handleFeriadoCheckboxChange}
                      style={{ marginLeft: "10px" }}
                      disabled={repeteDiaChecked ? true : false}
                    />
                  </label>
                </div>
              </div>
              <br />
              <button
                style={{
                  display: "flex",
                  width: "100%",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor:COLORS.button
                }}
                type="submit"
              >
                {carregando ? (
                  <span className="loader"></span>
                ) : (
                  "Cadastrar | Atualziar"
                )}
              </button>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CadHorarioModal;
