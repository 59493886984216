import React, { useState, useEffect } from "react";
import "./ServicoLista.css"; // Certifique-se de atualizar o caminho se necessário
import { toast } from "react-toastify";
import { servicoService } from "../../../services/servico";
import { useAuth } from "../../../contexts/AuthContext";
import {
  converteParaSegundos,
  converteSegundos,
} from "../../../functions/converteSegundos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faCheck } from "@fortawesome/free-solid-svg-icons";
import MaskedInput from "react-text-mask";
import { COLORS } from "../../../global/STYLE";
import { useNavigate } from "react-router-dom";

function ServicosListaEstabelecimento() {
  const { token, logout } = useAuth();
  const navigate = useNavigate()

  const [servicos, setServicos] = useState([]);
  const [editando, setEditando] = useState({});
  const [carregando, setCarregando] = useState(false);

  const handleStatusChange = (id, e) => {
    // console.log(id, e.target.checked)
    handleServicoChange(id, "status", e.target.checked);
    // setStatus(e.target.checked);
  };

  const handlePrecoChange = (id, e) => {
    const valorFormatado = formatarValorParaReal(e.target.value);
    handleServicoChange(id, "preco", removerFormatacaoDeReal(valorFormatado));
  };

  const formatarValorParaReal = (valor) => {
    return valor
      .replace(/\D/g, "") // Remove tudo que não é dígito
      .replace(/(\d)(\d{2})$/, "$1,$2") // Coloca a vírgula
      .replace(/(?=(\d{3})+(\D))\B/g, "."); // Coloca os pontos
  };

  const removerFormatacaoDeReal = (valor) => {
    return valor.replace(/\./g, "").replace(",", ".");
  };

  function validarHorario(horario) {
    const partes = horario.split(":");
    let horas = parseInt(partes[0], 10);
    let minutos = parseInt(partes[1], 10);

    horas = horas > 99 ? 99 : horas;
    minutos = minutos > 59 ? 59 : minutos;

    return `${horas.toString().padStart(2, "0")}:${minutos
      .toString()
      .padStart(2, "0")}`;
  }

  function handleChange(id, e) {
    const novoValor = e.target.value;
    const horarioValidado = validarHorario(novoValor);
    const duracaoEmSegundos = converteParaSegundos(horarioValidado);
    handleServicoChange(id, "duracao", duracaoEmSegundos);
  }

  const pegaDadosServico = async () => {
    try {
      const data = await servicoService.pegaServicoEstabelecimento(
        token,
        "ativado"
      );
      if (data.error) {
        if(data.reload){
          navigate("/login/estabelecimento")
          logout()  
        }
        toast.error(data.message);
      } else {
        setServicos(data.data);
      }
    } catch (e) {
      // toast.error(e);
    }
  };

  const handleEditarClick = (id) => {
    setEditando((prev) => ({ ...prev, [id]: !prev[id] }));
    // setServicoEditado((prev) => ({
    //   ...prev,
    //   [id]: servicos.find((s) => s.id === id),
    // }));
  };

  // const handleCampoChange = (id, campo, valor) => {
  //   setServicoEditado((prev) => ({
  //     ...prev,
  //     [id]: { ...prev[id], [campo]: valor },
  //   }));
  // };

  const salvarEdicao = (id) => {
    setCarregando(true);
    const servico = servicos.map(async (servico) => {
      if (servico.id === id) {
        let duracaoFormatada =
          typeof servico.duracao === "number"
            ? converteSegundos(servico.duracao)
            : servico.duracao;

        const fatiaHorario = duracaoFormatada.split(":");
        let soma =
          Number(fatiaHorario[0]) * 3600 + Number(fatiaHorario[1]) * 60;
        if (isNaN(soma)) {
          soma = servico.duracao;
        }
        try {
          // console.log(soma)
          const dadosCreate = {
            servico_id: id,
            titulo: servico.titulo,
            descricao: servico.descricao,
            preco: servico.preco,
            comissao: servico.comissao,
            duracao: isNaN(soma) ? servico.duracao : soma,
            status: servico.status ? "ativado" : "desativado",
          };
          const data = await servicoService.atualizaServicoEstabelecimento(
            token,
            dadosCreate
          );
          if (data.error) {
            if(data.reload){
              navigate("/login/estabelecimento")
              logout()  
            }
            toast.error(data.message);
          } else {
            setEditando((prev) => ({ ...prev, [id]: !prev[id] }));
            toast.success(data.message);
          }
        } catch (e) {
          // toast.error(e);
        }
      }
    });

    // const fatiaHorario = servicos.duracao.split(":")
    //   const soma = (Number(fatiaHorario[0] * 60 * 60) + Number(fatiaHorario[1] * 60))
    //   // console.log(soma)
    setCarregando(false);
  };

  // const handleServicoChange = (id, campo, novoValor) => {
  //   setServicos(
  //     servicos.map((servico) => {
  //       if (servico.id === id) {
  //         return { ...servico, [campo]: novoValor };
  //       }
  //       return servico;
  //     })
  //   );
  // };

  const handleServicoChange = (id, campo, novoValor) => {
    // console.log(id, campo, novoValor)
    const novosServicos = servicos.map((servico) => {
      if (servico.id === id) {
        return { ...servico, [campo]: novoValor };
      }
      return servico;
    });
    // console.log((novosServicos))
    setServicos(novosServicos);
    // console.log('Serviços Atualizados:', novosServicos);
  };

  const handleDelete = async (id, titulo) => {
    const confirmacao = window.confirm(`Você deseja realmente cancelar o serviço de ${titulo}?`);
    if(confirmacao){
      // toast.success(`Envio de exclusão do id = ${id}`)
      try{
        const data = await servicoService.deletaServico(token, id)
        if (data.error) {
          if(data.reload){
            navigate("/login/estabelecimento")
            logout()  
          }
          toast.error(data.message);
        } else {
          setServicos((prevDados) =>
            prevDados.filter((item) => item.id !== id)
          );
          toast.success(data.message);
        }
      }catch(e){

      }
    }
  }

  useEffect(() => {
    pegaDadosServico();
  }, []);
  // console.log(editando);
  return (
    <div>
      <h3 style={{ marginBottom: 20, color: COLORS.primaryText }}>
        Listas de serviços
      </h3>

      <span
        className="descricao"
        style={{ backgroundColor: COLORS.bgButtonCard, marginTop: 10 }}
      >
        <p style={{ color: COLORS.primaryText }}>
          Listagem dos serviços cadastrados.
        </p>
        {/* <p>Para adicionar um serviço basta escolher um e a caixinha ficará selecionada.</p>
      <p>Para remover um serviço basta escolher um e a caixinha perderá a seleção.</p> */}
      </span>

      <hr style={{ marginBottom: "20px" }} />

      <div className="grid-container">
        {servicos.map((servico, index) => (
          <form
            onSubmit={salvarEdicao}
            className="formulario-servico"
            key={index}
            style={{ backgroundColor: COLORS.cardBackground }}
          >
            {editando[servico.id] ? (
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: 10,
                  right: 10,
                }}
                onClick={() => (carregando ? {} : salvarEdicao(servico.id))}
                color={COLORS.primary}
              />
            ) : (
              <FontAwesomeIcon
                icon={faEdit}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: 10,
                  right: 10,
                }}
                onClick={() => handleEditarClick(servico.id)}
                color={COLORS.primary}
              />
            )}

            <label style={{ color: COLORS.placeHolder }}>Título</label>
            <input
              type="text"
              value={servico.titulo}
              onChange={(e) =>
                handleServicoChange(servico.id, "titulo", e.target.value)
              }
              required
              disabled={!editando[servico.id]}
              style={{
                border: `1px solid ${COLORS.secondary}`,
                backgroundColor: ` ${COLORS.inputBackground}`,
                color: COLORS.primaryText,
              }}
            />

            <label style={{ color: COLORS.placeHolder }}>Descrição</label>
            <textarea
              value={servico.descricao}
              onChange={(e) =>
                handleServicoChange(servico.id, "descricao", e.target.value)
              }
              required
              disabled={!editando[servico.id]}
              style={{
                border: `1px solid ${COLORS.secondary}`,
                backgroundColor: ` ${COLORS.inputBackground}`,
                color: COLORS.primaryText,
              }}
            />
            <label style={{ color: COLORS.placeHolder }}>Preço (R$)</label>
            <input
              type="text"
              value={formatarValorParaReal(servico.preco)}
              onChange={(e) => handlePrecoChange(servico.id, e)}
              required
              disabled={!editando[servico.id]}
              style={{
                border: `1px solid ${COLORS.secondary}`,
                backgroundColor: ` ${COLORS.inputBackground}`,
                color: COLORS.primaryText,
              }}
            />

            <label style={{ color: COLORS.placeHolder }}>Comissão (%)</label>
            <input
              type="number"
              min="0"
              max="100"
              value={servico.comissao}
              onChange={(e) =>
                handleServicoChange(servico.id, "comissao", e.target.value)
              }
              disabled={!editando[servico.id]}
              style={{
                border: `1px solid ${COLORS.secondary}`,
                backgroundColor: ` ${COLORS.inputBackground}`,
                color: COLORS.primaryText,
              }}
            />

            <label style={{ color: COLORS.placeHolder }}>
              Duração do serviço (HH:MM)
            </label>
            <MaskedInput
              mask={[/\d/, /\d/, ":", /\d/, /\d/]}
              type="text"
              className="form-control"
              placeholder="HH:MM"
              value={converteSegundos(servico.duracao)}
              onChange={(e) => handleChange(servico.id, e)}
              required
              disabled={!editando[servico.id]}
              style={{
                border: `1px solid ${COLORS.secondary}`,
                backgroundColor: ` ${COLORS.inputBackground}`,
                color: COLORS.primaryText,
              }}
            />

            <div className="switch-container-lstServ" style={{margin:"20px 0 0 0"}}>
              <label style={{ color: COLORS.placeHolder }}>
                Status:
                </label>
                <div className="switch-lstServ">
                  <input
                    type="checkbox"
                    id={`status-switch-${servico.id}`}
                    checked={servico.status === "ativado" ? true : false}
                    onChange={(e) => handleStatusChange(servico.id, e)}
                    // onChange={console.log(servico.id)}
                    disabled={!editando[servico.id]}
                  />
                  <label
                    htmlFor={`status-switch-${servico.id}`}
                    className="slider-lstServ round"
                  ></label>
                </div>
            </div>

            <div style={{width:"100%", backgroundColor:'rgba(0,0,0,0.2)', textAlign:'center', padding:'10px 0', color:COLORS.primaryText, cursor:'pointer', marginTop:15, borderRadius:5, fontSize:'0.90rem'}} onClick={()=>handleDelete(servico.id, servico.titulo)}>
              Excluir Serviço
            </div>
          </form>
        ))}
      </div>
    </div>
  );
}

export default ServicosListaEstabelecimento    
