import axios from 'axios';
// import { CONSTANTE } from '../global/constantes';
// console.log(process.env.REACT_APP_API_URI)
const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
  // baseURL: 'http://192.168.18.85:3005',
  responseType: 'json',
  withCredentials: true,
});

// console.log(process.env.REACT_APP_API_URI)

export default Api;
