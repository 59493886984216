import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCalendarCheck,
  // faChartLine,
  faCogs,
  faBusinessTime,
  faBank,
  faUsers,
  faShop,
  faUserTie,
  faHandshake,
  faRankingStar,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../contexts/AuthContext";
// import {
//   ResponsiveContainer,
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from "recharts";

import "../../ADMIN_Estabelecimento/components/PrincipalE.css"; // Certifique-se de criar este arquivo CSS
// import NewsFeed from "./NewsFeed";
import { toast } from "react-toastify";
import { planoService } from "../../../services/plano";
import moment from "moment";
import { COLORS } from "../../../global/STYLE";
import { useNavigate } from "react-router-dom";
import { formatarValorReal } from "../../../functions/formatarValorReal";

const PrincipalADM = () => {
  const { user, token, logout, dadosHome } = useAuth();
  const navigate = useNavigate();

  const [dadosDashboardPlano, setDadosDashboardPlano] = useState({});

  const dataDashboardPlano = async () => {
    try {
      const data = await planoService.pegaPlanosDashBoardADM(token);
      if (data.error) {
        if (data.reload) {
          navigate("/login/adm_facilite");
          logout();
        }
        toast.error(data.message);
      } else {
        // toast.success(String(data.data))
        console.log(data.data);
        setDadosDashboardPlano(data.data);
      }
    } catch (e) {
      // toast.error(e);
    }
  };

  useEffect(() => {
    dataDashboardPlano();
  }, []);

  return (
    <div
      className="principalE-container"
      style={{ backgroundColor: COLORS.bgButtonCard }}
    >
      {/* Cards */}
      <h2 style={{ color: "white", marginBottom: -30, marginTop: 30 }}>
        Contas
      </h2>
      <div className="cards-container">
        <div
          className="card"
          style={{
            backgroundColor: COLORS.cardBackground,
            borderColor: COLORS.separator,
          }}
        >
          <FontAwesomeIcon icon={faShop} size="2x" color={COLORS.placeHolder} />
          <h3 style={{ color: COLORS.primaryText }}>Total Estabelecimentos</h3>
          <p style={{ color: COLORS.button }}>
            {dadosHome.estabelecimentos || 0}
          </p>
        </div>

        <div
          className="card"
          style={{
            backgroundColor: COLORS.cardBackground,
            borderColor: COLORS.separator,
          }}
        >
          <FontAwesomeIcon
            icon={faUserTie}
            size="2x"
            color={COLORS.placeHolder}
          />
          <h3 style={{ color: COLORS.primaryText }}>Total Colaboradores</h3>
          <p style={{ color: COLORS.button }}>{dadosHome.colaboradores || 0}</p>
        </div>

        <div
          className="card"
          style={{
            backgroundColor: COLORS.cardBackground,
            borderColor: COLORS.separator,
          }}
        >
          <FontAwesomeIcon icon={faUser} size="2x" color={COLORS.placeHolder} />
          <h3 style={{ color: COLORS.primaryText }}>Total Clientes</h3>
          <p style={{ color: COLORS.button }}>{dadosHome.clientes || 0}</p>
        </div>

        <div
          className="card"
          style={{
            backgroundColor: COLORS.cardBackground,
            borderColor: COLORS.separator,
          }}
        >
          <FontAwesomeIcon
            icon={faHandshake}
            size="2x"
            color={COLORS.placeHolder}
          />
          <h3 style={{ color: COLORS.primaryText }}>Total Afiliados</h3>
          <p style={{ color: COLORS.button }}>{dadosHome.afiliados || 0}</p>
        </div>

        <div
          className="card"
          style={{
            backgroundColor: COLORS.cardBackground,
            borderColor: COLORS.separator,
          }}
        >
          <FontAwesomeIcon
            icon={faUsers}
            size="2x"
            color={COLORS.placeHolder}
          />
          <h3 style={{ color: COLORS.primaryText }}>Total Contas</h3>
          <p style={{ color: COLORS.button }}>
            {dadosHome.usuariosComAfiliados || 0}
          </p>
        </div>
      </div>

      <hr />
      <h2 style={{ color: "white", marginBottom: -30, marginTop: 10 }}>
        Planos
      </h2>
      <div className="cards-container">
        <div
          className="card"
          style={{
            backgroundColor: COLORS.cardBackground,
            borderColor: COLORS.separator,
          }}
        >
          <FontAwesomeIcon
            icon={faRankingStar}
            size="2x"
            color={COLORS.placeHolder}
          />
          <h3 style={{ color: COLORS.primaryText }}>Bronze</h3>
          <p style={{ color: COLORS.button }}>
            {dadosDashboardPlano.bronze || 0}
          </p>
        </div>

        <div
          className="card"
          style={{
            backgroundColor: COLORS.cardBackground,
            borderColor: COLORS.separator,
          }}
        >
          <FontAwesomeIcon
            icon={faRankingStar}
            size="2x"
            color={COLORS.placeHolder}
          />
          <h3 style={{ color: COLORS.primaryText }}>Prata</h3>
          <p style={{ color: COLORS.button }}>
            {dadosDashboardPlano.prata || 0}
          </p>
        </div>

        <div
          className="card"
          style={{
            backgroundColor: COLORS.cardBackground,
            borderColor: COLORS.separator,
          }}
        >
          <FontAwesomeIcon
            icon={faRankingStar}
            size="2x"
            color={COLORS.placeHolder}
          />
          <h3 style={{ color: COLORS.primaryText }}>Ouro</h3>
          <p style={{ color: COLORS.button }}>
            {dadosDashboardPlano.ouro || 0}
          </p>
        </div>

        <div
          className="card"
          style={{
            backgroundColor: COLORS.cardBackground,
            borderColor: COLORS.separator,
          }}
        >
          <FontAwesomeIcon
            icon={faRankingStar}
            size="2x"
            color={COLORS.placeHolder}
          />
          <h3 style={{ color: COLORS.primaryText }}>Diamante</h3>
          <p style={{ color: COLORS.button }}>
            {dadosDashboardPlano.diamante || 0}
          </p>
        </div>

        <div
          className="card"
          style={{
            backgroundColor: COLORS.cardBackground,
            borderColor: COLORS.separator,
          }}
        >
          <FontAwesomeIcon
            icon={faRankingStar}
            size="2x"
            color={COLORS.placeHolder}
          />
          <h3 style={{ color: COLORS.primaryText }}>Vitalício</h3>
          <p style={{ color: COLORS.button }}>
            {dadosDashboardPlano.vitalicio || 0}
          </p>
        </div>
      </div>

      {/* Seção de Novidades e Gráficos */}
      <div className="novidades-graficos-container">
        {/* <section className="novidades">
          <NewsFeed />
        </section> */}
      </div>
    </div>
  );
};

export default PrincipalADM;
