import React from "react";

const Badge = ({texto} ) => {
  return (
    <div
      style={{
        cursor: "pointer",
        padding: "3px 8px", // Reduzindo o espaçamento interno para torná-lo mais compacto
        borderRadius: "50%",
        backgroundColor: "red",
        fontSize: "10px", // Reduzindo o tamanho da fonte para torná-lo menor
        minWidth: "15px", // Tamanho mínimo para evitar que o badge fique muito pequeno
        textAlign: "center",
        marginLeft:'5px'
      }}
    >
      {texto}
    </div>
  );
};

export default Badge;
