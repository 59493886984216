import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faYoutube,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faGlobe, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import "./ShareButton.css"; // Certifique-se de ter o arquivo CSS para animações
import { COLORS } from "../../global/STYLE"; // Corrija o caminho para o arquivo de estilo

const ShareButton = ({ estabelecimento }) => {
  const [expanded, setExpanded] = useState(false);
  const [socialLinks, setSocialLinks] = useState({
    facebook: "",
    instagram: "",
    youtube: "",
    site: "",
    whatsapp: "",
  });
  const [cor, setCor] = useState(false);

  useEffect(() => {
    if (estabelecimento) {
      // Atualize os links sociais e a cor com base em `estabelecimento`
      const fundo = estabelecimento.foto_bg;
      //   console.log(fundo ?'tem fundo' : 'não tem fundo')
      setCor(fundo ? true : false); // Atualiza a cor com base na presença de `fundo`

      setSocialLinks({
        facebook: estabelecimento.facebook || "",
        instagram: estabelecimento.instagram || "",
        youtube: estabelecimento.youtube || "",
        site: estabelecimento.site || "",
        whatsapp: estabelecimento.whatsapp || "",
      });
    }
  }, [estabelecimento]); // Atualiza quando `estabelecimento` mudar

  const toggleExpand = () => {
    setExpanded((prevState) => !prevState);
  };

  const hasSocialLinks = Object.values(socialLinks).some((link) => link !== "");

  if (!hasSocialLinks) {
    return null;
  }

  return (
    <div className="share-button-wrapper">
      {cor}
      <button
        className={`share-button ${expanded ? "expanded" : ""}`}
        onClick={toggleExpand}
      >
        <FontAwesomeIcon icon={faShareAlt} size="lg" />
      </button>
      <div className={`share-options ${expanded ? "show" : ""}`}>
        {socialLinks.site && (
          <a
            href={socialLinks.site}
            className="share-option"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faGlobe}
              size="2x"
              style={{ color: cor ? COLORS.primary : COLORS.cardBackground }}
            />
          </a>
        )}

        {socialLinks.whatsapp && (
          <a
            href={socialLinks.whatsapp}
            className="share-option"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faWhatsapp}
              size="2x"
              style={{ color: cor ? COLORS.primary : COLORS.cardBackground }}
            />
          </a>
        )}

        {socialLinks.instagram && (
          <a
            href={socialLinks.instagram}
            className="share-option"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              size="2x"
              style={{ color: cor ? COLORS.primary : COLORS.cardBackground }}
            />
          </a>
        )}

        {socialLinks.youtube && (
          <a
            href={socialLinks.youtube}
            className="share-option"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faYoutube}
              size="2x"
              style={{ color: cor ? COLORS.primary : COLORS.cardBackground }}
            />
          </a>
        )}
        {socialLinks.facebook && (
          <a
            href={socialLinks.facebook}
            className="share-option"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faFacebookF}
              size="2x"
              style={{ color: cor ? COLORS.primary : COLORS.cardBackground }}
            />
          </a>
        )}
      </div>
    </div>
  );
};

export default ShareButton;
