import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCalendarCheck,
  faCogs,
  faBusinessTime,
  faBank,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../contexts/AuthContext";

import "./PrincipalE.css"; // Certifique-se de criar este arquivo CSS
import NewsFeed from "./NewsFeed";
import { toast } from "react-toastify";
import { estabelecimentoService } from "../../../services/estabelecimento";
import moment from "moment";
import { COLORS } from "../../../global/STYLE";
import { useNavigate } from "react-router-dom";
import { formatarValorReal } from "../../../functions/formatarValorReal";
import ToolTip from "./ToolTip";

const PrincipalE = () => {
  const { user, token, logout } = useAuth();
  const navigate = useNavigate();

  const [dadosDashboard, setDadosDashboard] = useState([]);

  const dataDashboard = async () => {
    try {
      const data = await estabelecimentoService.dadosDashboard(token);
      if (data.error) {
        if (data.reload) {
          navigate("/login/estabelecimento");
          logout();
        }
        toast.error(data.message);
      } else {
        // toast.success(String(data.data))
        // console.log(data.data);
        setDadosDashboard(data.data);
      }
    } catch (e) {
      // toast.error(e);
    }
  };

  useEffect(() => {
    dataDashboard();
  }, []);

  return (
    <div
      className="principalE-container"
      style={{ backgroundColor: COLORS.bgButtonCard }}
    >
      {/* Cards */}
      <div className="cards-container">
        <div
          className="card"
          style={{
            backgroundColor: COLORS.cardBackground,
            borderColor: COLORS.separator,
          }}
        >
          <FontAwesomeIcon icon={faUser} size="2x" color={COLORS.placeHolder} />
          <h3 style={{ color: COLORS.primaryText }}>Total Colaboradores</h3>
          <p style={{ color: COLORS.button }}>
            {user.estabelecimentoColaborador
              ? Object.keys(user.estabelecimentoColaborador).length
              : 0}
          </p>
        </div>
        <div
          className="card"
          style={{
            backgroundColor: COLORS.cardBackground,
            borderColor: COLORS.separator,
          }}
        >
          <FontAwesomeIcon
            icon={faCalendarCheck}
            size="2x"
            color={COLORS.placeHolder}
          />
          <h3 style={{ color: COLORS.primaryText }}>Agendamentos Hoje</h3>
          <p style={{ color: COLORS.button }}>
            {dadosDashboard.qtdAgendamentoHoje}
          </p>
        </div>
        <div
          className="card"
          style={{
            backgroundColor: COLORS.cardBackground,
            borderColor: COLORS.separator,
          }}
        >
          <FontAwesomeIcon
            icon={faBusinessTime}
            size="2x"
            color={COLORS.placeHolder}
          />
          <h3 style={{ color: COLORS.primaryText }}>Plano Atual</h3>
          {dadosDashboard.vitalicio ? (
            <h4 style={{ color: COLORS.button }}>ILIMITADO</h4>
          ) : (
            <>
              <h4 style={{ color: COLORS.button }}>
                {dadosDashboard.plano ? dadosDashboard.plano : "Sem Plano"}
              </h4>
              {dadosDashboard.vencimento ? (
                <h5 style={{ color: COLORS.toastWarning }}>
                  Vencimento:{" "}
                  {`${moment(dadosDashboard.vencimento).format("DD/MM/YYYY")}`}
                </h5>
              ) : (
                ""
              )}
            </>
          )}
        </div>

        <div
          className="card"
          style={{
            backgroundColor: COLORS.cardBackground,
            borderColor: COLORS.separator,
          }}
        >
          <FontAwesomeIcon icon={faCogs} size="2x" color={COLORS.placeHolder} />
          <h3 style={{ color: COLORS.primaryText }}>Status do Serviço </h3>
          <h4
            style={{
              color:
                user.status && user.status === "ativado"
                  ? COLORS.concluido
                  : COLORS.cancelado,
              marginBottom: "5px",
            }}
          >
            {user.status && user.status.toUpperCase()}
          </h4>
        </div>

        <div
          className="card"
          style={{
            backgroundColor: COLORS.cardBackground,
            borderColor: COLORS.separator,
          }}
        >
          <FontAwesomeIcon icon={faBank} size="2x" color={COLORS.placeHolder} />
          <h3 style={{ color: COLORS.primaryText, justifyContent:"center", alignItems:'center', textAlign:'center', display:'flex'}}>
            Saldo 
            <ToolTip
              id={"saldoTooltip"}
              text={"?"}
              tooltip={"O seu saldo positivo pode ser descontado no valor do seu plano ao renovar ou alterar!"}
              style={{color:'white', textDecoration:'none'}}
            />
          </h3>
          <h4
            style={{
              color: dadosDashboard.saldo ? COLORS.concluido : COLORS.cancelado,
              marginBottom: "5px",
            }}
          >
            {formatarValorReal(dadosDashboard.saldo ? dadosDashboard.saldo : 0)}
          </h4>
        </div>
      </div>

      {/* Seção de Novidades e Gráficos */}
      <div className="novidades-graficos-container">
        <section className="novidades">
          <NewsFeed />
        </section>
      </div>
    </div>
  );
};

export default PrincipalE;
