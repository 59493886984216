import { toast } from "react-toastify";

export function calcularPorcentagem(valorParcial, valorTotal) {
  // Converte as strings para números usando parseFloat
  const parcialNumerico = parseFloat(valorParcial);
  const totalNumerico = parseFloat(valorTotal);

  if (isNaN(parcialNumerico) || isNaN(totalNumerico)) {
    // Trate o caso em que a conversão não é bem-sucedida
    toast.error("Valores inválidos");
    return null;
  }

  return (parcialNumerico / totalNumerico) * 100;
}

export function calcularDesconto(porcentagem, valorTotal) {
  // Converte as strings para números usando parseFloat
  const porcentagemlNumerico = parseFloat(porcentagem);
  const totalNumerico = parseFloat(valorTotal);

  if (isNaN(porcentagemlNumerico) || isNaN(totalNumerico)) {
    // Trate o caso em que a conversão não é bem-sucedida
    toast.error("Valores inválidos");
    return null;
  }

  return (totalNumerico * porcentagemlNumerico) / 100;
}
