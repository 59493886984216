// signUpEstabelecimento.js

import React, { useEffect, useState } from "react";
import "./Singup.css";
import { toast } from "react-toastify";
import MaskedInput from "react-text-mask";
import { useNavigate } from "react-router-dom";
import { CONSTANTES } from "../../global/CONSTANTES";
import TermosDeUsoModal from "./TermosDeUsoModal";
import { colaboradorService } from "../../services/colaborador";
import { COLORS } from "../../global/STYLE";

const SignupColaborador = () => {
  let navigate = useNavigate();

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [telefone, setTelefone] = useState("");

  const [aceitouTermos, setAceitouTermos] = useState(false);
  const [carregando, setCarregando] = useState(false);

  const handleAceitarTermos = () => {
    setAceitouTermos(!aceitouTermos);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleNomeChange = (e) => {
    setNome(e.target.value);
  };

  const handleTelefoneChange = (e) => {
    setTelefone(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCarregando(true);

    if (!aceitouTermos) {
      setCarregando(false)
      return toast.error(
        "Para cadastrar, primeiro precisa aceitar os termos de uso!"
      );
    }
    try {
      const dadosCreate = {
        nome,
        email,
        password,
        telefone,
        termosDeUso: aceitouTermos,
      };

      const data = await colaboradorService.create(dadosCreate);
      if (data.error) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        setNome("");
        setEmail("");
        setPassword("");
        setTelefone("");
        setAceitouTermos(false);
        navigate("/login/colaborador");
      }
    } catch (e) {
      toast.error(e);
    }
    setCarregando(false);
  };

  return (
    <div
      className="bodySignup"
      style={{ backgroundColor: COLORS.background, margin: 0 }}
    >
      <div className="signUp-estabelecimento-container">
        <div className="logo-container">
          <img
            src={`/${CONSTANTES.logoImage}`}
            alt={CONSTANTES.logo}
            title={CONSTANTES.logo}
            width={200}
            height={75}
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          />{" "}
          {/* <img src="caminho/para/sua/logo.png" alt={CONSTANTES.logo} /> */}
        </div>
        <form onSubmit={handleSubmit} className="signUp-estabelecimento-form">
          <label htmlFor="nome" style={{ color: COLORS.placeHolder }}>
            Nome ou apelido
          </label>
          <input
            type="text"
            id="nome"
            value={nome}
            onChange={handleNomeChange}
            required
            style={{
              border: `1px solid ${COLORS.secondary}`,
              backgroundColor: ` ${COLORS.inputBackground}`,
              color: COLORS.primaryText,
            }}
          />

          <label htmlFor="email" style={{ color: COLORS.placeHolder }}>
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            required
            style={{
              border: `1px solid ${COLORS.secondary}`,
              backgroundColor: ` ${COLORS.inputBackground}`,
              color: COLORS.primaryText,
            }}
          />

          <label htmlFor="password" style={{ color: COLORS.placeHolder }}>
            Senha
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            required
            style={{
              border: `1px solid ${COLORS.secondary}`,
              backgroundColor: ` ${COLORS.inputBackground}`,
              color: COLORS.primaryText,
            }}
          />

          <label style={{ color: COLORS.placeHolder }}>Telefone</label>
          <MaskedInput
            mask={[
              "(",
              /[1-9]/,
              /\d/,
              ")",
              " ",
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            value={telefone || ""}
            onChange={(e) => setTelefone(e.target.value)}
            placeholder="(31) 9 4567-8901"
            className="masked-input"
            required
            style={{
              border: `1px solid ${COLORS.secondary}`,
              backgroundColor: ` ${COLORS.inputBackground}`,
              color: COLORS.primaryText,
            }}
          />

          <div className="termos-de-uso-container">
            <label
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <input
                type="checkbox"
                checked={aceitouTermos}
                onChange={handleAceitarTermos}
                // disabled={!aceitouTermos}
                style={{
                  margin: "0 10px 0 0 ",
                  padding: 0,
                  display: "flex",
                  width: "20px",
                }}
              />
              <p style={{ color: COLORS.button }}>Eu aceito os</p>{" "}
              <TermosDeUsoModal />.
            </label>
          </div>

          <div className="form-actions">
            <button
              type="submit"
              style={{
                backgroundColor: COLORS.button,
                color: COLORS.primaryText,
              }}
              disabled={carregando ? true : false}
            >
              {carregando ? <span className="loader"></span> : "Cadastrar"}
            </button>
            <span
              onClick={() => {
                navigate("/login/colaborador");
              }}
              style={{ color: COLORS.button }}
            >
              Já possui cadastro
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupColaborador;
