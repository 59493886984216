import Api from "../index";

const getDados = async (token, page) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, 
    },
  };
  const { data } = await Api.get(`/pagamento/lista/${page}`, headerToken);
  return data;
};

const getDadoCompleted = async (token) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, 
    },
  };
  const { data } = await Api.get(`/pagamento/unico`, headerToken);
  return data;
};

const createOrder = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, 
    },
  };
  const { data } = await Api.post(`/create-order`,dados, headerToken);
  return data;
};

const createOrderUpgradePlano = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, 
    },
  };
  const { data } = await Api.post(`/create-order/upgrade`,dados, headerToken);
  return data;
};




export const pagamentoService = {
  getDados,
  createOrder,
  getDadoCompleted,
  createOrderUpgradePlano,
};
