import React, { useCallback, useEffect, useState } from "react";
import "./Pagamento.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRepeat, faUpload } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import { pagamentoService } from "../../../services/pagamento";
import moment from "moment/moment";
import { formatarValorReal } from "../../../functions/formatarValorReal";
import RenovarPlanoModal from "./RenovarPlanoModal";
import UpgradePlanoModal from "./UpgradePlanoModal";
import CountdownTimer from "./CountdownTimer";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../../global/STYLE";
import TraduzirStatusPagamento from "../../../functions/TraduzirStatusPagamento";
import Planos from "../../Home/Planos";

function ListaPagamento() {
  const { token, logout } = useAuth();
  let navigate = useNavigate();

  const [planos, setPlanos] = useState([]);
  const [plano, setPlano] = useState({});
  const [modalRenovarOpen, setModalRenovarOpen] = useState(false);
  const [modalUpgradeOpen, setModalUpgradeOpen] = useState(false);
  const [evento, setEvento] = useState(null);
  const [tipoEvento, setTipoEvento] = useState("");
  const [dataValidade, setDataValidade] = useState("");
  const [statusVencimento, setStatusVencimento] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handleEventClick = useCallback((type, event) => {
    if (type === "R") {
      setModalRenovarOpen(true);
      setEvento(event);
      setTipoEvento("Renovar Plano");
    } else if (type === "U") {
      setModalUpgradeOpen(true);
      setEvento(event);
      setTipoEvento("Atualizar Plano");
    }
  }, []);

  const handleAction = (st) => {
    setStatusVencimento(st);
  };

  const pegaDados = useCallback(async () => {
    try {
      const data = await pagamentoService.getDados(token, page);
      if (data.error) {
        toast.error(data.message);
        if (data.reload) {
          navigate("/login/estabelecimento");
          logout();
        }
      } else {
        // console.log(data.data)
        setPlanos(data.data);
        setTotalPages(data.totalPages);
        const dado = await pagamentoService.getDadoCompleted(token);
        // console.log(dado)
        if (dado.error) {
          setDataValidade(new Date());
        } else {
          if (dado.data.renovar) {
            setDataValidade(new Date());
          } else {
            setDataValidade(dado.data.fim_ativacao);
            setPlano(dado.data);
          }
        }
      }
    } catch (e) {
      // toast.error(e);
    }
  }, [page, token]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setPage(pageNumber); // Adicionado para manter a consistência entre currentPage e page
  };

  useEffect(() => {
    pegaDados();
  }, [pegaDados]);

  useEffect(() => {
    // Obtenha a URL completa
    const url = window.location.href;

    // Use uma biblioteca ou uma lógica personalizada para analisar a URL e obter o parâmetro desejado
    const params = new URL(url).searchParams;
    const parametroDaUrl = params.get("pagamento");

    // Faça algo com o parâmetro da URL
    if (parametroDaUrl) {
      toast.info(parametroDaUrl);
    }
  }, [navigate]);

  return (
    <div className="financial-table-container">
      <h3 style={{ marginBottom: 15, color: COLORS.primaryText }}>Pagamento</h3>
      <span
        className="descricao"
        style={{ backgroundColor: COLORS.bgButtonCard, marginTop: 10 }}
      >
        {/* <p style={{ color: COLORS.primaryText }}>
          Para não ter problema, use o mesmo email que você cadastrou no
          pagamento.
        </p> */}
        <p style={{ color: COLORS.primaryText }}>
          Faltando uma semana para vencer o plano, nós liberaremos o botão de
          renovar.
        </p>
        <p style={{ color: COLORS.primaryText }}>
          Método único de pagamento via: <b>Paypal</b>
        </p>
        <p style={{ color: COLORS.primaryText }}>
          Os pedidos criados e não pagos em até 3 dias serão removidas automaticamente pois saem do prazo de pagamento de até 3 dias estipulados pelo cartão.
        </p>
        <p></p>
      </span>

      <button
        style={{
          padding: 10,
          height: "40px",
          width: "130px",
          backgroundColor: "#069",
          textAlign: "center",
          color: "white",
          fontWeight: "bold",
          borderRadius: 5,
          marginRight: 20,
        }}
        // onClick={() => handleEventClick("U", planos[0])}
        onClick={() => handleEventClick("U", plano)}
      >
        Alterar plano
      </button>

      {statusVencimento && (
        <button
          style={{
            padding: 10,
            height: "40px",
            width: "130px",
            backgroundColor: "#069",
            textAlign: "center",
            color: "white",
            fontWeight: "bold",
            borderRadius: 5,
          }}
          onClick={() => handleEventClick("R", plano)}
          // onClick={() => handleEventClick("R", planos[0])}
        >
          Renovar plano
        </button>
      )}
      {/* <button>{""}</button> */}

      <div
        style={{
          width: "100%",
          display: "flex",
          margin: "20px 0",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <CountdownTimer
          targetDate={"2024-02-14 20:41:30"}
          acao={handleAction}
        /> */}
        <CountdownTimer targetDate={dataValidade} acao={handleAction} />
      </div>

      <div className="content-tabela-pagamento">
        <table
          className="financial-table"
          style={{
            backgroundColor: COLORS.cardBackground,
            color: COLORS.primaryText,
          }}
        >
          <thead>
            <tr>
              <th style={{ backgroundColor: COLORS.inputBackground }}>
                Status
              </th>
              <th style={{ backgroundColor: COLORS.inputBackground }}>
                Data Solicitação
              </th>
              <th style={{ backgroundColor: COLORS.inputBackground }}>
                Data Ativação
              </th>
              <th style={{ backgroundColor: COLORS.inputBackground }}>Plano</th>
              <th style={{ backgroundColor: COLORS.inputBackground }}>Tipo</th>
              <th style={{ backgroundColor: COLORS.inputBackground }}>
                Descrição
              </th>
              <th style={{ backgroundColor: COLORS.inputBackground }}>Preço</th>
              <th style={{ backgroundColor: COLORS.inputBackground }}>
                Validade
              </th>
            </tr>
          </thead>
          <tbody>
            {planos.map((plan, index) => (
              <tr key={index}>
                <td>{TraduzirStatusPagamento(plan.status)}</td>
                <td>{moment(plan.createdAt).format("DD/MM/YYYY")}</td>
                <td>
                  {plan?.inicio_ativacao
                    ? moment(plan.inicio_ativacao).format("DD/MM/YYYY")
                    : "-"}
                </td>
                <td>{plan.plano}</td>
                <td>{plan.tipo}</td>
                <td>{plan?.description ? plan.description : "-"}</td>
                <td>{formatarValorReal(plan.amount)}</td>
                <td>
                  {plan?.fim_ativacao
                    ? moment(plan.fim_ativacao).format("DD/MM/YYYY")
                    : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {totalPages > 1 && (
        <div className="pagination-pagamento">
          {[...Array(totalPages)].map((_, index) => (
            <button key={index} onClick={() => paginate(index + 1)}>
              {index + 1}
            </button>
          ))}
        </div>
      )}

      {tipoEvento === "Renovar Plano" && (
        <RenovarPlanoModal
          isOpen={modalRenovarOpen}
          onRequestClose={() => setModalRenovarOpen(false)}
          event={evento}
          tipoPlano={tipoEvento}
        />
      )}

      {tipoEvento === "Atualizar Plano" && (
        <UpgradePlanoModal
          isOpen={modalUpgradeOpen}
          onRequestClose={() => setModalUpgradeOpen(false)}
          event={evento}
          tipoPlano={tipoEvento}
          atualizarTabela={() => pegaDados()}
        />
      )}

      {/* <td
                style={{
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FontAwesomeIcon
                  icon={faRepeat}
                  style={{ cursor: "pointer" }}
                  title="Renovar Plano"
                  onClick={()=>handleEventClick('R',plan)}
                  />
                <FontAwesomeIcon
                  icon={faUpload}
                  style={{ cursor: "pointer" }}
                  title="Atualziar Plano"
                  onClick={()=>handleEventClick('U',plan)}
                />
              </td> */}
      <div style={{marginTop:150}}>
<hr style={{opacity:0.2, marginBottom:20}}/>
        <Planos />
      </div>
    </div>
  );
}

export default ListaPagamento;
