// VinculaServicos.js

import React, { useState, useCallback, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "./VinculaServico.css";
import { toast } from "react-toastify";
import { colaboradorService } from "../../../services/colaborador";
import { servicoService } from "../../../services/servico";
import { useAuth } from "../../../contexts/AuthContext";
import { CONSTANTES } from "../../../global/CONSTANTES";
import { COLORS } from "../../../global/STYLE";
import { useNavigate } from "react-router-dom";

function VinculaServicos() {
  const { token, logout } = useAuth();
  const navigate = useNavigate()

  const [colaboradores, setColaboradores] = useState([]);
  const [servicos, setServicos] = useState([]);

  // const servicosDisponiveis = [
  //   { id: 1, nome: "Serviço 1" },
  //   { id: 2, nome: "Serviço 2" },
  //   { id: 3, nome: "Serviço 3" },
  //   // Adicione mais serviços conforme necessário
  // ];

  const pegaDados = useCallback(async () => {
    try {
      const data = await colaboradorService.pegaColaboradoresEstabelecimento(
        token
      );
      if (data.error) {
        if(data.reload){
          navigate("/login/estabelecimento")
          logout()  
        }
        toast.error(data.message);
      } else {
        // toast.success(data.message);
        // console.log(data.data)
        setColaboradores(data.data);
      }
    } catch (e) {
      // toast.error(e);
    }
  }, []);

  const pegaDadosServico = useCallback(async () => {
    try {
      const data = await servicoService.pegaServicoEstabelecimento(
        token,
        "ativado"
      );
      if (data.error) {
        if(data.reload){
          navigate("/login/estabelecimento")
          logout()  
        }
        toast.error(data.message);
      } else {
        // toast.success(data.message);
        // console.log(data.data)
        setServicos(data.data);
      }
    } catch (e) {
      // toast.error(e);
    }
  }, []);

  const handleColaboradorClick = (index) => {
    const updatedColaboradores = [...colaboradores];
    // Toggle the state of services visibility directly
    updatedColaboradores[index].showServicos =
      !updatedColaboradores[index].showServicos;
    setColaboradores(updatedColaboradores);
  };

  const handleCheckboxChange = async (index, servicoId, col_id) => {
    // console.log("Chegada dos dados na função: ",index, servicoId, col_id);
    const updatedColaboradores = [...colaboradores];
    // console.log("updated dos colaboradores clone: ",updatedColaboradores)
    const isSelected = updatedColaboradores[index].servicos.includes(servicoId);
    // console.log("isSelected: ", isSelected)

    updatedColaboradores[index].servicos = isSelected
      ? updatedColaboradores[index].servicos.filter(
          (selectedService) => selectedService !== servicoId
        )
      : [...updatedColaboradores[index].servicos, servicoId];
    // console.log("updateColaboradores = ", updatedColaboradores[index].servicos);
    try {
      const data =
        await servicoService.vinculaServicoAoColaboradorEstabelecimento(
          token,
          col_id,
          servicoId
          // updatedColaboradores[index].servicos
        );
      if (data.error) {
        if(data.reload){
          navigate("/login/estabelecimento")
          logout()  
        }
        toast.error(data.message);
        // console.log(data.data)
      } else {
        // console.log(data.data)
        toast.success(data.message);

        setColaboradores(updatedColaboradores);
      }
    } catch (e) {
      // toast.error(e);
    }
  };

  useEffect(() => {
    pegaDadosServico();
    pegaDados();
  }, [pegaDados, pegaDadosServico]);

  return (
    <div className="vincula-servicos">
      <h3 style={{ color: COLORS.primaryText }}>Vicular Serviço a um ou  Colaboradores:</h3>
      <span
        className="descricao"
        style={{ backgroundColor: COLORS.bgButtonCard, marginTop: 10 }}
      >
        <p style={{ color: COLORS.primaryText }}>
          Adicione ou retire serviços para um determinado colaborador.
        </p>
        <p style={{ color: COLORS.primaryText }}>
          Para adicionar um serviço basta escolher um e a caixinha ficará
          selecionada.
        </p>
        <p style={{ color: COLORS.primaryText }}>
          Para remover um serviço basta escolher um e a caixinha perderá a
          seleção.
        </p>
      </span>

      <hr style={{ backgroundColor: COLORS.separator }} />
      <ul className="collaborators-list">
        {colaboradores.map((colaborador, index) => (
          <li key={colaborador.id}>
            <div className="colaborador-info">
              {colaborador.foto ? (
                <img
                  src={`${CONSTANTES.uri}${CONSTANTES.caminhoImageColaboradores}${colaborador.id}/${colaborador.foto}`}
                  className="avatar"
                  alt={colaborador.nome}
                />
              ) : (
                <img
                  src="https://via.placeholder.com/100"
                  alt="Avatar"
                  className="avatar"
                />
              )}
              <div className="info-direita">
                <div style={{ color: COLORS.primaryText }}>
                  {colaborador.nome}
                </div>
                <div style={{ color: COLORS.primaryText }}>
                  {colaborador.email}
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                className="services-toggle-button"
                onClick={() => handleColaboradorClick(index)}
                style={{
                  backgroundColor: COLORS.placeHolder,
                  padding: 5,
                  borderRadius: 5,
                }}
              >
                <span style={{ color: COLORS.secondaryText }}>Serviços </span>

                {colaborador.showServicos ? (
                  <FontAwesomeIcon icon={faAngleUp} />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} />
                )}
              </button>
            </div>
            {colaborador.showServicos && (
              <div
                className="services-list"
                style={{ borderColor: COLORS.secondary }}
              >
                <ul>
                  {servicos.map((servico) => (
                    <li key={servico.id}>
                      <label>
                        <input
                          type="checkbox"
                          className="chk-vincula-estabelecimento"
                          checked={colaborador.servicos.includes(servico.id)}
                          onChange={() =>
                            handleCheckboxChange(
                              index,
                              servico.id,
                              colaborador.id
                            )
                          }
                        />
                        <span style={{ color: COLORS.placeHolder }}>
                          {servico.titulo}
                        </span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default VinculaServicos;
