// NewsItem.js
import React, { useState } from 'react';
import '../../ADMIN_Estabelecimento/components/News.css';
import moment from 'moment';
import { COLORS } from '../../../global/STYLE';

const NewsItem = ({ news }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className={`news-item ${isDropdownOpen ? 'open' : ''}`} onClick={toggleDropdown}>
      <div className="news-header">
        <h3 style={{color:COLORS.secondaryText}}>{news.titulo}</h3>
        <div className="date-arrow-container">
          <p className="date" style={{color:COLORS.bgButtonCard}}>Data: {moment(news.created_at).format('DD/MM/YYYY')}</p>
          <div className="arrow-divider"></div>
          <span className={`dropdown-arrow ${isDropdownOpen ? 'open' : ''}`} style={{color:COLORS.cardBackground}}>&#9660;</span>
        </div>
      </div>
      {isDropdownOpen && (
        <div className="news-details">
          <p>{news.conteudo}</p>
          <div className="divider"></div>
        </div>
      )}
    </div>
  );
};

export default NewsItem;
