// import React from 'react';
import moment from 'moment';

const converteData = (inputDate) => {
    // console.log(inputDate)
// Analise a data de entrada no formato original
const parsedDate = moment(inputDate, 'DD/MM/YYYY, HH:mm:ss');
// const parsedDate = moment(inputDate, 'YYYY-MM-DD HH:mm:ss');

// Formate a data no novo formato desejado
const formattedDate = parsedDate.format('DD/MM/YYYY [às] HH:mm');

return formattedDate;
}

export default converteData;