import React, { useState, useEffect } from "react";
import "./styles.css"; 
import { toast } from "react-toastify";
import { useAuth } from "../../../contexts/AuthContext";
import { COLORS } from "../../../global/STYLE";
import { useNavigate } from "react-router-dom";

import ServicoPlano from "./ServicoPlano";
import {planoService} from '../../../services/plano'
import ModalPlano from "./ModaPlano";

const ListaPlano =  () => {
  const { token, logout } = useAuth();
  const navigate = useNavigate();

  const [plano, setPlano] = useState([])

  // console.log(plano)

  
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const addPlano = (dado) => {
    setPlano([...plano, dado]);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const pegaDados = async () => {
    try{
      const data = await planoService.pegaPlanosADM(token)
      if (data.error) {
        toast.error(data.message);
        if(data.reload){
          navigate("/login/adm_facilite")
          logout()  
        }
      } else {
        setPlano(data.data)
      }
    }catch(e){

    }
  }

  useEffect(()=>{
    pegaDados()
  },[])


  const editarInformacao = (info) => {
    console.log('Editar informação:', info);
  };

  const excluirInformacao = (info) => {
    console.log('Excluir informação:', info);
  };

  return (
    <div style={{ paddingBottom: 30 }}>
      <h3 style={{ color: COLORS.primaryText }}>Lista de Planos</h3>
      <span
        className="descricao"
        style={{ backgroundColor: COLORS.bgButtonCard, marginTop: 10 }}
      >
        <p style={{ color: COLORS.primaryText }}>Lista de planos.</p>
      </span>

      <hr style={{ marginBottom: "10px" }} />

      <div
        className="form-lstCol-estab"
        style={{ backgroundColor: COLORS.cardBackground }}
      >

        <label>
          <button
            className="btnVincular"
            onClick={openModal}
            style={{
              backgroundColor: COLORS.button,
              borderColor: COLORS.secondary,
              padding: 10,
            }}
          >
            Criar um novo plano
          </button>
        </label>
      </div>

      <div>
      <ModalPlano  isOpen={modalIsOpen} onRequestClose={closeModal} addPlano={addPlano}/>
      </div>

      <br />

      <div>
      <ServicoPlano plano={plano}/>
    </div>

    </div>
  );
};

export default ListaPlano;
