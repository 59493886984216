import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import "./styles.css";
import { useEffect } from "react";
import { estabelecimentoService } from "../../../services/estabelecimento";
import { useAuth } from "../../../contexts/AuthContext";

import "react-quill/dist/quill.snow.css"; // Importar os estilos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faTrash } from "@fortawesome/free-solid-svg-icons";
import { CONSTANTES } from "../../../global/CONSTANTES";
import { COLORS } from "../../../global/STYLE";
import { useNavigate } from "react-router-dom";

function GaleriaEstabelecimento() {
  const { user, token, changeData , logout} = useAuth();
  const navigate = useNavigate()

  const [galeria, setGaleria] = useState([]);
  const [carregando, setCarregando] = useState(false);
  const [limit, setLimit] = useState(0);

  // const handleSubmit = async (e) => {
  //   setCarregando(true);

  //   e.preventDefault();
  //   try {
  //   } catch (e) {
  //     toast.error(e);
  //   }
  //   setCarregando(false);
  // };

  const handleStatusChange = async (id, e) => {

    const dadosCreate = {
      foto_id: id,
      status: e.target.checked,
    };
    let stt = dadosCreate.status
    // console.log(dadosCreate, stt)
    try {
      const data = await estabelecimentoService.mudaStatusFotoGaleria(
        token,
        dadosCreate
      );
      if (data.error) {
        if(data.reload){
          navigate("/login/estabelecimento")
          logout()  
        }
        toast.error(data.message);
      } else {
        handleGaleriaChange(id, "status", stt);
        toast.success(data.message)
      }
    } catch (e) {
      // toast.error(e);
    }
    // console.log(dadosCreate)
    // setStatus(e.target.checked);
  };

  const handleGaleriaChange = (id, campo, novoValor) => {
    // console.log("HANDLE = ",id, campo, novoValor)
    const nossaGaleria = galeria.map((imagens) => {
      if (imagens.id === id) {
        return { ...imagens, [campo]: novoValor ? "ativado" : "desativado"};
      }
      return imagens;
    });
    // console.log(nossaGaleria)
    setGaleria(nossaGaleria);

    // console.log('Serviços Atualizados:', nossaGaleria);
  };

  const handleImageUploadGaleria = useCallback(
    async (event) => {
      setCarregando(true);
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];

        const maxSize = 2 * 1024 * 1024; // 2MB em bytes

        if (file && file.size > maxSize) {
          toast.error('O tamanho do arquivo excede o limite de 2MB. Por favor, selecione um arquivo menor.')
          // alert('O tamanho do arquivo excede o limite de 2MB. Por favor, selecione um arquivo menor.');
          event.target.value = ''; // Limpa o valor do input file
          setCarregando(false);
          return false
        }

        let estabelecimento_id = user.id;
        try {
          const data = await estabelecimentoService.addFotoGaleria(
            file,
            token,
            estabelecimento_id
          );
          if (data.error) {
            if(data.reload){
              navigate("/login/estabelecimento")
              logout()  
            }
            toast.error(data.message);
          } else {
            changeData("foto", data.data.foto);
            pegaDadosGaleria();
            toast.success(data.message);
            // console.log(data.data);
          }
        } catch (e) {
          // console.error("Erro ao enviar imagem", e);
        }
      }
      setCarregando(false);
    },
    [user]
  );

  const pegaDadosGaleria = async () => {
    try {
      const data = await estabelecimentoService.dataGaleria(token);
      if (data.error) {
        if(data.reload){
          navigate("/login/estabelecimento")
          logout()  
        }
        toast.error(data.message);
      } else {
        // toast.success(data.message)
        // console.log(data.data, data.limit);
        setGaleria(data.data);
        // console.log("carrega dados do BD para LOCAL = ", data.data)
        setLimit(data.limit);
      }
    } catch (e) {
      // toast.error(e);
    }
  };

  const handleExcluirImagem = async (id) => {
    const confirmacao = window.confirm("Deseja realmente excluir a imagem?");
    
    if (confirmacao) {

      try{
        const dadosCreate = {
          foto_id: id
        }
        const data = await estabelecimentoService.excluirFotoGaleria(token, dadosCreate)
        if(data.error){
          if(data.reload){
            navigate("/login/estabelecimento")
            logout()  
          }
          toast.error(data.message)
        }else{
          toast.success(data.message)
          // Atualiza a lista de imagens (remove a imagem com o ID correspondente)
          const novaGaleria = galeria.filter((imagem) => imagem.id !== id);
          setGaleria(novaGaleria);

        }
      }catch(e){
        // toast.error(e)
      }


    }
  };

  useEffect(() => {
    pegaDadosGaleria();
  }, []);

  return (
    <div className="body-galeria-img">
      <h3 style={{ color: COLORS.primaryText }}>Galeria de Fotos</h3>
      <span className="descricao" style={{ backgroundColor: COLORS.bgButtonCard, marginTop: 10 }}>
        <p style={{ color: COLORS.primaryText }}>- Escolha imagens com formato JPG ou PNG para fazer upload.</p>
        <p style={{ color: COLORS.primaryText }}>- A largura e altura mais apropriada para a oferta é de 512 x 512 px (pixel).</p>
        <p style={{ color: COLORS.primaryText }}>- O tamanho permitido para a imagem é até 2MB (megabytes).</p>
        <p style={{ color: COLORS.primaryText }}>
          - Fique atento com a quantidade de fotos que podem ficar ativas de
          acordo com o plano contratado.
        </p>
        <p style={{ color: COLORS.primaryText }}>
          - Ao vencimento de cada plano, automaticamente algumas fotos na galeria
          ficam desativados.
        </p>
      </span>

      <form className="formulario-galeria-estabelecimento">
        <div style={{ alignItems: "center", display: "flex" }}>
          <strong style={{ marginRight: 10 }}>
            Clique no ícone ao lado para adicionar uma foto a galeria:
          </strong>
          {carregando ? (
            <span
              className="loader"
              style={{ width: 45, height: 45, cursor: "default" }}
            ></span>
          ) : (
            <label
              htmlFor="image-upload-galeria"
              className="edit-avatar"
              style={{ width: "auto" }}
            >
              <FontAwesomeIcon
                icon={faImage}
                size="2x"
                style={{ cursor: "pointer" }}
                color="#069"
              />
              <input
                type="file"
                id="image-upload-galeria"
                accept="image/*"
                onChange={handleImageUploadGaleria}
                alt="Adiciona imagem na galeria"
                title="Adiciona imagem na galeria"
                hidden
              />
            </label>
          )}
        </div>
      </form>

      <div
        className="grid-container-galeria-estabelecimento"
        style={{ marginTop: 20 }}
      >
        {galeria.map((gallery, index) => (
          <form
            // onSubmit={salvarEdicao}
            className="formulario-servico"
            key={index}
          >
            <FontAwesomeIcon
              icon={faTrash}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: 8,
                right: 10,
                padding:10,
                backgroundColor:'rgba(34,148,242,1)',
                borderRadius:20
              }}
              width={15}
              color="white"
              onClick={() => handleExcluirImagem(gallery.id)}
              // onClick={() => handleEditarClick(servico.id)}
            />

            <label  className="label-galeria-img">
              <img
                src={`${CONSTANTES.uri}${CONSTANTES.caminhoImageEstabelecimentos}${user.id}/galeria/${gallery.foto}`}
                alt="gallery.foto"
                // style={{ width: "300px", height: "230px" }}
                className="galeria-img-item"
              />
            </label>

            <div className="switch-container-lstGaleria">
              <label>
                Status:
                <div className="switch-lstGaleria">
                  <input
                    type="checkbox"
                    id={`status-switch-galeria-estabelecimento-${gallery.id}`}
                    checked={gallery.status === "desativado" ? false : gallery.status === "ativado" && true}
                    onChange={(e) => handleStatusChange(gallery.id, e)}
                    // disabled={!editando[gallery.id]}
                  />
                  <label
                    htmlFor={`status-switch-galeria-estabelecimento-${gallery.id}`}
                    className="slider-lstGaleria round"
                  ></label>
                </div>
              </label>
            </div>
          </form>
        ))}
      </div>
    </div>
  );
}

export default GaleriaEstabelecimento;
