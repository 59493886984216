import Api from "../index";


// const validateToken = async ({ token }) => {
//   const headerToken = {
//     headers: {
//       Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
//     },
//   };
//   const data = await Api.post(
//     "/validateToken/estabelecimento",
//     null,
//     headerToken
//   );
//   return data;
// };

const categoriaAll = async () => {
  
  const {data} = await Api.get(
    "/categoria",
  );
  return data;
};



export const categoriaService = {
  // create,
  categoriaAll
};
