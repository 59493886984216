// Dashboard.jsx
import React from 'react';
// import Navbar from './Navbar';
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import '../../ADMIN_Estabelecimento/components/Dashboard.css';
import { COLORS } from '../../../global/STYLE';

const Dashboard = () => {
  return (
    <div className="dashboard" style={{backgroundColor:COLORS.background}}>
      {/* <Navbar /> */}
      <div className="main-content">
        <Sidebar />
        <div className="content-area">
          <Outlet/>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
