// LoginCliente.js

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CONSTANTES } from "../../global/CONSTANTES";
import { useAuth } from "../../contexts/AuthContext";
import "./Login.css";
import { toast } from "react-toastify";
import { COLORS } from "../../global/STYLE";
import { clienteService } from "../../services/cliente";

const LoginCliente = () => {
  let navigate = useNavigate();
  const { user, login, logout } = useAuth();
  // console.log(user, login, logout)

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [carregando, setCarregando] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCarregando(true);
    // console.log(email, password)

    try {
      const data = await clienteService.login(email, password);
      if (data.error) {
        toast.error(data.message);
      } else {
        await login(data.data, data.userType, data.token);
        navigate("/");
      }
    } catch (e) {
      toast.error(e);
    }
    setCarregando(false);
  };

  return (
    <div className="bodyLogin" style={{ backgroundColor: COLORS.background }}>
      <div className="login-estabelecimento-container">
        <div className="logo-container">
          <img
            src={`/${CONSTANTES.logoImage}`}
            alt={CONSTANTES.logo}
            title={CONSTANTES.logo}
            width={200}
            height={75}
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          />{" "}
          {/* <img src="caminho/para/sua/logo.png" alt={CONSTANTES.logo} /> */}
        </div>
        
        <form onSubmit={handleSubmit} className="login-estabelecimento-form">
          <label htmlFor="email" style={{ color: COLORS.placeHolder }}>
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            required
            style={{
              border: `1px solid ${COLORS.secondary}`,
              backgroundColor: ` ${COLORS.inputBackground}`,
              color: COLORS.primaryText,
            }}
          />

          <label htmlFor="password" style={{ color: COLORS.placeHolder }}>
            Senha
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            required
            style={{
              border: `1px solid ${COLORS.secondary}`,
              backgroundColor: ` ${COLORS.inputBackground}`,
              color: COLORS.primaryText,
            }}
          />

          <a
            href="#"
            className="forgot-password"
            onClick={(event) => {
              event.preventDefault();
              navigate("/forgot-password");
            }}
            style={{ color: COLORS.button }}
          >
            Esqueceu a senha?
          </a>

          <div className="form-actions">
            <button
              type="submit"
              style={{
                backgroundColor: COLORS.button,
                color: COLORS.primaryText,
              }}
              disabled = {carregando ? true : false}
            >
              {carregando ? <span className="loader"></span> : "Entrar"}
            </button>
            <span
              onClick={() => {
                navigate("/signup/cliente");
              }}
              style={{ color: COLORS.button }}
            >
              Cadastrar
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginCliente;
