import React, { useState } from "react";
import MaskedInput from "react-text-mask";
import "./ServicoCadastro.css"; // Certifique-se que o caminho está correto
import { toast } from "react-toastify";
import { servicoService } from "../../../services/servico";
import { useAuth } from "../../../contexts/AuthContext";
import { COLORS } from "../../../global/STYLE";
import { useNavigate } from "react-router-dom";

const ServicoCadastro = () => {
  const { token, logout } = useAuth();
  const navigate = useNavigate()

  const [titulo, setTitulo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [preco, setPreco] = useState("");
  const [comissao, setComissao] = useState("0");
  const [duracao, setDuracao] = useState("");
  const [status, setStatus] = useState(false);
  const [carregando, setCarregando] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCarregando(true);

    const fatiaHorario = duracao.split(":");
    const soma =
      Number(fatiaHorario[0] * 60 * 60) + Number(fatiaHorario[1] * 60);
    // console.log(soma)

    try {
      const dadosCreate = {
        titulo,
        descricao,
        preco,
        comissao,
        duracao: soma,
        status: status ? "ativado" : "desativado",
      };
      const data = await servicoService.cadServicoEstabelecimento(
        token,
        dadosCreate
      );
      if (data.error) {
        if(data.reload){
          navigate("/login/estabelecimento")
          logout()  
        }
        toast.error(data.message);
      } else {
        toast.success(data.message);
        setTitulo("");
        setDescricao("");
        setPreco("");
        setComissao("");
        setDuracao("");
        setStatus(false);
      }
    } catch (e) {
      // toast.error(e);
    }
    setCarregando(false);
    // console.log({ titulo, descricao, preco, comissao, duracao, status });
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.checked);
  };

  const handlePrecoChange = (e) => {
    const valorFormatado = formatarValorParaReal(e.target.value);
    setPreco(removerFormatacaoDeReal(valorFormatado));
  };

  const formatarValorParaReal = (valor) => {
    return valor
      .replace(/\D/g, "") // Remove tudo que não é dígito
      .replace(/(\d)(\d{2})$/, "$1,$2") // Coloca a vírgula
      .replace(/(?=(\d{3})+(\D))\B/g, "."); // Coloca os pontos
  };

  const removerFormatacaoDeReal = (valor) => {
    return valor.replace(/\./g, "").replace(",", ".");
  };

  function validarHorario(horario) {
    const partes = horario.split(":");
    let horas = parseInt(partes[0], 10);
    let minutos = parseInt(partes[1], 10);

    horas = horas > 99 ? 99 : horas;
    minutos = minutos > 59 ? 59 : minutos;

    return `${horas.toString().padStart(2, "0")}:${minutos
      .toString()
      .padStart(2, "0")}`;
  }

  function handleChange(e) {
    const horarioValidado = validarHorario(e.target.value);
    setDuracao(horarioValidado);
  }

  return (
    <div style={{paddingBottom:"30px" }}>
      <h3 style={{ color: COLORS.primaryText }}>Cadastrar Serviço</h3>
      <span
        className="descricao"
        style={{ backgroundColor: COLORS.bgButtonCard, marginTop: 10 }}
      >
        <p style={{ color: COLORS.primaryText }}>
          Cadastre um novo serviço para vincular a um colaborador!
        </p>
      </span>

      <form
        onSubmit={handleSubmit}
        className="formulario-servico-cadastro"
        style={{ backgroundColor: COLORS.background}}
      >
        <label style={{ color: COLORS.placeHolder }}>Título</label>
        <input
          type="text"
          value={titulo}
          onChange={(e) => setTitulo(e.target.value)}
          required
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        <label style={{ color: COLORS.placeHolder }}>Descrição</label>
        <textarea
          value={descricao}
          onChange={(e) => setDescricao(e.target.value)}
          required
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        <label style={{ color: COLORS.placeHolder }}>Preço (R$)</label>
        <input
          type="text"
          value={formatarValorParaReal(preco)}
          onChange={handlePrecoChange}
          required
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        {/* <label style={{ color: COLORS.placeHolder }}>Comissão (%)</label>
        <input
          type="number"
          min={0}
          max={100}
          value={comissao}
          onChange={(e) => setComissao(e.target.value)}
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        /> */}

        <label style={{ color: COLORS.placeHolder }}>
          Duração do serviço (HH:MM)
        </label>
        <MaskedInput
        type="text"
          mask={[/\d/, /\d/, ":", /\d/, /\d/]}
          className="form-control"
          placeholder="HH:MM"
          value={duracao}
          onChange={handleChange}
          required
          style={{
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: ` ${COLORS.inputBackground}`,
            color: COLORS.primaryText,
          }}
        />

        <div className="switch-container-srvCad" style={{margin:"20px 0 0 0"}}>
          <label style={{ color: COLORS.placeHolder }}>Status:</label>
          <div className="switch-srvCad">
            <input
              type="checkbox"
              id="status-switch"
              checked={status}
              onChange={handleStatusChange}
            />
            <label
              htmlFor="status-switch"
              className="slider-srvCad round"
            ></label>
          </div>
        </div>
        <button type="submit" className="submit-button" disabled={carregando ? true : false} style={{backgroundColor:COLORS.button, marginTop:30}}>
        {carregando ? <span className="loader"></span> : "Cadastrar Serviço"}
          
        </button>
      </form>
    </div>
  );
};

export default ServicoCadastro;
