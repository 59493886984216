import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


const RedirectSenhaAppPage = () => {
    let navigate = useNavigate();

  const [message, setMessage] = useState(""); // Estado para armazenar a mensagem a ser exibida

  useEffect(() => {
    // Extrai o token da URL
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    // Verifica se o token está presente na URL
    if (token) {
      // Redireciona para o aplicativo móvel com o esquema personalizado
        window.location.href = `intellisyncagendamento://intellisyncagendamento/reset-password/${token}`;
    } else {
      setMessage("Token não encontrado. Não é possível redirecionar.");
      // Define a mensagem a ser exibida no estado
    }
  }, []); // O array vazio assegura que o efeito seja executado apenas uma vez, similar ao componentDidMount

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems:"center",
        // backgroundColor: "red",
        textAlign:'center',
        display: "flex",
    flexDirection:"column"
      }}
    >
      {message ? <h1>{message}</h1> : <h1>Redirecionando...</h1>}

      {message && <span style={{width:'100%', marginTop:10}} onClick={()=>navigate("/")}>Ir para o site</span>}
    </div>
  );
};

export default RedirectSenhaAppPage;
