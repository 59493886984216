import React, {
  // useCallback,
  useEffect,
  // useState,
} from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faThreads,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  useNavigate,
  // Link
} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomePage.css";
import { COLORS, ESTILO } from "../../global/STYLE";

import gplay from "../../assets/images/gplay.png";
import MembrosHome from "./Membros";

import { Outlet } from "react-router-dom";
import NavbarHome from "../../components/navbar/NavbarHome";
import CommentCarousel from "../../components/ComentarioCarousel/CommentCarousel";
import Planos from "./Planos";
import { decodeID } from "../../functions/criptografia";
import { CONSTANTES } from "../../global/CONSTANTES";
import { estabelecimentoService } from "../../services/estabelecimento";
import { useAuth } from "../../contexts/AuthContext";

const HomePage = () => {
  const { statusEstabelecimentoCompartilhado } = useAuth();
  let navigate = useNavigate();

  const currentYear = new Date().getFullYear();
  // const [isNavExpanded, setIsNavExpanded] = useState(false);

  const armazenaAfiliadoLocalStorage = async (af) => {
    const afiliado = { afiliado: af };
    await localStorage.setItem("afiliado", JSON.stringify(afiliado));
    navigate("/signup/estabelecimento");
  };

  /**Pega Dados do Estabelecimento compartilhado pela url */
  const pegaDadosEstabelecimentoCompartilhado = async (idDecodificado) => {
    try {
      const data = await estabelecimentoService.pegaEstabelecimentoIDsemJWT(
        idDecodificado,
        "ativado"
      );
      if (data.error) {
        toast.error(data.message);
      } else {
        const dados = Object.keys(data.data).length
        if(dados > 0){
          statusEstabelecimentoCompartilhado(data.data, data.data.estabelecimento.id, true);
          // console.log(data.data);
        }else{
          toast.error("Estabelecimento não encontrado ou não está ativo!");
        }
      }
    } catch (e) {
      toast.error("Estabelecimento não encontrado ou não está ativo!");
    }
  };

  /** COMPARTILHAMENTO RECEBIDO DO ESTABELECIMENTO */
  // const openAppOrFallback = (encodedId) => {
  //   const decodedId = decodeID(
  //     encodedId.trim(),
  //     CONSTANTES.SECRET_KEY_CRIPTOGRAFIA
  //   );

  //   if (decodedId) {
  //     // Tente abrir o aplicativo
  //     const appUrl = `intellisyncagendamento://intellisyncagendamento/estabelecimento/${encodedId}`;

  //     // Use window.location.href para tentar abrir o aplicativo
  //     window.location.href = appUrl;

  //     // Verifica após um tempo se o aplicativo não foi aberto
  //     setTimeout(() => {
  //       // Como não há fallback URL, simplesmente executamos uma função se o redirecionamento falhou
  //       pegaDadosEstabelecimentoCompartilhado(decodedId);
  //     }, 1500); // Tempo para verificar se o aplicativo foi aberto
  //   }
  // };

  const openAppOrFallback = (encodedId) => {
    const decodedId = decodeID(
      encodedId.trim(),
      CONSTANTES.SECRET_KEY_CRIPTOGRAFIA
    );

    if (decodedId) {
      const appUrl = `intellisyncagendamento://intellisyncagendamento/estabelecimento/${encodedId}`;
      
      if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        // Se o dispositivo é móvel, tente abrir o aplicativo
        window.location.href = appUrl;

        setTimeout(() => {
          // Verifique se o redirecionamento falhou e execute o fallback
          pegaDadosEstabelecimentoCompartilhado(decodedId);
        }, 1500);
      } else {
        // Se não for um dispositivo móvel, não tente abrir o aplicativo
        // console.log("Esquema personalizado não suportado neste ambiente.");
        pegaDadosEstabelecimentoCompartilhado(decodedId);
      }
    }
  };

  useEffect(() => {
    // Obtenha a URL completa
    const url = window.location.href;

    // Use uma biblioteca ou uma lógica personalizada para analisar a URL e obter o parâmetro desejado
    const params = new URL(url).searchParams;
    const parametroDaUrl = params.get("pagamento");
    const afiliadoUrl = params.get("af");
    const encodedId = params.get("e");
    /**AFILIADO CODIGO */
    if (afiliadoUrl) {
      // toast.info(afiliadoUrl)
      armazenaAfiliadoLocalStorage(afiliadoUrl);
    }

    // Faça algo com o parâmetro da URL
    if (parametroDaUrl) {
      toast.info(parametroDaUrl);
    }

    /**COMPARTILHAMENTO RECEBIDO DO ESTABELECIMENTO */
    if (encodedId) {
      openAppOrFallback(encodedId);
    }
  }, [navigate]);

  return (
    <div className="home" style={{ backgroundColor: COLORS.background }}>
      <NavbarHome />

      {/* <HeaderHome /> */}
      <div style={{ marginTop: ESTILO.navBar_height }} />
      <Outlet />

      <section>
        <CommentCarousel />
      </section>
      <section className="plans-section">
        {/* PLANOS */}
        {/* <Planos/> */}

        {/* MEMBROS */}
        <MembrosHome />
      </section>

      <footer className="footer">
        <div className="footer-top">
          <div
            className="social-links"
            style={{ margin: "10px 10px 0 10px", width: "auto" }}
          >
            <div>
              <h4>Siga nossa rede social</h4>
            </div>
            <div className="social-icons">
              <a
                href="https://www.instagram.com/facilite.appbr/"
                target="_blank"
                style={{ textDecoration: "none", color: COLORS.primary }}
                alt="@facilite.appbr"
                title="@facilite.appbr"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="2x"
                  style={{ marginRight: 30 }}
                />
              </a>
              {/* <FontAwesomeIcon
                icon={faThreads}
                size="2x"
                style={{ marginRight: 30 }}
              /> */}
              {/* <FontAwesomeIcon icon={faFacebookF} size="2x" /> */}
            </div>
          </div>
          <div className="play-store">
            <p>Versão para clientes disponível no Google Play</p>
            <h3>Baixe agora</h3>
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.intellisync.facilite"
                alt="facilite app"
                title="facilite app"
                target="_blank"
              >
                <img src={gplay} className="gplay" />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          © 2024-{currentYear} . Desenvolvido por{" "}
          <a href="http://www.intellisync.com.br" target="_blank">
            IntelliSync
          </a>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
