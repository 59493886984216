import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Importando o hook useLocation do React Router
import { toast } from "react-toastify";
import { agendamentoService } from "../../services/agendamento";

function useQuery() {
  return new URLSearchParams(useLocation().search); // Função utilitária para capturar os parâmetros de consulta da URL
}

function Votacao({ categoria, enviarVoto }) {
  const [estrelasSelecionadas, setEstrelasSelecionadas] = useState(0);

  const handleClickEstrela = (numeroEstrelas) => {
    setEstrelasSelecionadas(numeroEstrelas);
  };

  useEffect(() => {
    if (estrelasSelecionadas > 0) {
      enviarVoto(estrelasSelecionadas);
    }
  }, [estrelasSelecionadas, enviarVoto]);

  return (
    <div style={styles.container}>
      <h3 style={styles.title}>Votar {categoria}</h3>
      <p style={styles.description}>
        Por favor, selecione o número de estrelas abaixo para avaliar sua
        experiência:
      </p>
      <div style={styles.starsContainer}>
        {[1, 2, 3, 4, 5].map((numero) => (
          <span
            key={numero}
            onClick={() => handleClickEstrela(numero)}
            style={{
              cursor: "pointer",
              fontSize: 32,
              color: estrelasSelecionadas >= numero ? "#FFD700" : "#D3D3D3",
            }}
          >
            ★
          </span>
        ))}
      </div>
    </div>
  );
}

function TelaVotacao() {
  const query = useQuery(); // Capturando os parâmetros de consulta da URL
  const token_rating = query.get("id"); // Obtendo o ID do agendamento da URL

  const [estrelasEstabelecimento, setEstrelasEstabelecimento] = useState(0);
  const [estrelasColaborador, setEstrelasColaborador] = useState(0);
  const [comentario, setComentario] = useState("");
  const [enviarVoto, setEnviarVoto] = useState(false);
  const [votado, setVotado] = useState(false);
  const [semVoto, setSemVoto] = useState(false);

  const [colaborador, setColaborador] = useState(0);
  const [estabelecimento, setEstabelecimento] = useState(0);
  const [cliente, setCliente] = useState(0);
  const [agendamento, setAgendamento] = useState(0);

  const pegaDados = async () => {
    try {
      const data = await agendamentoService.pegaAgendamentoTokenVotacao(
        token_rating
      );
      if (data.error) {
        setSemVoto(true);
        toast.error(data.message);
      } else {
        setEstabelecimento(data.data.estabelecimento_id);
        setColaborador(data.data.colaborador_id);
        setCliente(data.data.cliente_id);
        setAgendamento(data.data.agendamento_id);
      }
    } catch (e) {
      setSemVoto(true);
      toast.error(e.message);
    }
  };

  useEffect(() => {
    pegaDados();
  }, []);

  const handleVotarEstabelecimento = (estrelas) => {
    setEstrelasEstabelecimento(estrelas);
  };

  const handleVotarColaborador = (estrelas) => {
    setEstrelasColaborador(estrelas);
  };

  const handleComentarioChange = (event) => {
    setComentario(event.target.value);
  };

  useEffect(() => {
    if (estrelasEstabelecimento > 0 && estrelasColaborador > 0) {
      setEnviarVoto(true);
    }
  }, [estrelasEstabelecimento, estrelasColaborador]);

  const handleEnviarVoto = async () => {
    if(!comentario || comentario.length<=10){
      toast.warning("O comentário precisa ter pelo menos 10 caracteres.")
      return false
    }
    try {
      let voto = 0;
      const enviaVotoEstabelecimento = await agendamentoService.starE({
        estabelecimento_id: estabelecimento,
        cliente_id: cliente,
        rating: estrelasEstabelecimento,
        comentario,
        agendamento_id: agendamento,
      });
      if (enviaVotoEstabelecimento.error) {
        toast.error(enviaVotoEstabelecimento.message);
      } else {
        voto += 1;
      }

      const enviaVotoColaborador = await agendamentoService.starC({
        colaborador_id: colaborador,
        cliente_id: cliente,
        rating: estrelasColaborador,
      });
      if (enviaVotoColaborador.error) {
        toast.error(enviaVotoColaborador.message);
      } else {
        voto += 1;
      }

      if (voto > 0) {
        setVotado(true);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };
  if (semVoto) {
    return (
      <div style={styles.body}>
        <div style={styles.container}>
          <h3 style={styles.title}>Desculpa, não há mais o que votar!</h3>
        </div>
      </div>
    );
  } else if (votado) {
    return (
      <div style={styles.body}>
        <div style={styles.container}>
          <h3 style={styles.title}>Obrigado por votar!</h3>
        </div>
      </div>
    );
  } else {
    return (
      <div style={styles.body}>
        <div style={styles.container}>
          <Votacao
            categoria="no Estabelecimento"
            enviarVoto={handleVotarEstabelecimento}
          />
          <Votacao
            categoria="no Colaborador"
            enviarVoto={handleVotarColaborador}
          />
        </div>
        <div style={styles.commentContainer}>
          <p style={styles.commentLabel}>Deixe seu comentário:</p>
          <textarea
            rows={6}
            cols={50}
            style={styles.commentInput}
            maxLength={200}
            value={comentario}
            onChange={handleComentarioChange}
            placeholder="Digite seu comentário aqui..."
          />
          <p style={styles.commentLength}>
            <small>{comentario.length}/200</small>
          </p>
        </div>
        {enviarVoto && (
          <button onClick={handleEnviarVoto} style={styles.button}>
            Enviar
          </button>
        )}
      </div>
    );
  }
}

const styles = {
  body: {
    backgroundColor: "#171717",
    minHeight: "100vh",
    display: "flex",
    // justifyContent: 'center',
    // alignItems: 'center',
    flexDirection: "column",
    padding: 20,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 10,
  },
  title: {
    color: "#FFF",
    fontSize: 24,
    marginBottom: 10,
  },
  description: {
    color: "#FFF",
    fontSize: 16,
    marginBottom: 10,
  },
  starsContainer: {
    display: "flex",
    justifyContent: "center",
  },
  commentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 10,
  },
  commentLabel: {
    color: "#FFF",
    marginBottom: 10,
  },
  commentInput: {
    width: "80%",
    padding: 10,
    borderRadius: 5,
    border: "1px solid #FFF",
    backgroundColor: "#333",
    color: "#FFF",
    resize: "none",
  },
  commentLength: {
    color: "#FFF",
    width: "78%",
    textAlign: "right",
  },
  button: {
    backgroundColor: "#FFD700",
    color: "#000",
    padding: "10px 20px",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    marginTop: 20,
  },
};

export default TelaVotacao;
