import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import { COLORS, applyCssVariables } from "./global/STYLE";

// Aplicar variáveis CSS
applyCssVariables(COLORS);

const App = () => {
  return (
    <div>
      <ToastContainer
        style={{ zIndex: 1001, minWidth: "350px", maxWidth: "auto" }}
        bodyStyle={{ width: "100%" }}
      />
      <AuthProvider>
          <Router>
            <Routes />
          </Router>
      </AuthProvider>
    </div>
  );
};

export default App;
