import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Stars from "../star/Stars";
import { homeService } from "../../services/home";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import "./CommentCarousel.css";
import noImage from '../../assets/images/noimage.png';
import { CONSTANTES } from "../../global/CONSTANTES";

// Componentes de seta personalizados com FontAwesomeIcon
const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'rgba(0, 0, 0, 0.8)', borderRadius: '50%', padding: '10px' }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faArrowRight} color="white" size="2x" />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'rgba(0, 0, 0, 0.8)', borderRadius: '50%', padding: '10px' }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faArrowLeft} color="white" size="2x" />
    </div>
  );
};

const CommentCarousel = () => {
  const [dados, setDados] = useState([]);

  const getDados = async () => {
    try {
      const data = await homeService.getComentariosHome();
      if (!data.error) {
        setDados(data.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getDados();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if(dados.length > 0){

  return (
    <div className="comment-carousel">
      <div className="comment-container">
        <h2 className="comment-title">Comentários dos Clientes</h2>
        <Slider {...settings}>
          {dados.map((comment, index) => (
            <div key={comment.id} className="comment-slide">
              <div className="image-container">
                <div
                  className="image-wrapper"
                  style={{
                    backgroundImage: `url(${comment.cliente.foto
                      ? CONSTANTES.uri + CONSTANTES.caminhoImageClientes + comment.cliente.id + "/" + comment.cliente.foto
                      : noImage})`
                  }}
                ></div>
                <div className="star-container">
                  <div className="star-text">
                    {comment.cliente.nome} deu {comment.rating} estrelas para {comment.estabelecimento.nome}
                  </div>
                  <div className="star">
                    <Stars stars={comment.rating} />
                  </div>
                </div>
                <div
                  className="image-wrapper"
                  style={{
                    backgroundImage: `url(${comment.estabelecimento.foto
                      ? CONSTANTES.uri + CONSTANTES.caminhoImageEstabelecimentos + comment.estabelecimento.id + "/" + comment.estabelecimento.foto
                      : noImage})`
                  }}
                ></div>
              </div>
              <p className="comment-text">{comment.comentario}</p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}else{
    return <></>
}
};

export default CommentCarousel;
