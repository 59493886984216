// LoginEstabelecimento.js

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CONSTANTES } from "../../global/CONSTANTES";
import { useAuth } from "../../contexts/AuthContext";
import "./Login.css";
import { toast } from "react-toastify";
import { resetSenhaService } from "../../services/resetSenha";
import { COLORS } from "../../global/STYLE";

const EsqueceuSenha = () => {
  let navigate = useNavigate();
  const {user, login, logout } = useAuth()
  // console.log(user, login, logout)

  const [email, setEmail] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState("")
  const [carregando, setCarregando] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCarregando(true);
    // console.log(email, password)
    const createData = {
      email: email,
      tipoUsuario: tipoUsuario
    }
    try {
      const data = await resetSenhaService.forgotSenha(createData);
      if (data.error) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        //apaga os dados
        setEmail("")
        setTipoUsuario("")
      }
    } catch (e) {
      toast.error(e);
    }
    setCarregando(false);
  };

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className="bodyLogin" style={{ backgroundColor: COLORS.background }}>
      <div className="login-estabelecimento-container">
        <div className="logo-container">
        <img
            src={`/${CONSTANTES.logoImage}`}
            alt={CONSTANTES.logo}
            title={CONSTANTES.logo}
            width={200}
            height={75}
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          />{" "}          
          {/* <img src="caminho/para/sua/logo.png" alt={CONSTANTES.logo} /> */}
        </div>
        <form onSubmit={handleSubmit} className="login-estabelecimento-form">
          <label htmlFor="email" style={{ color: COLORS.placeHolder }}>Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            required
            style={{
              border: `1px solid ${COLORS.secondary}`,
              backgroundColor: ` ${COLORS.inputBackground}`,
              color: COLORS.primaryText,
            }}
          />

          <label htmlFor="tipoUsuario" style={{ color: COLORS.placeHolder }}>Tipo de Usuário:</label>
          
          <select
            className="login-estabelecimento-input"
            value={tipoUsuario || ""}
            onChange={(e) => setTipoUsuario(e.target.value)}
            id="tipoUsuario"
            required
            style={{
              border: `1px solid ${COLORS.secondary}`,
              backgroundColor: ` ${COLORS.inputBackground}`,
              color: COLORS.primaryText,
            }}
          >
            <option value="">Selecione...</option>
            <option value="estabelecimento">Estabelecimento</option>
            <option value="colaborador">Colaborador</option>
            <option value="representante">Afiliado</option>
            <option value="cliente">Cliente</option>
          </select>


          <div className="form-actions">
            <button type="submit" style={{
                backgroundColor: COLORS.button,
                color: COLORS.primaryText,
              }}>
            {carregando ? <span className="loader"></span> : "Solicitar troca de Senha"}
              </button>

            <span type="button" onClick={handleGoBack} style={{ color: COLORS.button }}>
              Voltar
            </span>

          </div>
        </form>
      </div>
    </div>
  );
};

export default EsqueceuSenha;
