import Api from "../index";


const create = async(dados) => {
  const {data} = await Api.post('/colaborador', dados)
  return data
}

const cadColaboradorEstabelecimento = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.post(
    "/colaborador/estabelecimento",
    dados,
    headerToken
  );
  return data;
};

const pegaColaboradoresEstabelecimento = async (token) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.get("/colaboradores/estabelecimento", headerToken);
  return data;
};

const statusColaboradoresEstabelecimento = async (
  token,
  status,
  colaborador_id
) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.put(
    "/estabelecimentoColaborador/status",
    { status, colaborador_id },
    headerToken
  );
  return data;
};

const searchColaboradorEstabelecimento = async (search) => {
  const { data } = await Api.post("/colaboradores/search", { search });
  return data;
};

const vincularColaboradorEstabelecimento = async (token, colaborador_id) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const {data} = await Api.post("/estabelecimentoColaborador", {colaborador_id}, headerToken)
  return data
}

const desvincularColaboradorEstabelecimento = async (token, colaborador_id) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const {data} = await Api.post("/estabelecimentoColaborador/desvincular", {colaborador_id}, headerToken)
  return data
}

const login = async (email, password) => {
  const dados = {
    email,
    password,
  };
  const { data } = await Api.post("/login/colaborador", dados);
  return data;
};

const validateToken = async ( token ) => {
  // console.log("VALIDA TOKEN SERVICE = ",token)
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer ' seguido do token
    },
  };
  const {data} = await Api.post(
    "/validateToken/colaborador",
    null,
    headerToken
  );
  return data;
};

const changePhoto = async (file, token, colaborador_id) => {
  const MAX_RETRIES = 3;
  let attempts = 0;
  let success = false;
  // Crie um FormData e anexe a imagem e outros dados
  const formData = new FormData();

  formData.append("colaborador_id", colaborador_id.toString());
  formData.append("file", file);
  // console.log('FormData:', { cliente_id: cliente_id.toString(), file: { uri: uri.uri, type: uri.type, name: uri.fileName } });
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  while (attempts < MAX_RETRIES && !success) {
    try {
      const { data } = await Api.post(
        "/colaborador/mudarFoto",
        formData,
        config
      );
      return data;
      return false;
    } catch (error) {
      attempts += 1;
      // console.log('Erro no upload da imagem:', error);
      // opcional: aguarde um pouco antes de tentar novamente
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }
};

const changePhotoByEstab = async (file, token, colaborador_id) => {
  const MAX_RETRIES = 3;
  let attempts = 0;
  let success = false;
  // Crie um FormData e anexe a imagem e outros dados
  const formData = new FormData();

  formData.append("colaborador_id", colaborador_id.toString());
  formData.append("file", file);
  // console.log('FormData:', { cliente_id: cliente_id.toString(), file: { uri: uri.uri, type: uri.type, name: uri.fileName } });
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  while (attempts < MAX_RETRIES && !success) {
    try {
      const { data } = await Api.post(
        "/colaborador/mudarFoto/byEstab",
        formData,
        config
      );
      return data;
      return false;
    } catch (error) {
      attempts += 1;
      // console.log('Erro no upload da imagem:', error);
      // opcional: aguarde um pouco antes de tentar novamente
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }
};

const updateColaborador = async (token, perfil) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.put(
    "/colaborador/update",
    perfil,
    headerToken
  );
  return data;
};

const dataColaborador = async (token) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.get("/colaborador/data", headerToken);
  return data;
};

const pegaEstabelecimentoVinculadoAoColaborador = async (token) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.get("/colaborador/estabelecimentos/servicos", headerToken);
  return data;
};

const listColaboradorPorServico = async (token, servico_id, status) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const {data} = await Api.get(`/servico/colaboradores/${servico_id}/${status}`, headerToken);
  return data;
};

const listColaboradorPorServicoAgendamentoClienteWEB = async (token, servico_id, status) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const {data} = await Api.get(`/servico/colaboradores/web/agendamento_cliente/${servico_id}/${status}`, headerToken);
  return data;
};

const listaColaboradorPorEstabelecimento = async (token,estabelecimento_id, status) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const {data} = await Api.get(`/colaborador/estabelecimento/getAll/${estabelecimento_id}/${status}`, headerToken);
  return data;
};

const deleteAccount = async (dados) => {
  const { data } = await Api.post(
    `/colaborador/deleteAccount`,
    dados
  );
  return data;
};

export const colaboradorService = {
  cadColaboradorEstabelecimento,
  pegaColaboradoresEstabelecimento,
  statusColaboradoresEstabelecimento,
  searchColaboradorEstabelecimento,
  vincularColaboradorEstabelecimento,
  desvincularColaboradorEstabelecimento,
  login,
  validateToken,
  create,
  changePhoto,
  updateColaborador,
  dataColaborador,
  pegaEstabelecimentoVinculadoAoColaborador,
  listColaboradorPorServico,
  listaColaboradorPorEstabelecimento,
  listColaboradorPorServicoAgendamentoClienteWEB,
  deleteAccount,
  changePhotoByEstab,
};
