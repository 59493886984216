import { useCallback } from "react";
import { CONSTANTES } from "../../../global/CONSTANTES";
import "../../ADMIN_Estabelecimento/Agendamentos/ModalAgendamento.css";
import Modal from "react-modal";
import { formatarValorReal } from "../../../functions/formatarValorReal";
import { toast } from "react-toastify";
import { agendamentoService } from "../../../services/agendamento";
import { useAuth } from "../../../contexts/AuthContext";
import { COLORS } from "../../../global/STYLE";
import { useNavigate } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {faClose} from "@fortawesome/free-solid-svg-icons";
Modal.setAppElement("#root"); // ou o seletor do seu elemento raiz

const ModalAgendamento = ({ isOpen, onRequestClose, event, reload }) => {
  const { token, logout } = useAuth();
  const navigate = useNavigate()
  // console.log(event);

  const handleCancelar = useCallback(async () => {
    const confirmacao = window.confirm("Você deseja realmente cancelar?");

    if (confirmacao) {
      // Lógica a ser executada quando o usuário pressionar "OK"
      try {
        const dados = await agendamentoService.novoStatusAgendamentoColaborador(
          token,
          event.id,
          "cancelado"
        );
        if (dados.error) {
          if(dados.reload){
            navigate("/login/colaborador")
            logout()  
          }
          return toast.error(dados.message);
        } else {
          reload()
          onRequestClose()
          return toast.success(dados.message);
        }
      } catch (e) {
        // return toast.error(e);
      }
    }
  }, [event]);

  const handleConcluir = useCallback(async () => {
    const confirmacao = window.confirm("Você deseja realmente concluir?");

    if (confirmacao) {
      // Lógica a ser executada quando o usuário pressionar "OK"
      try {
        const dados = await agendamentoService.novoStatusAgendamentoColaborador(
          token,
          event.id,
          "concluido"
        );
        if (dados.error) {
          if(dados.reload){
            navigate("/login/colaborador")
            logout()  
          }
          return toast.error(dados.message);
        } else {
          reload()
          onRequestClose()
          return toast.success(dados.message);
        }
      } catch (e) {
        // return toast.error(e);
      }
    }
  }, [event]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          zIndex: 1000,
          width: "80%",
          height: "auto",
          margin: "auto",
          backgroundColor: COLORS.background,
        },
      }}
      contentLabel="Detalhes do Evento"
    >
      <div className="container">
        <div className="header">
          <h2 style={{ color: COLORS.secondaryText }}>{event.title}</h2>
          <button
            onClick={onRequestClose}
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 5,
              fontSize: 12,
            }}
            className="button"
          >
            Fechar
          </button>
        </div>

        <hr
          style={{
            marginBottom: 20,
            borderColor: "rgba(0,0,0,0.2)",
            borderWidth: 1,
          }}
        />

        <div className="body">
          <div className="servico">
            <div className="data">
              <strong>Data Agendamento:</strong> {event.inicio}
            </div>
            <div className="valor">
              <strong>Valor do serviço:</strong>{" "}
              {formatarValorReal(event.valor)}
            </div>
          </div>
          <div className="status">
            <strong>Status</strong>{" "}
            <span
              style={{
                backgroundColor:
                  event.status === "concluido"
                    ? "#388e3c"
                    : event.status === "pendente"
                    ? "#fbc02d"
                    : "#d32f2f",
                color: "#ffffff",
              }}
            >
              {event.status}
            </span>
          </div>
          <div className="cards">

            <div className="cliente">
              <h4>Cliente</h4>
              {event.cliente && (
                <>
                  {event.cliente.foto ? (
                    <img
                      src={`${process.env.REACT_APP_API_URI}${CONSTANTES.caminhoImageClientes}${event.cliente.id}/${event.cliente.foto}`}
                      alt=""
                      className="avatar"
                    />
                  ) : (
                    <img
                      src="https://via.placeholder.com/100"
                      alt="Avatar"
                      className="avatar"
                    />
                  )}
                  <p>{event.cliente.nome}</p>
                  <p>{event.cliente.telefone}</p>
                  <p>{event.cliente.email}</p>
                </>
              )}
            </div>

            <div className="colaborador">
              <h4>Estabelecimento</h4>
              {event.estabelecimento && (
                <>
                  {event.estabelecimento.foto ? (
                    <img
                      src={`${process.env.REACT_APP_API_URI}${CONSTANTES.caminhoImageEstabelecimentos}${event.estabelecimento.id}/${event.estabelecimento.foto}`}
                      alt=""
                    />
                  ) : (
                    <img
                      src="https://via.placeholder.com/100"
                      alt="Avatar"
                      className="avatar"
                    />
                  )}
                  <p>{event.estabelecimento.nome}</p>
                  <p>{event.estabelecimento.telefone}</p>
                  <p>{event.estabelecimento.email}</p>
                </>
              )}
            </div>
          </div>
          <div className="btnGroup">
            {event.status === "pendente" ? (
              <button onClick={handleConcluir} className="buttonOK">
                Concluir Agendamento
              </button>
            ) : (
              ""
            )}
            {event.status === "pendente" ? (
              <button onClick={handleCancelar} className="button">
                Cancelar Agendamento
              </button>
            ) : (
              ""
            )}
            {/* <button onClick={handleConcluir} className="buttonOK">Concluir Agendamento</button>
            <button onClick={handleCancelar} className="button">Cancelar Agendamento</button> */}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAgendamento;
