import Api from "../index";

const deleteAccount = async (dados) => {
  const { data } = await Api.post(
    `/cliente/deleteAccount`,
    dados
  );
  return data;
};

const login = async (email, password) => {
  const dados = {
    email,
    password,
  };
  const {data} = await Api.post('/login', dados);
  return data;
};

const buscarClienteEstabelecimento = async (searchterm) => {
  
  const {data} = await Api.get(`/cliente/web/search/${searchterm}`);
  return data;
};

const validateToken = async ( token ) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer ' seguido do token
    },
  };
  const {data} = await Api.post('/validateToken', null, headerToken);
  return data;
};

const requestPasswordReset = async (email) => {
  const {data} = await Api.post('/forgot-password', {email});
  return data;
};

const resetPassword = async (
  email,
  token,
  newPassword,
) => {
  const {data} = await Api.post('/reset-password', {email, token, newPassword});
  return data;
};

const changePhoto = async (token, file, cliente_id) => {
  const MAX_RETRIES = 1;
  let attempts = 0;
  let success = false;
  // Crie um FormData e anexe a imagem e outros dados
  const formData = new FormData();
  formData.append('cliente_id', cliente_id.toString());
  formData.append("file", file);
  // console.log('FormData:', { cliente_id: cliente_id.toString(), file: { uri: uri.uri, type: uri.type, name: uri.fileName } });
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };
  while (attempts < MAX_RETRIES && !success) {
    try {
      const {data} = await Api.post('/cliente/mudarFoto', formData, config);
      return data;
      // return false;
    } catch (error) {
      attempts += 1;
      // console.log('Erro no upload da imagem:', error);
      // opcional: aguarde um pouco antes de tentar novamente
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }
};

const updatePerfil = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  // console.log(dados)
  const {data} = await Api.post('/cliente/perfil',  dados, headerToken);
  return data;
};

const excluirFotoPerfil = async (token) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  // console.log(dados)
  const {data} = await Api.post('/cliente/delete/imgPerfil',  null, headerToken);
  return data;
};

const mudarSenha = async (token,dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  // console.log(dados)
  const {data} = await Api.post('/cliente/mudarSenha',  dados, headerToken);
  return data;
};

const create = async(dados) => {
  const {data} = await Api.post('/cliente', dados)
  return data
}


export const clienteService = {
deleteAccount,
create,
  login,
  validateToken,
  requestPasswordReset,
  resetPassword,
  changePhoto,
  updatePerfil,
  mudarSenha,
  excluirFotoPerfil,
  buscarClienteEstabelecimento,
};
