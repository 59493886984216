import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify"; // Certifique-se de instalar a biblioteca react-toastify
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../contexts/AuthContext";
import { agendamentoService } from "../../services/agendamento";
import { COLORS } from "../../global/STYLE";
import { CONSTANTES } from "../../global/CONSTANTES";
import { formatarValorReal } from "../../functions/formatarValorReal";
import converteData from "../../functions/converteData";
import "./AgendamentoCliente.css"; // Importe o arquivo CSS
import { useNavigate } from "react-router-dom";
import noImage from '../../assets/images/noimage.png'

function AgendamentoCliente() {
  const { token, logout } = useAuth();
  const navigate = useNavigate();

  const [tab, setTab] = useState("pendente");
  const [concluido, setConcluido] = useState([]);
  const [pendente, setPendente] = useState([]);
  const [cancelado, setCancelado] = useState([]);
  const [loading, setLoading] = useState(false); // Estado para controlar o carregamento
  const [carregando, setCarregando] = useState(false);

  const moveItemById = (id) => {
    const index = pendente.findIndex((item) => item.id === id);
    if (index !== -1) {
      const removedItem = pendente.splice(index, 1)[0];
      removedItem.status = "cancelado";
      setPendente([...pendente]);
      setCancelado([...cancelado, removedItem]);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleCancelar = async (id) => {
    setCarregando(true);
    if (window.confirm("Você deseja realmente cancelar?")) {
      const dadosCreate = { agendamento_id: id };
      try {
        const dados = await agendamentoService.cancelamentoCliente(
          token,
          dadosCreate
        );
        // console.log(dados)
        if (dados.error) {
          toast.error(dados.message);
          if (dados.reload) {
            setLoading(false);
            navigate("/");
          }
        } else {
          toast.success(dados.message);
          moveItemById(id);
        }
      } catch (e) {
      } finally {
        setCarregando(false);
      }
    }
  };

  const pegaDados = useCallback(async () => {
    setLoading(true);
    try {
      const dado = await agendamentoService.pegarAgendamentosCliente(token);
      if (dado.error) {
        if (dado.reload) {
          setLoading(false);
          logout();
          toast.error(dado.message);
        }
      } else {
        // console.log(dado.data)
        dado.data.forEach((agendamento) => {
          if (agendamento.status === "pendente") {
            setPendente((prev) => [...prev, agendamento]);
          } else if (agendamento.status === "concluido") {
            setConcluido((prev) => [...prev, agendamento]);
          } else if (agendamento.status === "cancelado") {
            setCancelado((prev) => [...prev, agendamento]);
          }
        });
      }
    } catch (e) {
      console.log(`Error: ${e}`);
    }
    setLoading(false);
  }, [token]);

  useEffect(() => {
    setPendente([]);
    setConcluido([]);
    setCancelado([]);
    pegaDados();
  }, [pegaDados]);

  const agendamentos = { pendente, concluido, cancelado };

  return (
    <div className="agendamento_cliente_container">
      <h1 className="agendamento_cliente_titulo">Meus Agendamentos</h1>
      <div className="agendamento_cliente_tabs">
        <button
          className={
            tab === "pendente"
              ? "agendamento_cliente_tabActive"
              : "agendamento_cliente_tab"
          }
          onClick={() => setTab("pendente")}
        >
          Pendente
        </button>
        <button
          className={
            tab === "concluido"
              ? "agendamento_cliente_tabActive"
              : "agendamento_cliente_tab"
          }
          onClick={() => setTab("concluido")}
        >
          Concluído
        </button>
        <button
          className={
            tab === "cancelado"
              ? "agendamento_cliente_tabActive"
              : "agendamento_cliente_tab"
          }
          onClick={() => setTab("cancelado")}
        >
          Cancelado
        </button>
      </div>
      <div className="agendamento_cliente_lista">
        {loading ? (
          <p>Carregando...</p>
        ) : agendamentos[tab].length === 0 ? (
          <p>
            Não há agendamentos{" "}
            {tab === "pendente"
              ? "pendentes"
              : tab === "concluido"
              ? "concluídos"
              : "cancelados"}
            .
          </p>
        ) : (
          <div className="agendamento_cliente_grid">
            {agendamentos[tab].map((agendamento, index) => (
              <div key={index} className="agendamento_cliente_card">
                <div className="agendamento_cliente_cardContent">
                  <div className="agendamento_cliente_agendamentoImg">
                    <img
                    src={
                      agendamento.foto && agendamento.foto.length > 0 ?
                      `${CONSTANTES.uri}${CONSTANTES.caminhoImageEstabelecimentos}${agendamento.estabelecimento_id}/${agendamento.foto}`
                      :
                      `${noImage}`
                    }
                    // src={`${CONSTANTES.uri}${CONSTANTES.caminhoImageEstabelecimentos}${agendamento.estabelecimento_id}/${agendamento.foto}`}
                      alt="Imagem"
                      className="agendamento_cliente_img"
                    />
                  </div>
                  <div className="agendamento_cliente_agendamentoInfo">
                    <div className="agendamento_cliente_nome">
                      {agendamento.nome}
                    </div>
                    <div
                      className="agendamento_cliente_data_agendamento"
                      style={{ color: COLORS.bgButtonCard }}
                    >
                      Data agendamento:
                    </div>
                    <div
                      className="agendamento_cliente_data_hora_agendamento"
                      style={{ color: COLORS.button }}
                    >
                      {converteData(agendamento.dia_agendamento)}
                    </div>
                  </div>
                  <div
                    className="agendamento_cliente_data_agendamento"
                    style={{ color: COLORS.bgButtonCard }}
                  >
                    Endereço:
                  </div>

                  <div className="agendamento_cliente_agendamentoEndereco">
                    {agendamento.logradouro}, {agendamento.numero},{" "}
                    {agendamento.cidade}, {agendamento.estado}
                  </div>
                  <div className="agendamento_cliente_agendamentoFooter">
                    <div>
                      <div className="agendamento_cliente_titulo_servico">
                        {agendamento.titulo_servico}
                      </div>
                      <div
                        className="agendamento_cliente_valor"
                        style={{ color: COLORS.button }}
                      >
                        {formatarValorReal(agendamento.valor)}
                      </div>
                    </div>
                    <div
                      style={{
                        color:
                          agendamento.status === "concluido"
                            ? COLORS.concluido
                            : agendamento.status === "pendente"
                            ? COLORS.pendente
                            : COLORS.cancelado,
                        backgroundColor: "rgba(0,0,0,0.9)",
                        padding: 10,
                        borderRadius: 5,
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      {agendamento.status}
                    </div>
                  </div>
                  {agendamento.status === "pendente" && (
                    <button
                      className="agendamento_cliente_cancelButton"
                      onClick={() => handleCancelar(agendamento.id)}
                      disabled={carregando ? true : false}
                    >
                      {carregando ? (
                        <span className="loader"></span>
                      ) : (
                        "Cancelar agendamento"
                      )}
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default AgendamentoCliente;
