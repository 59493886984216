// Função para codificar o ID
export function encodeID(id, secretKey) {
  const base36Id = id.toString(36); // Converte o ID para Base36
  const hash = Math.abs((id + secretKey.length) * 31).toString(36); // Gera um hash simples
  return `${base36Id}${hash}`; // Concatena o ID e o hash sem separador
}
  
  // Função para decodificar o ID
  export function decodeID(encodedId, secretKey) {
    // Verifique se encodedId e secretKey não são undefined ou null
    if (!encodedId || !secretKey) {
        console.error("código indefinido.");
        return null;
    }

    // console.log("Encoded ID Before Trim: ", encodedId);

    encodedId = encodedId.trim(); // Remove espaços em branco
    // console.log("Encoded ID After Trim: ", encodedId);

    let decodedId = null;

    // Verifique se o encodedId tem comprimento suficiente para a operação
    if (encodedId.length < 2) {
        console.error("Código muito curto.");
        return null;
    }

    for (let i = 1; i < encodedId.length; i++) {
        const idPart = encodedId.slice(0, i); // Extrair parte do ID
        // console.log("Tentativa de ID Part: ", idPart);

        decodedId = parseInt(idPart, 36); // Tentar converter parte do ID
        // console.log("Tentativa de Decoded ID: ", decodedId);

        if (isNaN(decodedId)) continue; // Se falhar, passar para a próxima iteração

        const hash = Math.abs((decodedId + secretKey.length) * 31).toString(36); // Gera o hash esperado
        // console.log("Generated Hash: ", hash);

        if (`${idPart}${hash}` === encodedId) {
            // console.log("Hash encontrado e válido.");
            return decodedId; // Retorna o ID decodificado correto
        }
    }

    // console.log("Falha ao decodificar o ID.");
    return null; // Retorna null se não encontrar um hash válido
}











  