import React, { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { COLORS } from "../../global/STYLE";
import { clienteService } from "../../services/cliente";
import { afiliadoService } from "../../services/afiliado";
import { colaboradorService } from "../../services/colaborador";
import { estabelecimentoService } from "../../services/estabelecimento";

// Estilo do modal
const customStyles = {
  content: {
    width: "80%",
    height: "auto",
    maxWidth: "500px",
    maxHeight: "200px",
    margin: "auto",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "20px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    backgroundColor: "#2B2B2B",
    color: "#fff",
  },
};

const DeleteAccountScreen = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [accountType, setAccountType] = useState("");
  const [accountDeleted, setAccountDeleted] = useState(false);
  const [carregando, setCarregando] = useState(false);

  const handleDelete = () => {
    // Verifica se o tipo de conta foi selecionado
    if (accountType === "") {
      toast.error("Por favor, selecione o tipo de conta.");
      return;
    }

    // Verifica se o email está no formato válido
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Por favor, insira um e-mail válido.");
      return;
    }

    // Verifica se a senha atende aos critérios de validação
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#%^&+=!]).{8,}$/;
    if (!passwordRegex.test(password)) {
      toast.error(
        "A senha deve ter pelo menos 8 caracteres, incluindo pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial."
      );
      return;
    }

    // Verifica se o email e a senha foram preenchidos
    if (email.trim() !== "" && password.trim() !== "") {
      setModalIsOpen(true);
    } else {
      toast.error("Por favor, preencha o e-mail e a senha.");
    }
  };

  const confirmDeletion = async (e) => {
    e.preventDefault();
    setCarregando(true);

    try {
      const dados = {
        email,
        password,
      };
      switch (accountType) {
        case "cliente":
          const data = await clienteService.deleteAccount(dados);
          if (data.error) {
            toast.error(data.message);
          } else {
            toast.success(
              "Sua solicitação foi enviada e sua conta desativada!"
            );
            setAccountDeleted(true);
            setModalIsOpen(false);
            setCarregando(false);
            setEmail("");
            setPassword("");
          }
          break;
        case "estabelecimento":
          const dataE = await estabelecimentoService.deleteAccount(dados);
          if (dataE.error) {
            toast.error(dataE.message);
          } else {
            toast.success(
              "Sua solicitação foi enviada e sua conta desativada!"
            );
            setAccountDeleted(true);
            setModalIsOpen(false);
            setCarregando(false);
            setEmail("");
            setAccountType("");
            setPassword("");
          }
          break;
        case "colaborador":
          const dataC = await colaboradorService.deleteAccount(dados);
          if (dataC.error) {
            toast.error(dataC.message);
          } else {
            toast.success(
              "Sua solicitação foi enviada e sua conta desativada!"
            );
            setAccountDeleted(true);
            setModalIsOpen(false);
            setCarregando(false);
            setEmail("");
            setPassword("");
          }
          break;
        case "afiliado":
          const dataAf = await afiliadoService.deleteAccount(dados);
          if (dataAf.error) {
            toast.error(dataAf.message);
          } else {
            toast.success(
              "Sua solicitação foi enviada e sua conta desativada!"
            );
            setAccountDeleted(true);
            setModalIsOpen(false);
            setCarregando(false);
            setEmail("");
            setPassword("");
          }
          break;
        default:
          toast.info("Tipo de conta não encontrada.")
      }

      setCarregando(false);
    } catch (e) {
      toast.error(e.message);
      setCarregando(false);
      setModalIsOpen(false);
    } finally {
      setCarregando(false);
    }
    setModalIsOpen(false);
    setCarregando(false);
  };

  return (
    <div style={{ textAlign: "center", paddingTop: "50px", color: "#fff" }}>
      {!accountDeleted ? (
        <>
          <h1>Excluir Conta</h1>
          <p>
            Para confirmar a exclusão da sua conta, por favor insira seu e-mail
            e senha. Esta ação é irreversível.
          </p>
          <p>
            Ao excluir sua conta, você perderá todas as informações associadas,
            incluindo:
          </p>
          <ul
            style={{
              backgroundColor: COLORS.cardBackground,
              padding: "20px 0",
              margin: "20px",
              listStyle: "none",
            }}
          >
            <li>- Acesso a Conta</li>
            <li>- Foto do perfil</li>
            <li>- Informações pessoais</li>
            <li>- Favoritos</li>
            <li>- Agendamentos</li>
          </ul>
          <div style={{ maxWidth: "300px", margin: "auto" }}>
            <select
              value={accountType}
              onChange={(e) => setAccountType(e.target.value)}
              required
              style={{
                marginBottom: "10px",
                width: "100%",
                padding: "10px",
                boxSizing: "border-box",
                backgroundColor: "#22222c",
                color: "#fff",
                border: "1px solid #ccc",
              }}
            >
              <option value="" disabled>
                Selecione o tipo de conta
              </option>
              <option value="estabelecimento">Estabelecimento</option>
              <option value="cliente">Cliente</option>
              <option value="colaborador">Colaborador</option>
              <option value="afiliado">Afiliado</option>
            </select>
            <input
              type="email"
              placeholder="Digite seu e-mail"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              style={{
                marginBottom: "10px",
                width: "100%",
                padding: "10px",
                boxSizing: "border-box",
                backgroundColor: "#22222c",
                color: "#fff",
                border: "1px solid #ccc",
              }}
            />
            <input
              type="password"
              placeholder="Digite sua senha"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
              style={{
                marginBottom: "20px",
                width: "100%",
                padding: "10px",
                boxSizing: "border-box",
                backgroundColor: "#22222c",
                color: "#fff",
                border: "1px solid #ccc",
              }}
            />
            <button
              onClick={handleDelete}
              style={{
                backgroundColor: COLORS.button,
                borderRadius: 5,
                width: "100%",
                color: "#fff",
                border: "none",
                padding: "10px 20px",
                cursor: "pointer",
              }}
            >
              Confirmar Exclusão
            </button>
          </div>
        </>
      ) : (
        <div>
          <h1>Conta Excluída</h1>
          <br />
          <p>
            Sua conta foi marcada para exclusão. Todos os seguintes dados
            associados à sua conta serão excluídos em até 90 dias:
          </p>
          <ul
            style={{
              backgroundColor: COLORS.cardBackground,
              padding: "20px 0",
              margin: "20px",
              listStyle: "none",
            }}
          >
            <li>- Acesso a Conta (já desativada)</li>
            <li>- Informações pessoais</li>
            <li>- Favoritos</li>
            <li>- Foto do perfil</li>
            <li>- Agendamentos</li>
          </ul>
          <p>
            Para qualquer dúvida ou reativação da conta, entre em contato
            conosco.
          </p>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        contentLabel="Confirmação de exclusão"
      >
        <div>
          <p>
            Sua conta será excluída em até 90 dias. Tem certeza que deseja
            continuar?
          </p>
          <br />
          <div style={{ justifyContent: "space-around", display: "flex" }}>
            <button
              onClick={() => setModalIsOpen(false)}
              style={{
                backgroundColor: "rgba(150, 50, 50)",
                borderRadius: 5,
                color: "#fff",
                border: "none",
                padding: "10px 20px",
                cursor: "pointer",
              }}
            >
              Cancelar
            </button>
            <button
              onClick={confirmDeletion}
              style={{
                backgroundColor: "rgba(50, 150, 50)",
                borderRadius: 5,
                color: "#fff",
                border: "none",
                padding: "10px 20px",
                cursor: "pointer",
              }}
              disabled={carregando ? true : false}
            >
              {carregando ? (
                <span className="loader"></span>
              ) : (
                "Confirmar Exclusão"
              )}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteAccountScreen;
