import Api from "../index";

const cadHorarioDisponivelColaborador = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.post("/horario", dados, headerToken);
  return data;
};

const cadHorarioDisponivelColaboradorPeloEstabelecimento = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.post("/horario/byEstab", dados, headerToken);
  return data;
};

const horarioDisponivelServicoColaborador = async (token, colaborador_id, servico_id) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const {data} = await Api.get(`/horario/servico/colaborador/${servico_id}/${colaborador_id}`, headerToken);
  return data;
};

const horarioDisponivelServicoColaboradorAgendarParaCliente = async (token, colaborador_id, servico_id) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const {data} = await Api.get(`/horario/servico/colaborador/web/agendar_cliente/${servico_id}/${colaborador_id}`, headerToken);
  return data;
};


export const horarioService = {
  cadHorarioDisponivelColaborador,
  cadHorarioDisponivelColaboradorPeloEstabelecimento,
  horarioDisponivelServicoColaborador,
  horarioDisponivelServicoColaboradorAgendarParaCliente,
  
};
