import Api from '../index'

// const create = async(dados) => {
//     const {data} = await Api.post('/cliente', dados)
//     return data
//   }

const agendamentoEstabelecimento = async (token) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const {data} = await Api.get('/agendamento/estabelecimento', headerToken);
  return data;
};

const agendamentoColaborador = async (token) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const {data} = await Api.get('/agendamento/colaborador', headerToken);
  return data;
};

const novoStatusAgendamentoEstabelecimento = async (token, agendamento_id, status) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const dados = {
    status,
    agendamento_id
  }
  const {data} = await Api.post(`/agendamento/estabelecimento/status`, dados ,headerToken);
  return data;
};

const novoStatusAgendamentoColaborador = async (token, agendamento_id, status) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const dados = {
    status,
    agendamento_id
  }
  const {data} = await Api.post(`/agendamento/colaborador/status`, dados ,headerToken);
  return data;
};

const pegaAgendamentoTokenVotacao = async (token) => {

  const {data} = await Api.get(`/agendamento/token/${token}`);
  return data;
};

const starE = async (dados) => {

  const {data} = await Api.post(`/starE`, dados );
  return data;
};


const starC = async (dados) => {

  const {data} = await Api.post(`/starC`, dados);
  return data;
};

const criarAgendamentoCliente = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const {data} = await Api.post(`/agendamento`, dados, headerToken);
  return data;
};

const criarAgendamentoClientePeloEstabelecimento = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const {data} = await Api.post(`/agendamento/byEstab`, dados, headerToken);
  return data;
};

const pegarAgendamentosCliente = async (token) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const {data} = await Api.get(`/agendamento/cliente/cID`, headerToken)
  return data
}

const cancelamentoCliente = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const {data} = await Api.put('/agendamento/cancelamento/cliente', dados, headerToken)
  return data
}
  
  export const agendamentoService = {
    // create,
    agendamentoEstabelecimento,
    novoStatusAgendamentoEstabelecimento,
    agendamentoColaborador,
    novoStatusAgendamentoColaborador,
    pegaAgendamentoTokenVotacao,
    starC,
    starE,
    criarAgendamentoCliente,
    pegarAgendamentosCliente,
    cancelamentoCliente,
    criarAgendamentoClientePeloEstabelecimento,
  };
  