import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <div style={{ textAlign: 'center', paddingTop: '50px', color: '#fff' }}>
      <h1 style={{ marginBottom: '20px' }}>Política de Privacidade do Facilite</h1>

      <div style={{ textAlign: 'left', maxWidth: '80%', margin: 'auto' }}>
        <h4 style={{ marginBottom: '10px' }}>Data de Vigência: 08 de Março de 2024.</h4>
        <p style={{ textAlign: 'justify', marginBottom: '20px' }}>
        Estamos empenhados em salvaguardar a privacidade e a segurança dos dados dos nossos usuários. Esta Política de Privacidade explica os tipos de informações que coletamos, como as utilizamos e as medidas que adotamos para proteger os seus dados.
        </p>
        
        <h2 style={{ margin: '30px 0 20px 0' }}>Coleta e Uso de Informações</h2>
        <h4 style={{ marginBottom: '10px' }}>As informações listadas a seguir são coletadas:</h4>


        <h3 style={{ marginBottom: '10px' }}>1. Informações Pessoais:</h3>
        <p style={{ textAlign: 'justify', marginBottom: '20px' }}>
        Isso inclui seu nome, endereço de e-mail e número de telefone usados para cadastro, bem como data de nascimento, endereço e gênero informados no ato do preenchimento do perfil. Essas informações são utilizadas para fornecer suporte ao cliente, enviar atualizações sobre o nosso aplicativo e para fins de gestão de conta.        </p>

        <h3 style={{ marginBottom: '10px' }}>2. Informações do Dispositivo:</h3>
        <p style={{ textAlign: 'justify', marginBottom: '20px' }}>
        Coletamos informações sobre o dispositivo que você usa para acessar o nosso aplicativo, como o sistema operacional e identificadores únicos do dispositivo. Essas informações nos ajudam a otimizar o nosso aplicativo para diferentes dispositivos e a solucionar quaisquer problemas.
        </p>

        <h3 style={{ marginBottom: '10px' }}>3. Dados de Localização: </h3>
        <p style={{ textAlign: 'justify', marginBottom: '20px' }}>
        Se você ativar os serviços de localização, podemos coletar a localização do seu dispositivo para fornecer recursos baseados na localização em nosso aplicativo, a exemplo da distância estimada para os estabelecimentos disponíveis.
        </p>

        <h3 style={{ marginBottom: '10px' }}>4. Dados de Uso:</h3>
        <p style={{ textAlign: 'justify', marginBottom: '20px' }}>
        Coletamos dados sobre como você interage com o nosso aplicativo, como os as buscas realizadas dentro do aplicativo e o tempo gasto no aplicativo. Isso nos ajuda a melhorar a funcionalidade e a experiência do usuário do nosso aplicativo.
        </p>


        <h2 style={{ margin: '30px 0 20px 0' }}>Compartilhamento e Divulgação de Dados</h2>
        <p style={{ textAlign: 'justify', marginBottom: '20px' }}>Não vendemos nem alugamos suas informações pessoais a terceiros. Podemos compartilhar suas informações com prestadores de serviços terceirizados que realizam serviços em nosso nome, como hospedagem, análise e atendimento ao cliente. Esses provedores são vinculados por acordos de confidencialidade e só têm permissão para usar suas informações para o propósito de prestar serviços a nós.</p>


        <h2 style={{ margin: '30px 0 20px 0' }}>Segurança dos Dados</h2>
        <p style={{ textAlign: 'justify', marginBottom: '20px' }}>Levamos a segurança dos seus dados a sério e implementamos medidas técnicas e organizacionais apropriadas para protegê-los contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, nenhum sistema de segurança é impenetrável e não podemos garantir a segurança absoluta das suas informações.</p>
        
        
        <h2 style={{ margin: '30px 0 20px 0' }}>Retenção de Dados</h2>
        <p style={{ textAlign: 'justify', marginBottom: '20px' }}>Suas informações são pelo tempo necessário para fornecer nossos serviços e cumprir os propósitos delineados nesta declaração. Quando não precisarmos mais das suas informações, as excluiremos ou anonimizaremos. A qualquer momento você tem o direito de solicitar a exclusão dos seus dados através do endereço <a href="https://facilite.app/delete-account" title='link de exclusão para dados' alt="link de exclusão para dados">https://facilite.app/delete-account</a>.</p>


        <h2 style={{ margin: '30px 0 20px 0' }}>Alterações nesta Declaração</h2>
        <p style={{ textAlign: 'justify', marginBottom: '20px' }}>Podemos atualizar esta Política de Privacidade de tempos em tempos. Notificaremos você sobre quaisquer alterações significativas, publicando a nova política em nosso aplicativo e atualizando a data de entrada em vigor.</p>


        <h2 style={{ margin: '30px 0 20px 0' }}>Fale Conosco</h2>
        <p style={{ textAlign: 'justify', marginBottom: '20px' }}>Se você tiver alguma dúvida ou preocupação sobre a nossa Política de Privacidade ou nossas práticas de dados, por favor, entre em contato conosco através do e-mail contato.intellisync@gmail.com ou do telefone +55 31 9 91038051.</p>


      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
