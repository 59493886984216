import React, { useState } from "react";
import Modal from "react-modal";
import "./styles.css"; 
import { formatarValorReal } from "../../../functions/formatarValorReal";
import { useAuth } from "../../../contexts/AuthContext";
import { planoService } from "../../../services/plano";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Set the app element to avoid screen readers issues
Modal.setAppElement("#root");

const ModalPlano = ({ isOpen, onRequestClose, addPlano }) => {
  const { token, logout } = useAuth();
  const navigate = useNavigate();

  const [plano, setPlano] = useState("");
  const [valor, setValor] = useState("");
  const [limiteImagem, setLimiteImagem] = useState(0);
  const [limiteOferta, setLimiteOferta] = useState(0);
  const [limiteColaborador, setLimiteColaborador] = useState(0);
  const [liberarAgendamento, setLiberarAgendamento] = useState(false);
  const [liberarOferta, setLiberarOferta] = useState(false);
  const [nivel, setNivel] = useState(1);
  const [descriptions, setDescriptions] = useState([]);
  const [carregando, setCarregando] = useState(false);

  const handleAddDescription = () => {
    setDescriptions([...descriptions, { quantidade: "", descricao: "" }]);
  };

  const handleDescriptionChange = (index, field, value) => {
    const newDescriptions = descriptions.map((desc, i) =>
      i === index ? { ...desc, [field]: value } : desc
    );
    setDescriptions(newDescriptions);
  };

  const handleRemoveLastDescription = () => {
    if (descriptions.length > 0) {
      setDescriptions(descriptions.slice(0, -1));
    }
  };

  const handlePlanoInputValorChange = (event) => {
    let value = event;
    // Remove caracteres não numéricos
    value = value.replace(/\D/g, "");
    // Remove os zeros à esquerda
    value = value.replace(/^0+/, "");
    // Preenche com zeros à esquerda até ter 6 caracteres
    value = value.padStart(6, "0");
    // Divide o valor para obter a parte inteira e a parte decimal
    const parteInteira = value.slice(0, -2) || "0";
    const parteDecimal = value.slice(-2);
    // Formata o valor para o formato de reais
    const valorFormatado = `${parteInteira}.${parteDecimal}`;

    setValor(valorFormatado);
  };

  const handleSubmit = async () => {
    setCarregando(true);
    if(!plano || !valor){
        toast.error(
            "Todos os campos devem ser preenchidos."
          );
          setCarregando(false);
          return;
    }
    // Validação das descrições
    for (const desc of descriptions) {
      if (!desc.quantidade || !desc.descricao) {
        toast.error(
          "Todos os campos de quantidade e descrição devem estar preenchidos."
        );
        setCarregando(false);
        return;
      }
    }

    const dado = {
      plano,
      valor,
      limite_imagem: limiteImagem,
      limite_oferta: limiteOferta,
      limite_colaborador:limiteColaborador,
      liberar_agendamento: liberarAgendamento,
      liberar_oferta: liberarOferta,
      nivel,
      descricoes: descriptions,
    };
    const data = await planoService.cadastraPlano(token, dado);
    if (data.error) {
      toast.error(data.message);
      if (data.reload) {
        setCarregando(false);
        navigate("/login/adm_facilite");
        logout();
      }
      setCarregando(false);
    } else {
      addPlano(data.data)
      toast.success(data.message);
      setPlano("")
      setValor("")
      setLimiteImagem(0)
      setLimiteOferta(0)
      setLimiteColaborador(0)
      setLiberarAgendamento(false)
      setLiberarOferta(false)
      setNivel(1)
      setDescriptions([])
      setCarregando(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Formulário de Plano"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <div
        style={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
          marginBottom: 15,
        }}
      >
        <h3>Cadastrar novo Plano</h3>
        <button onClick={onRequestClose} className="close-button">
          Fechar
        </button>
      </div>
      <form className="form-content">
        <div className="form-group">
          <label>Plano</label>
          <input
            type="text"
            name="plano"
            value={plano}
            onChange={(e) => setPlano(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Valor</label>
          <input
            type="text"
            name="valor"
            required
            placeholder="00,00"
            value={formatarValorReal(valor)}
            onChange={(e) => handlePlanoInputValorChange(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Limite de Imagem</label>
          <input
            type="number"
            name="limite_imagem"
            value={limiteImagem}
            onChange={(e) => setLimiteImagem(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Limite de Colaborador</label>
          <input
            type="number"
            name="limite_colaborador"
            value={limiteColaborador}
            onChange={(e) => setLimiteColaborador(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Limite de Oferta</label>
          <input
            type="number"
            name="limite_oferta"
            value={limiteOferta}
            required
            onChange={(e) => setLimiteOferta(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Liberação de Agendamento</label>
          <select
            name="liberar_agendamento"
            value={liberarAgendamento}
            onChange={(e) => setLiberarAgendamento(e.target.value)}
            required
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
        </div>
        <div className="form-group">
          <label>Liberação de Oferta</label>
          <select
            name="liberar_oferta"
            value={liberarOferta}
            onChange={(e) => setLiberarOferta(e.target.value)}
            required
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
        </div>
        <div className="form-group">
          <label>Nível</label>
          <select
            name="nivel"
            value={nivel}
            onChange={(e) => setNivel(Number(e.target.value))}
          >
            {Array.from({ length: 10 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>
        <hr />
        <br />
        <h3>Detalhes</h3>
        <br />
        {descriptions.map((desc, index) => (
          <div key={index} className="form-group-inline">
            <div className="form-group">
              <label>Quantidade</label>
              <input
                type="text"
                value={desc.quantidade}
                onChange={(e) =>
                  handleDescriptionChange(index, "quantidade", e.target.value)
                }
              />
            </div>
            <div className="form-group">
              <label>Descrição</label>
              <input
                type="text"
                value={desc.descricao}
                onChange={(e) =>
                  handleDescriptionChange(index, "descricao", e.target.value)
                }
              />
            </div>
          </div>
        ))}

        <div  style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', }}>
          <button
            type="button"
            onClick={handleAddDescription}
            className="add-button"
          >
            Adicionar mais detalhes
          </button>

          <button
            type="button"
            onClick={handleRemoveLastDescription}
            className="rem-button"
          >
            Remover último detalhe
          </button>
        </div>

        <button type="button" onClick={handleSubmit} className="cad-button" disabled = {carregando ? true : false}>
        {carregando ? <span className="loader"></span> : "Cadastrar Plano"}
          
        </button>
      </form>
    </Modal>
  );
};

export default ModalPlano;
