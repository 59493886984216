import React, { useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCamera,
  faSignOutAlt,
  faCalendar,
  faBars,
  faUser,
  faCalendarAlt,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import "../../ADMIN_Estabelecimento/components/Sidebar";
import { useAuth } from "../../../contexts/AuthContext";

import { CONSTANTES } from "../../../global/CONSTANTES";
import { colaboradorService } from "../../../services/colaborador";
import { COLORS } from "../../../global/STYLE";

const Sidebar = () => {
  const { token, logout, user, changeData } = useAuth();

  let navigate = useNavigate();

  // Inicializando um objeto de estado para controlar os dropdowns
  const [dropdownsOpen, setDropdownsOpen] = useState({});
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [carregando, setCarregando] = useState(false);

  const toggleDropdown = useCallback((dropdownName) => {
    setDropdownsOpen((prevDropdowns) => ({
      ...prevDropdowns,
      [dropdownName]: !prevDropdowns[dropdownName],
    }));
    // console.log(dropdownName)
  }, []);

  const handleNavigation = useCallback((path) => {
    // console.log(path)
    navigate(path);
    setSidebarOpen(false); // Feche o sidebar quando um item é clicado
  }, []);

  const handleImageUpload = useCallback(
    async (event) => {
      setCarregando(true);
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];

        const maxSize = 2 * 1024 * 1024; // 2MB em bytes

        if (file && file.size > maxSize) {
          toast.error(
            "O tamanho do arquivo excede o limite de 2MB. Por favor, selecione um arquivo menor."
          );
          // alert('O tamanho do arquivo excede o limite de 2MB. Por favor, selecione um arquivo menor.');
          event.target.value = ""; // Limpa o valor do input file
          setCarregando(false);
          return false;
        }

        let colaborador_id = user.id;
        try {
          const data = await colaboradorService.changePhoto(
            file,
            token,
            colaborador_id
          );
          if (data.error) {
            if(data.reload){
              navigate("/login/colaborador")
              logout()  
            }
            toast.error(data.message);
          } else {
            changeData("foto", data.data.foto);
            toast.success(data.message);
            // console.log(data.data.foto);
          }
        } catch (e) {
          toast.error("Erro ao enviar imagem", e.message)
          // console.error("Erro ao enviar imagem", e);
        }
      }
      setCarregando(false);
    },
    [user]
  );

  return (
    <>
      <div
        className="hamburger-toggle"
        onClick={() => setSidebarOpen(!isSidebarOpen)}
      >
        <FontAwesomeIcon icon={faBars} />
      </div>
      <div
        className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}
        style={{ backgroundColor: COLORS.cardBackground }}
      >
        <div className="avatar-section">
          {user.foto ? (
            <img
              src={`${CONSTANTES.uri}${CONSTANTES.caminhoImageColaboradores}${user.id}/${user.foto}`}
              alt="Avatar"
              className="avatar"
            />
          ) : (
            <img
              src="https://via.placeholder.com/100"
              alt="Avatar"
              className="avatar"
            />
          )}

          {carregando ? (
            <label className="edit-avatar" style={{ width: "auto" }}>
              <span className="loader"></span>
            </label>
          ) : (
            <label
              htmlFor="image-upload-colaborador"
              className="edit-avatar"
              style={{ width: "auto" }}
            >
              <FontAwesomeIcon icon={faCamera} />
              <input
                type="file"
                id="image-upload-colaborador"
                accept="image/*"
                onChange={handleImageUpload}
                hidden
              />
            </label>
          )}

          <div className="company-name">{user.nome}</div>
        </div>

        <ul className="menu-items">
          <li
            className="menu-item"
            onClick={() => handleNavigation("/adminC/")}
          >
            <FontAwesomeIcon icon={faHome} className="menu-icon" />
            Início
          </li>

          <li
            className="menu-item"
            onClick={() => handleNavigation("/adminC/PerfilColaborador")}
          >
            <FontAwesomeIcon icon={faUser} className="menu-icon" />
            Perfil
          </li>

          <li
            className="menu-item"
            onClick={() => handleNavigation("/adminC/AgendamentoColaborador")}
          >
            <FontAwesomeIcon icon={faCalendarAlt} className="menu-icon" />
            Agendamento
          </li>
          <li
            className="menu-item"
            onClick={() => handleNavigation("/adminC/HorarioDisponivel")}
          >
            <FontAwesomeIcon icon={faCalendarCheck} className="menu-icon" />
            Horario Disponivel
          </li>

          {/* <li
            className="menu-item"
            onClick={() => handleNavigation("/adminC/agendamento")}
          >
            <FontAwesomeIcon icon={faCog} className="menu-icon" />
            Configuração
          </li> */}
        </ul>
        <div className="logout-section">
          <button
            className="logout-button"
            onClick={() => {
              logout();
              navigate("/");
            }}
          >
            <FontAwesomeIcon icon={faSignOutAlt} className="logout-icon" />
            Sair
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
