import Api from "../index";

const pegaPlanoPeloPlano = async (token, plano) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.get(`/plano/${plano}`, headerToken);
  return data;
};

const pegaPlanos = async (token) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.get(`/planos`, headerToken);
  return data;
};

const pegaPlanosST = async () => {
  // const headerToken = {
  //   headers: {
  //     Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
  //   },
  // };
  const { data } = await Api.get(`/planos/web/no`);
  return data;
};

const pegaPlanosADM = async (token) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.get(`/planos/adm`, headerToken);
  return data;
};

const pegaPlanosDashBoardADM = async (token) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.get(`/planos/dashboard/adm`, headerToken);
  return data;
};

const pegaCupom = async (token, cupom) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.get(
    `/cupom/estabelecimento/${cupom}`,
    headerToken
  );
  return data;
};

const atualizaPlano = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.post(`/plano/atualiza`, dados, headerToken);
  return data;
};

const cadastraPlano = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.post(`/plano`, dados, headerToken);
  return data;
};

const cadastraDetalhePlano = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.post(`/plano/detalhe`, dados, headerToken);
  return data;
};

const deleteDetalhePlano = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.post(`/plano/detalhe/delete`, dados, headerToken);
  return data;
};

const deletePlano = async (token, dados) => {
  const headerToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Use o prefixo 'Bearer' seguido do token
    },
  };
  const { data } = await Api.post(`/plano/delete`, dados, headerToken);
  return data;
};

export const planoService = {
  pegaPlanoPeloPlano,
  pegaPlanos,
  pegaCupom,
  pegaPlanosDashBoardADM,
  pegaPlanosADM,
  atualizaPlano,
  cadastraPlano,
  cadastraDetalhePlano,
  deleteDetalhePlano,
  deletePlano,
  pegaPlanosST
};
